import Button from "@mui/material/Button";
import {Forward, CheckBoxRounded, InfoOutlined} from "@mui/icons-material";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {CalendarMonth} from "@mui/icons-material";
import {Clear} from "@mui/icons-material";
import {MaterialReactTable} from "material-react-table";
import {useMemo, useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Schedules from "../../../classes/Schedules";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import DetailEdit from "./detail/DetailEdit";
import ReassignCrew from "../../scheduling/crew/ReassignCrew";
import {darken, lighten} from "@mui/material";

const UpdatesGrid = ({isLoading, userId, selectedTab,
                                 selectedDate, setSchedule, schedule,
                                 employees, setEmployees, selectedEmployees, setSelectedEmployees,
                                 selectedEmployeesReassign, setSelectedEmployeesReassign, empsToRemove, setEmpsToRemove, crews, setCrews,
                                 setRowSelection, selectedRows, setSelectedRows,
                                 phases, phaseTasks, suppliers, isLocked}) => {

    const [newSelectedDate, setNewSelectedDate] = useState(selectedDate)
    const [dateOpen, setDateOpen] = useState(false)
    const [detailOpen, setDetailOpen] = useState(false)
    const [employeesOpen, setEmployeesOpen] = useState(false)

    let [editDetails, setEditDetails] = useState([])

    const [selectedTableRows, setSelectedTableRows] = useState([])
    const [tableObj, setTable] = useState({})

    const [selectedTasks, setSelectedTasks] = useState([])
    const [selectedPhaseTaskNames, setSelectedPhaseTaskNames] = useState('')

    const [detailScheduleRows, setDetailScheduleRows] = useState([])
    const [detailRows, setDetailRows] = useState([])

    const gridColumns = useMemo(
        () => [
            {
                accessorFn: (row) => row.task.code !== null && row.task.code !== "" ? `${row.task.code} ${row.task.name}` : row.task.name,
                id: 'task',
                header: 'Task',
                enableEditing: false,
                size: 200
            },
            {
                accessorFn: (row) => `${row.lot.job.project_name} - ${row.lot.job.number}`,
                header: 'Tract',
                size: 200
            },
            {
                accessorFn: (row) => `${row.lot.phase} ${row.lot.number}`,
                header: 'Lot',
                size: 200
            },
            {
                accessorFn: (row) => {
                    let crewNumber = '-1'
                    try {
                        crewNumber = row.crew !== null && row.crew.crew !== null ? row.crew.crew.number : '-1';
                    } catch (e) {
                        console.log('error', e, row)
                    }
                    let name = '';
                    if (row.crew !== null && row.crew.employees.length > 0 && row.crew.employees[0].employee !== null) {
                        let emp = row.crew.employees[0].employee
                        name = emp.alias !== undefined && emp.alias !== null ? `${emp.alias} ${emp.first_name} ${emp.last_name}` : `${emp.first_name} ${emp.last_name}`
                    } else {
                        name = 'Not Assigned'
                    }
                    return (`${crewNumber} - ${name}`)
                },
                id: 'crew',
                header: 'Crew',
                enableEditing: false,
                size: 200
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
                size: 200
            },
        ],
        [],
    );
    const detailColumns = useMemo(
        () => [
            {
                accessorFn: (row) => {
                    let emp = row.employee
                    let name = emp.alias !== undefined && emp.alias !== null ? `${emp.alias} ${emp.first_name} ${emp.last_name}` : `${emp.first_name} ${emp.last_name}`
                    return (name)
                },
                header: '',
                id: 'scheduled-employee'
            },
            {
                accessorFn: (row) => {
                    let emp = row.employee
                    let crew = emp.membership.crew.number
                    if(crew !== row.parent_crew)
                        return (`From Crew ${crew}`)
                },
                header: '',
                id: 'scheduled-employee-from-crew'
            },
        ],
        [],
    );

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'
    const baseCompleteColor = theme.palette.mode === 'dark' ? 'rgba(143, 248, 144, 1)' : 'rgba(143, 248, 144, 1)'

    const getSelectedTaskNames = (tasks, onlyForDetails) => {
        let selectedPhaseTasks = ''
        tasks.forEach(task => {
            if (task !== undefined && (!onlyForDetails || task.schedule_detail_type !== null)) {
                selectedPhaseTasks += ' ' + task.name
            }
        })
        setSelectedPhaseTaskNames(selectedPhaseTasks.trim())
    }

    //Change Crew
    const handleAssignOne = async (table, row) => {
        table.setRowSelection({
            [row.id]: true
        })
        setSelectedRows([row])
        setSelectedEmployeesReassign(await Schedules.handlePrepAssignmentChange(phases, [row], setEmployees, setCrews, setSelectedRows, setSelectedTableRows, setEmpsToRemove))
        setEmployeesOpen(true)
        table.resetRowSelection()
    }
    const handleAssignmentCloseNoSave = () => {
        setEmployeesOpen(false);
    };
    const handleCloseAssignmentSave = async () => {
        setSchedule(await Schedules.handleAssignmentSave(selectedRows, schedule, employees, selectedEmployeesReassign, empsToRemove, userId))
        setEmployeesOpen(false)
    }

    //Update Status
    const handleStatusChange = async (table, rows, isComplete) => {
        if (!isComplete || !handleDetailEdit(table, rows)) {
            await setStatus(table, rows, isComplete)
        }
    }

    const setStatus = async (table, rows, isComplete) => {
        await Schedules.handleComplete(isComplete, schedule, rows, userId)
        table.resetRowSelection()
    }

    //Date Changes
    const handleDateChanged = async newDate => {
        setNewSelectedDate(new Date(newDate))
    }
    const handleChangeDateOne = (table, row) => {
        table.setRowSelection({
            [row.id]: true
        })
        let rows = table.getSelectedRowModel().rows
        setSelectedRows([row])
        setDateOpen(true)
    }
    const handleDateCloseNoSave = async () => {
        setDateOpen(false)
        setNewSelectedDate('')
        setSelectedRows([])
    }
    const handleDateCloseSave = async () => {
        setSchedule(await Schedules.handleDateChange(selectedRows, schedule, selectedDate, newSelectedDate))
        tableObj.resetRowSelection()
        setSelectedRows([])
        setDateOpen(false)
    }


    //Detail Actuals
    const handleDetailEdit = (table, rows) => {
        let tasks = []
        let dRows = []

        for (let r = 0; r < rows.length; r++) {

            let phaseTask = phaseTasks.find(x => x.id === rows[r].original.phase_task_id)
            if (tasks.find(x => x.id === phaseTask.id) === undefined) {
                tasks.push(phaseTask)
            }
            let needsDetails = phaseTask.detail.length > 0
            let row = rows[r]

            if (needsDetails) {
                dRows.push(row.original)
            }
        }
        getSelectedTaskNames(tasks)
        setSelectedTasks(tasks)

        if (dRows.length > 0) {
            let detail = []
            for (let d = 0; d < dRows.length; d++) {
                detail.push({
                    job: dRows[d].job,
                    lot: dRows[d].lot,
                    lot_phase: dRows[d].lot_phase,
                    schedule_id: dRows[d].schedule_id
                })
                for (let a = 0; a < dRows[d].detail.length; a++) {
                    if (dRows[d].detail[a].supplier !== undefined && dRows[d].detail[a].supplier !== null) {
                        detail[detail.length - 1][dRows[d].detail[a].taskDetail.type.key] = dRows[d].detail[a].supplier.name
                    } else {
                        detail[detail.length - 1][dRows[d].detail[a].taskDetail.type.key] = dRows[d].detail[a].actual_value
                    }
                    let key_detail_key = `${dRows[d].detail[a].taskDetail.type.key}_key_detail_id`
                    detail[detail.length - 1][key_detail_key] = dRows[d].detail[a].id
                }
            }
            setDetailScheduleRows(dRows)
            setDetailRows(detail)
            setDetailOpen(true)
            return true
        } else {
            return false
        }
    }

    const handleDetailCancel = () => {
        setDetailOpen(false)
    }

    const handleDetailSave = async () => {
        let detail = []
        for (let r = 0; r < detailRows.length; r++) {
            detail.push({
                id: detailRows[r].yards_key_detail_id,
                actual_value: detailRows[r].yards //TODO need to not hard code to yards for this but for now this is the only value being updated
            })
        }
        await Schedules.updateDetail({
            data: detail
        })

        setDetailOpen(false)
        if (selectedTab === "0") {
            await setStatus(tableObj, selectedTableRows, selectedTab === "0")
        }
    }

    //Notes
    const handleNotesSave = async (e, newValue, cell, row) => {
        row.original['notes'] = newValue === null || newValue === "" ? "" : newValue.toString()
        let response = await Schedules.updateById({
            id: row.original.id,
            notes: row.original.notes
        })
        return response
    }

    return (
        <ThemeProvider theme={theme}>
            <MaterialReactTable
                id="schedule-update-table"
                columns={gridColumns}
                data={
                    selectedTab === "0" ?
                        schedule.filter(x => (x.complete === null || x.complete === 0 || x.complete === false)) :
                        schedule.filter(x => (x.complete === 1 || x.complete === true))
                }
                rowCount={schedule.length}
                enableSorting
                enableRowActions
                enableRowSelection
                layoutMode="grid-no-grow"
               // enableColumnResizing
                enableExpandAll={false}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: 'Status',
                        size: 220,
                    },
                    'mrt-row-expand': {
                        header: '',
                        size: 100
                    }
                }}
                muiTableBodyCellProps={({cell, row, table}) => {
                    return ({
                        sx: {
                            textAlign: 'left'
                        }
                    })}}
                muiTableBodyRowProps={({row, table}) => {
                    return({
                        sx: {
                            backgroundColor: () => {
                                let background = ''
                                if (row.index % 2) {
                                    background = row.original.complete ? darken(baseCompleteColor, 0.1) : darken(baseBackgroundColor, 0.1)
                                } else {
                                    background = row.original.complete ? lighten(baseCompleteColor, 0.3) : lighten(baseBackgroundColor, 0.3)
                                }
                                return background
                            }
                        }
                    })}}
                muiDetailPanelProps={({row}) => {
                    return({
                        sx: (theme) => ({
                            backgroundColor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(255,210,244,0.1)'
                                    : 'rgba(0,0,0,0.1)',
                        })
                    })}}
                muiExpandButtonProps ={({ row, table }) => ({
                    //  children: row.getIsExpanded() ? <Minimize/> : <Add/>,
                    onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
                    sx: {
                        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                        transition: 'transform 0.2s',
                    }
                })}
                renderRowActions={({table, row}) => {
                    return (
                        <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                            {(row.original.complete === true || row.original.complete === 1) &&
                                <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                    <Clear
                                        onClick={async () => {
                                            setTable(table)
                                            setSelectedTableRows([row])
                                            await handleStatusChange(table, [row], false)
                                        }}
                                        style={{cursor: 'pointer', color: 'darkorchid'}}
                                    />
                                    {row.original.detail.length > 0 && <InfoOutlined
                                        onClick={() => {
                                            setTable(table)
                                            setSelectedTableRows([row])
                                            handleDetailEdit(table, [row], false)
                                        }}
                                        style={{cursor: 'pointer', color: 'blue'}}
                                    />}
                                </div>}
                            {(row.original.complete === null || row.original.complete === false || row.original.complete === 0) &&
                                <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1.5rem'}}>
                                    <CheckBoxRounded
                                        onClick={() => {
                                            setTable(table)
                                            setSelectedTableRows([row])
                                            handleStatusChange(table, [row], true)
                                        }}
                                        style={{cursor: 'pointer', color: 'green'}}
                                    />
                                    <TransferWithinAStationIcon
                                        onClick={async () => {
                                            setTable(table)
                                            await handleAssignOne(table, row)
                                        }}
                                        style={{cursor: 'pointer', color: 'darkorange'}}
                                    />
                                    <CalendarMonth
                                        onClick={() => {
                                            setTable(table)
                                            handleChangeDateOne(table, row)
                                        }}
                                        style={{cursor: 'pointer', color: 'blue'}}
                                    />
                                </div>}
                        </div>
                    )
                }}
                initialState={{
                    showColumnFilters: true,
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {
                  //      left: [ 'mrt-row-expand', 'mrt-row-actions', 'mrt-row-select'],
                        right: [ 'mrt-row-expand'],
                    },
                }}
                positionToolbarAlertBanner="bottom"
                renderDetailPanel={({row}) =>
                    row.original.crew !== null && row.original.crew.employees.length > 1 ?
                        (
                            <MaterialReactTable
                                id="scheduled-employees-table"
                                columns={detailColumns}
                                data={Schedules.getEmployees(row.original)}
                                enableSelectAll={false}
                                enableExpanding={false}
                                enableExpandAll={false}
                                enableTopToolbar={false}
                                enableBottomToolbar={false}
                                enableSorting={false}
                                enableFilters={false}
                                enableHiding={false}
                                enablePagination={false}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableColumnActions={false}
                                enableTableHead={false}
                                enableRowActions
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        header: '', //change header text
                                        size: 50, //make actions column wider
                                    }
                                }}
                                muiTableBodyRowProps={({row, table}) => {
                                    return ({
                                        sx: {
                                            backgroundColor: () => {
                                                let background = ''
                                                if (row.index % 2) {
                                                    background = row.original.complete ? darken(baseCompleteColor, 0.1) : darken(baseBackgroundColor, 0.1)
                                                } else {
                                                    background = row.original.complete ? lighten(baseCompleteColor, 0.3) : lighten(baseBackgroundColor, 0.3)
                                                }
                                                return background
                                            },
                                        }
                                    })
                                }}

                                // renderRowActions={({table, row}) => {
                                //     return (
                                //         <Clear
                                //             onClick={async () => {
                                //                 const tempEmails = [...emails];
                                //                 const index = tempEmails.findIndex(e => parseInt(e.id) === parseInt(row.original.email_id));
                                //
                                //                 let person;
                                //                 if(row.original.employee_id !== null){
                                //                     person = tempEmails[index].distribution.find(d => parseInt(d.employee_id) === parseInt(row.original.employee_id));
                                //                 }
                                //                 else if(row.original.user_id !== null){
                                //                     person = tempEmails[index].distribution.find(d => parseInt(d.user_id) === parseInt(row.original.user_id));
                                //                 }
                                //                 else{
                                //                     return;
                                //                 }
                                //
                                //                 Auth.hitEndpointNew("DELETE", "email/distribution", "", person);
                                //                 setEmails(await Emails.loadEmails());
                                //             }}
                                //             style={{cursor: 'pointer', color: 'darkorchid'}}
                                //         />
                                //     )
                                // }}
                                // renderTopToolbarCustomActions={({table, row}) => {
                                //     const handleAddNew = () => {
                                //         let id = table.getRowModel().rows[0].original.email_id
                                //         let email = emails.find(x => x.id === id)
                                //         setSelectedEmail(email)
                                //         setSelectedStartDate(new Date(email.start_date))
                                //         if(email.end_date !== null && email.end_date !== ''){
                                //             setSelectedEndDate(new Date(email.end_date))
                                //         }
                                //         setDistributionOpen(true)
                                //     };
                                //     return (
                                //         <div style={{display: 'flex', width: "100%", justifyContent: "end"}}>
                                //             <Tooltip title="Add Someone to the Distribution List">
                                //                 <IconButton onClick={handleAddNew}>
                                //                     <GroupAdd/>
                                //                 </IconButton>
                                //             </Tooltip>
                                //         </div>
                                //     );
                                // }}
                                initialState={{
                                    pagination: {pageSize: 100, pageIndex: 0},
                                    density: 'compact',
                                }}
                            />) : null
                }
                renderTopToolbarCustomActions={({table}) => {
                    const handleStatusChangeAll = async (table) => {
                        setTable(table)
                        let rows = table.getSelectedRowModel().rows
                        setSelectedTableRows(rows)
                        await handleStatusChange(table, rows, selectedTab === "0")
                    };
                    const handleAssign = async () => {
                        //TODO need to reassess if we are going to add the functionality or not. If we do add this batch swap functionality we need to work out
                        // whether or not we want to limit to same crews selected to swap. Currently it would merge all employees on the jobs selected into one super crew.
                        // setTable(table)
                        // let rows = table.getSelectedRowModel().rows
                        // setSelectedRows(rows)
                        // await handlePrepAssignmentChange(rows)
                        // setEmployeesOpen(true)
                    }
                    const handleDateChange = () => {
                        let rows = table.getSelectedRowModel().rows
                        setTable(table)
                        setSelectedRows(rows)
                        setDateOpen(true)
                    }
                    return (
                        <div style={{display: 'flex', gap: '0.5rem'}}>
                            <Button
                                color={selectedTab === "0" ? "success" : "secondary"}
                                onClick={async () => {
                                    await handleStatusChangeAll(table)
                                }}
                                disabled={schedule.length === 0 || isLoading || isLocked || table.getSelectedRowModel().rows.length === 0}
                                variant="contained"
                            >
                                {selectedTab === "0" && <CheckBoxRounded/>}
                                {selectedTab === "1" && <Clear/>}
                            </Button>
                            {/*TODO have this turned off for now*/}
                            {/*{selectedTab === "0" && <Button*/}
                            {/*    color="warning"*/}
                            {/*    onClick={() => {*/}
                            {/*        handleAssign(table)*/}
                            {/*    }}*/}
                            {/*    disabled={(table.getSelectedRowModel().rows.length === 0) || isLoading || isLocked}*/}
                            {/*    variant="contained"*/}
                            {/*>*/}
                            {/*    <TransferWithinAStationIcon/>*/}
                            {/*</Button>}*/}
                            {selectedTab === "0" && <Button
                                color="info"
                                onClick={handleDateChange}
                                disabled={(table.getSelectedRowModel().rows.length === 0) || isLoading || isLocked}
                                variant="contained"
                            >
                                <CalendarMonth/><Forward/>
                            </Button>}
                            {selectedTab === "1" && <Button
                                color="info"
                                onClick={() => {
                                    handleDetailEdit(table, table.getSelectedRowModel().rows)
                                }}
                                disabled={schedule.length === 0 || isLoading || isLocked || table.getSelectedRowModel().rows.length === 0}
                                variant="contained"
                            ><InfoOutlined/>
                            </Button>}
                        </div>
                    );
                }}
            />
            <Dialog open={employeesOpen} onClose={handleAssignmentCloseNoSave} fullScreen={true}>
                <DialogTitle>Build A Crew</DialogTitle>
                <DialogContent>
                    <ReassignCrew
                        selectedRows={selectedTableRows}
                        crews={crews}
                        employees={employees}
                        setEmployees={setEmployees}
                        setSelectedEmployees={setSelectedEmployees}
                        selectedEmployees={selectedEmployees}
                        selectedEmployeesReassign={selectedEmployeesReassign}
                        setSelectedEmployeesReassign={setSelectedEmployeesReassign}
                        empsToRemove={empsToRemove}
                        setEmpsToRemove={setEmpsToRemove}
                        setOpen={setEmployeesOpen}
                        schedule={schedule}
                        setSchedule={setSchedule}
                        table={tableObj}
                        userId={userId}
                        showActions={false}
                        isUpdates={false}
                    ></ReassignCrew>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAssignmentCloseNoSave}>Cancel</Button>
                    <Button onClick={handleCloseAssignmentSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dateOpen} onClose={handleDateCloseNoSave} fullWidth={true}>
                <DialogTitle>Select a New Date</DialogTitle>
                <DialogContent>
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                onChange={(newValue) => {
                                    handleDateChanged(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        helperText: '',
                                        sx: {minWidth: '120px'},
                                        variant: 'standard',
                                    },
                                }}
                                selected={newSelectedDate}
                            />
                        </LocalizationProvider>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDateCloseNoSave}>Cancel</Button>
                    <Button onClick={handleDateCloseSave}>Update Jobs</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={detailOpen} onClose={handleDetailCancel} fullScreen={true}>
                <DialogTitle>{selectedPhaseTaskNames} Schedule Details</DialogTitle>
                <DialogContent>
                    <DetailEdit
                        schedule={detailScheduleRows}
                        setSchedule={setSchedule}
                        detailRows={detailRows}
                        setEditDetails={setEditDetails}
                        selectedTasks={selectedTasks}
                        suppliers={suppliers}
                        setOpen={setDetailOpen}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDetailCancel}>Cancel</Button>
                    <Button onClick={handleDetailSave}>Update Jobs</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default UpdatesGrid;
