import {useState} from 'react';
import Auth from '../authentication/Auth.js';

import Builders from '../../classes/Builders.js';
import MainHeader from '../utilities/MainHeader.js';
import BuildersPageContainer from './styles/BuildersPageContainer.js';
import { Button } from '../utilities/Button.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import PopupOk, {PopupOkWarningColor} from '../utilities/PopupOk.js';
import Popup, {PopupSuccessColor, PopupFailureColor} from '../utilities/Popup.js';

const BuildersPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [user, setUser] = useState('');

    const [builders, setBuilders] = useState([]);
    const [newBuilder, setNewBuilder] = useState(null);
    const [builderToEdit, setBuilderToEdit] = useState(null);
    const [builderToDelete, setBuilderToDelete] = useState(null);

    const [errorNameMissing, setErrorNameMissing] = useState(false);
    const [errorDuplicateName, setErrorDuplicateName] = useState(false);
    const [newBuilderSuccess, setNewBuilderSuccess] = useState(false);
    const [editBuilderSuccess, setEditBuilderSuccess] = useState(false);
    const [deleteBuilderSuccess, setDeleteBuilderSuccess] = useState(false);

    let canCreateBuilder = props.isAuthorized('builders', "WRITE");
    let canEditBuilder =  props.isAuthorized('builders', "WRITE");
    let canDeleteBuilder = false;

    const loadData = async () => {
        setUser(`${await Auth.getUserId()}`);
        setBuilders(Builders.sortBuilders("name", "ascending", await Builders.loadBuilders()));

        setDidLoadData(true);
    }

    if(!didLoadData){
        loadData();
    }

    const resetErrorPopups = () => {
        setErrorNameMissing(false);
        setErrorDuplicateName(false);
    }

    const resetSuccessPopups = () => {
        setNewBuilderSuccess(false);
        setEditBuilderSuccess(false);
        setDeleteBuilderSuccess(false);
    }

    const handleBuilderErrors = (errorCode) => {
        if(errorCode === "ERROR_NO_NAME"){setErrorNameMissing(true); return;}
        if(errorCode === "ERROR_DUPLICATE_NAME"){setErrorDuplicateName(true); return;}
    }

    const saveNewBuilder = async () => {
        const result = await Builders.checkBuilderForErrors(newBuilder);
        if(result !== "SUCCESS"){
            handleBuilderErrors(result);
            return;
        }

        setBuilders(Builders.sortBuilders("name", "ascending", [...builders, await Builders.saveNewBuilder(newBuilder)]));
        setNewBuilderSuccess(true);
        setNewBuilder(null);
    }

    const updateBuilder = async () => {
        const result = await Builders.checkBuilderForErrors(builderToEdit);
        if(result !== "SUCCESS"){
            handleBuilderErrors(result);
            return;
        }

        Builders.updateBuilder(builderToEdit, user);
        const tempBuilders = builders.filter(builderToFilter => builderToFilter.id !== builderToEdit.id);
        setBuilders(Builders.sortBuilders("name", "ascending", [...tempBuilders, builderToEdit]));
        setEditBuilderSuccess(true);
        setBuilderToEdit(null);
    }

    const deleteBuilder = async (e) => {
        if(e.target.value === "ok"){
            const builder = await Builders.deleteBuilder(builderToDelete);
            if(builder){
                setBuilders([...builders].filter(builderToFilter => builderToFilter.id !== builder.id));
                setDeleteBuilderSuccess(true);
            }
        }

        setBuilderToDelete(null);
    }

    const columns = ['Name'];

    return (
        <BuildersPageContainer>
            <MainHeader/>
            <div className='page-header'>
                {canCreateBuilder && <Button className="add-new-builder" handleClick={((e) => setNewBuilder(Builders.createNewBuilder(user)))} buttonText="Add Builder" buttonMargin="0 0 0 0" width="120" height="20"/>}
                <div className='header-title'>Builders</div>
            </div>
            {newBuilder &&
            <div className='new-builder-box'>
                <div className='new-builder-header'>Add New Builder</div>
                <CheckboxWithLabel className="is-active-checkbox" name="is-active" label="Is Active" checked={newBuilder.active} handleCheckbox={((e) => setNewBuilder({...newBuilder, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="name-textbox" name="name" label="Name" placeholder="Name" value={newBuilder.name} handleTextbox={((e) => setNewBuilder({...newBuilder, name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <div className="two-button-box">
                    <Button className="submit-new-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){saveNewBuilder()}})} handleClick={((e) => saveNewBuilder())} buttonText="Submit" width="120"/>
                    <Button className="cancel-new-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){setNewBuilder(null)}})} handleClick={((e) => setNewBuilder(null))} buttonText="Cancel" width="120"/>
                </div>
            </div>}
            <div className='table-header'>
                {columns.map((column, index)=> {
                    return <div key={`${column}-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                })}
            </div>
            {builders.map((builder, index) => {
                if(canEditBuilder && builderToEdit && (builderToEdit.id === builder.id)){
                    return(
                        <div key={`${builder.id}-${index}`} className='edit-builder-box'>
                            <div className='edit-builder-header'>Edit Builder</div>
                            <CheckboxWithLabel className="is-active-checkbox" name="is-active" label="Is Active" checked={builderToEdit.active} handleCheckbox={((e) => setBuilderToEdit({...builderToEdit, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="name-textbox" name="name" label="Name" placeholder="Name" value={builderToEdit.name} handleTextbox={((e) => setBuilderToEdit({...builderToEdit, name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            {!canDeleteBuilder &&
                            <div className="two-button-box">
                                <Button className="submit-edit-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){updateBuilder()}})} handleClick={((e) => updateBuilder())} buttonText="Submit" width="120"/>
                                <Button className="cancel-edit-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){setBuilderToEdit(null)}})} handleClick={((e) => setBuilderToEdit(null))} buttonText="Cancel" width="120"/>
                            </div>}
                            {canDeleteBuilder &&
                            <div className="three-button-box">
                                <Button className="submit-edit-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){updateBuilder()}})} handleClick={((e) => updateBuilder())} buttonText="Submit" width="120"/>
                                <Button className="cancel-edit-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){setBuilderToEdit(null)}})} handleClick={((e) => setBuilderToEdit(null))} buttonText="Cancel" width="120"/>
                                <Button className="submit-delete-builder" handleKeyPress={((e) => {if(e.key === 'Enter'){setBuilderToDelete(builderToEdit)}})} handleClick={((e) => setBuilderToDelete(builderToEdit))} buttonText="Delete" width="120"/>
                            </div>}
                        </div>
                    );
                }

                const position = index === 0 ? 'first' : index === (builders.length - 1) ? 'last' : '';
                return (
                    <div key={`${builder.id}-${index}`} className={`grid-table-row ${builder.active ? '' : 'in-active'}`}>
                        <div className={`grid-table-row-data ${position} far-left far-right`} onClick={(e) => setBuilderToEdit(builder)}>{builder.name}</div>
                    </div>
                )
            })}
            {errorNameMissing && <Popup color={PopupFailureColor} message={`Please add builder's name.`} handlePopup={resetErrorPopups}/>}
            {errorDuplicateName && <Popup color={PopupFailureColor} message={'Name already exists. Please add a unique builder name.'} handlePopup={resetErrorPopups}/>}
            {builderToDelete && <PopupOk color={PopupOkWarningColor} message={`You are deleting ${builderToDelete.name} from the database. This action is NOT reversable. Do you wish to continue?`} handlePopup={deleteBuilder}/>}
            {newBuilderSuccess && <Popup color={PopupSuccessColor} message={'The new builder has been saved to the database'} handlePopup={resetSuccessPopups}/>}
            {editBuilderSuccess && <Popup color={PopupSuccessColor} message={'The builder has been updated and saved to the database'} handlePopup={resetSuccessPopups}/>}
            {deleteBuilderSuccess && <Popup color={PopupSuccessColor} message={'The employee has been deleted from the database'} handlePopup={resetSuccessPopups}/>}
        </BuildersPageContainer>
    );
}

export default BuildersPage;