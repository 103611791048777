import styled from 'styled-components';

const ViewLotsContainer = styled.div`
    display: grid;
    margin: 10px 10px 150px 10px;

    .top-boxes{
        display: grid;
        justify-content: space-between;
        width: 1510px;
        grid-template-columns: 1fr 1fr;

        .filter-box{
            display: grid;
            // grid-template-rows: repeat(5, 1fr);
            grid-template-rows: 40px 50px 50px 50px 50px;
            width: 600px;
            background: rgb(210, 200, 190);
            border-bottom: 3px solid white;

            .filter-box-header{
                display: grid;
                grid-row: 1/2;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 30px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .job-lot-box{
                display: grid;
                align-items: center;
                // grid-row: 2/3;
                grid-template-columns: 300px, 300px;

                .job-filter{grid-column: 1/2; width: 300px;}
                .lot-filter{grid-column: 2/3; width: 300px;}
            }

            .phase-status-box{
                display: grid;
                // grid-row: 3/4;
                grid-template-columns: 300px, 300px;
                grid-template-rows: 1fr, 1fr;

                .phase-filter{grid-column: 1/2; grid-row: 1/2; width: 300px;}
                .include-bluestake{grid-column: 1/2; grid-row: 2/3; width: 300px;}
                .status-filter{grid-column: 2/3; grid-row: 1/2; width: 300px;}
            }

            .date-range-box{
                display: grid;
                align-items: center;
                grid-template-columns: 1fr, 1fr;
    
                .start-date{grid-column: 1/2;}
                .end-date{grid-column: 2/3;}
            }
        }

        .stats-box{
            display: inline-block;
            width: 900px;
            background: rgb(210, 200, 190);
            padding-bottom: 10px;
            border-bottom: 3px solid white;

            .stats-box-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 30px;
                margin-bottom: 10px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .stats{
                display: grid;
                width: 880px;
                margin: auto;
        
                .stat-row{
                    display: grid;
                    grid-template-columns: 250px 100px 100px 100px 100px 100px 100px;
                    border-bottom: 2px solid black;
        
                    .stat-row-data{
                        display: grid;
                        align-self: center;
                        text-align: center;
                        padding 2px;
                    }
                }
            }
        }
    }

    .page-header{
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        width: 1990px;
        margin-right: 10px;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;

        .export-to-xlsx{
            grid-column: 1/3;
        }

        .header-title{
            grid-column: 9/13;
            justify-self: center;
            align-self: center;
            display: grid;
            font-size: 18px;
            color: white;
        }
    }

    .table-header{
        display: grid;
        width: 1990px;
        grid-template-columns:  60px 230px 60px 90px 390px 140px 30px
                                110px 110px 110px 110px 110px 110px 110px 110px 110px;
        background: rgb(210, 200, 190);

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 140px;
        z-index: 5;

        .grid-table-header-data{
            display: grid;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px 0;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        .grid-table-header-data.index0{
            border-left: 2px solid black;
        }

        .grid-table-header-data.index15{
            border-right: 2px solid black;
        }
    }

    .grid-table-row{
        display: grid;
        width: 1990px;
        min-height: 63px;
        grid-template-columns:  60px 230px 60px 90px 390px 140px 30px
                                110px 110px 110px 110px 110px 110px 110px 110px 110px;

        .grid-table-row-data{
            display: grid;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 3px 0;
            border: 1px solid black;

            .display-members-dropdown{
                width: 330px;
            }
        }

        .grid-table-row-data.gray{
            background: rgb(40, 40, 40);
            color: white;
        }

        .grid-table-row-data.first{ border-top: 2px solid black; }
        .grid-table-row-data.last{ border-bottom: 2px solid black; }
        .grid-table-row-data.far-left{ border-left: 2px solid black; }
        .grid-table-row-data.far-right{ border-right: 2px solid black; }

        .grid-table-row-data.left-align{
            display: grid;
            justify-content: start;
            padding-left: 5px;
        }
    
        .grid-table-row-data.bold{
            font-weight: bold;
        }

        .grid-table-row-data.red{
            color: red;
        }

        :nth-child(even) { 
            background: rgb(230, 230, 220); 

            .drop-down-members{
                background: rgb(230, 230, 220);
            }
        }
    }

    .grid-table-row.in-active{
        background: rgb(180, 180, 180);
    }
`;

export default ViewLotsContainer;