import styled from 'styled-components';

const LotNotesContainer = styled.div`
    display: grid;
    width: ${props => props.width}px;
    background: rgb(210, 200, 190);

    .notes-header{
        width: ${props => props.width}px;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;

        .title{ grid-column: 5/7; }
        .close-out{
            display: grid;
            grid-column: 9/11;
            align-items: center; 

            .close-out-button {
                justify-self: center;
                width: 60px;
                height: 20px;
                font-size: 15px;
                color: black;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
        
                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }
    }

    .note-box{
        display: grid;
        grid-template-columns: calc(${props => props.width}px * .35) calc(${props => props.width}px * .65);

        .note-info-box{
            grid-column: 1/2;
            border: 2px solid black;
            border-right: 1px solid black;

            .note-date, .note-user, .note-type{
                padding: 2px 0 2px 5px
            }
        }

        .note-note-box{
            grid-column: 2/3;
            border: 2px solid black;
            border-left: 1px solid black;

            .note-note{
                padding: 5px;
            }
        }
    }

    .input-box{
        display: grid;
        grid-template-columns: calc(${props => props.width}px * .8) calc(${props => props.width}px * .2);

        .note-input{
            grid-column: 1/2;
            width: calc(${props => props.width}px * .75);
            height: 18px;
            align-self: center;
            justify-self: center;
        }

        .submit {
            gird-column: 2/3;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            align-items: center;
            margin: 10px 0;

            .button {
                justify-self: center;
                width: calc(${props => props.width}px * .18);
                height: 22px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
        
                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }
    
        
    }
`;

export default LotNotesContainer;