import {useState} from 'react';

import Lots from '../../classes/Lots.js';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Globals from '../utilities/Globals.js';
import Popup, {PopupSuccessColor} from '../utilities/Popup.js';
import PlumbingCameraQAFormContainer from './styles/PlumbingCameraQAFormContainer.js';
import Utilities from '../../classes/Utilities'
import moment from "moment";
import Auth from "../authentication/Auth.js";

const PlumbingCameraQAForm = (props) => {

    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);
    const [didLoadData, setDidLoadData] = useState(false);

    const [showHistory, setShowHistory] = useState(-1)
    const [showAllHistory, setShowAllHistory] = useState(false)

    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState('');
    const [questions, setQuestions] = useState();
    const [success, setSuccess] = useState(false);

    //const [history, setHistory] = useState([]);
    const [historyDisplayToggle, setHistoryDisplayToggle] = useState({});
  //  const [result, setResult] = useState({});

    // Check if user has permissions to edit form
    const canEditCameraQAForm = props.isAuthorized('cameraQA', 'WRITE');

    const loadData = async () => {

        setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));
        setQuestions(await Utilities.loadQuestions(1))
        loadLots(353)
        setDidLoadData(true);
    }

    if(!didLoadData){
        loadData();
    }

    const loadLots = async (jobId) => {
        setLots(Lots.sortLots("jobNumber", "ascending", await Lots.loadLots({jobId: jobId}, jobId), await Jobs.loadJobs(null)));
    }

    const selectJob = (e) => {
        loadLots(e.target.value);
    }

    const selectLot = (e) => {
        // Get selected lot
        const tempSelectedLot = lots.find(lot => lot.id.toString() === e.target.value.toString());

        let questions = {}
        let comments = []
        for(let q=0;q<tempSelectedLot.qa.length;q++) {
            if (tempSelectedLot.qa[q].qa !== undefined) {
                questions[tempSelectedLot.qa[q].qa.name] = tempSelectedLot.qa[q].value
                if (tempSelectedLot.qa[q].qa.name === 'comments') {
                    comments.push({
                            date: tempSelectedLot.qa[q].date,
                            user: tempSelectedLot.qa[q].user.firstname + ' ' + tempSelectedLot.qa[q].user.lastname,
                            value: tempSelectedLot.qa[q].value
                        }
                    )
                }
            }
        }
        setSelectedLot(tempSelectedLot);
        setComments(comments)
    }

    const toggleRadioButton = async (id, index) => {
        if(!canEditCameraQAForm){
            return;
        }

        if(id === null) {
            selectedLot.qa[index].value = selectedLot.qa[index].value === '0' ? '1' : '0'
        }
        else {
            let shouldAdd = true;
            for (let l = 0; l < selectedLot.qa.length; l++) {
                if (selectedLot.qa[l].id === undefined && selectedLot.qa[l].qa_id === selectedLot.qa[index].qa_id) {
                    shouldAdd = false
                    selectedLot.qa[l].user_id = await Auth.getUserId()
                    selectedLot.qa[l].date = moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                    selectedLot.qa[l].value = selectedLot.qa[index].value.toString() === '0' ? '1' : '0'
                    selectedLot.qa[l].user = {
                        id: await Auth.getUserId(),
                        firstname: JSON.parse(await Auth.getFirstName()).firstName,
                        lastname: JSON.parse(await Auth.getLastName()).lastName
                    }
                    break
                }
            }
            if (shouldAdd) {
                let newQA = {}
                newQA.field_id = selectedLot.qa[index].field_id
                newQA.lot_id =  selectedLot.id
                newQA.qa_id =  selectedLot.qa[index].qa_id
                newQA.user_id = await Auth.getUserId()
                newQA.date = moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                newQA.value = selectedLot.qa[index].value.toString() === '0' ? '1' : '0'
                selectedLot.qa[selectedLot.qa.length] = newQA
            }
        }

        //Fastest way I could think of to get this updated for the front end.
       selectedLot.qa_frontend[index].value = selectedLot.qa_frontend[index].value === '0' ? '1' : '0'


        const tempHistoryDisplayToggle = selectedLot.qa.filter(lq => lq.qa_id === selectedLot.qa[index].qa.id)
        setHistoryDisplayToggle(tempHistoryDisplayToggle);
        //just doing this to stop the unused var warning.
        return historyDisplayToggle
    }

    const toggleHistoryDisplay = (id) => {
        if(showHistory > -1 && showHistory.toString() === id.toString()){
            setShowHistory(-1)
        }
        else {
            setShowHistory(id)
        }
    }

    const handleShowAllHistory = () => {
        if(showAllHistory){
            setShowAllHistory(false)
        }
        else {
            setShowAllHistory(true)
        }
    }

    const handleSubmitCameraQAForm = async (e) => {
        if(!canEditCameraQAForm){
            return;
        }

        if(newComment.length > 0) {
            let date = new Date()
            let newQA = {}
            newQA.field_id = selectedLot.qa[0].field_id
            newQA.lot_id = selectedLot.id
            newQA.qa_id = questions.find(q => q.field_id.toString() === selectedLot.qa[0].field_id.toString() && q.name === 'comments').id
            newQA.user_id = await Auth.getUserId()
            newQA.date = moment(date).format('YYYY-MM-DD H:mm:ss')
            newQA.value = newComment
            selectedLot.qa[selectedLot.qa.length] = newQA

            selectedLot.qa_frontend[selectedLot.qa_frontend.length] = newQA
            selectedLot.qa_history[selectedLot.qa_history.length - 1].comments.splice(0, 0, newQA)
            comments.splice(0, 0,{
                    date: date,
                    user: await Auth.getFullName(),
                    value: newComment
                }
            )
            setComments(comments)
        }

        const updatedLot = await Lots.updateLot(selectedLot, null);

        //Update lots list
        let tempLots = lots;
        const selectedLotIndex = lots.findIndex(lot => lot.id.toString() === selectedLot.id.toString());
        tempLots[selectedLotIndex] = updatedLot[0];
        setLots(tempLots);
        setNewComment('');
        setSuccess(true);
    }

    const togglePopup = () => {
        setSuccess(false);
    }

    return (
        <PlumbingCameraQAFormContainer>
            <div className='plumbing-camera-qa-form-header'>Plumbing Camera - QA Form</div>
            <div className='plumbing-camera-qa-form-sub-header'>Select Job & Lot</div>
            <div className='plumbing-camera-qa-form-job-drop-down-box'>
                <label className="plumbing-camera-qa-form-job-drop-down-label" htmlFor='plumbing-camera-qa-form-job-select'>Job</label>
                <select className="plumbing-camera-qa-form-job-drop-down-select" name='plumbing-camera-qa-form-job-select' onChange={selectJob}>
                    <option className="plumbing-camera-qa-form-job-drop-down-option" value=''></option>
                    {jobsForDropDowns.map(job => {
                        return (
                            <option key={job.id} className="plumbing-camera-qa-form-job-drop-down-option" value={job.id}>{job.display}</option>
                        )
                    })}
                </select>
            </div>
            <div className='plumbing-camera-qa-form-lot-drop-down-box'>
                <label className="plumbing-camera-qa-form-lot-drop-down-label" htmlFor='plumbing-camera-qa-form-lot-select'>Lot</label>
                <select className="plumbing-camera-qa-form-lot-drop-down-select" name='plumbing-camera-qa-form-lot-select' onChange={selectLot}>
                    <option className="plumbing-camera-qa-form-lot-drop-down-option" value=''></option>
                    {lots.map(lot => {
                        const value = lot.id;
                        const display = `${lot.number} - ${lot.phase} - ${lot.address}`;
                        return (
                            <option key={value} className="plumbing-camera-qa-form-lot-drop-down-option" value={value}>{display}</option>
                        )
                    })}
                </select>
            </div>
            {selectedLot &&
                <div className='qa-form-box'>
                    <div className='qa-form-sub-header'>Camera - QA Form</div>
                    <div className='qa-form-question-box'>
                        <div className='qa-form-show-all-history-submit'>
                            <div tabIndex='0' className='qa-form-show-all-history-button' onClick={handleShowAllHistory}>{showAllHistory ? 'Collapse All History' : 'Show All History'}</div>
                        </div>
                    </div>
                    {questions.map((q) => {
                        const result = selectedLot.qa_frontend.find(r => r.qa_id.toString() === q.id.toString())
                        const history = selectedLot.qa_history.filter(h => h[q.name] !== undefined)
                        const index = selectedLot.qa_frontend.indexOf(result)
                        return (
                            selectedLot.qa_frontend[index] && q.name !== 'comments' &&
                            <div className='qa-form-question-box' key={`qa-form-question-box-${result.qa_id}`}>
                                <div className='qa-form-question-number'
                                     key={`qa-form-question-number-${result.qa_id}`}>{q.id}.
                                </div>
                                <div className='qa-form-question-question'
                                     key={`qa-form-question-question-${result.qa_id}`}>{q.display}</div>
                                <input type='radio' className='qa-form-radio-button-yes'
                                       disabled={!canEditCameraQAForm}
                                       name={`question${q.id}`} key={`question${q.id}-true`} value='1'
                                       checked={selectedLot.qa_frontend[index].value.toString() === '1'}
                                       onChange={(e) => toggleRadioButton(result.qa_id, index)}/>
                                <label className='qa-form-radio-label-yes' key={`qa-form-radio-label-yes-${result.qa_id}`} htmlFor='yes'>Yes</label>
                                <input type='radio' className='qa-form-radio-button-no'
                                       disabled={!canEditCameraQAForm} name={`question${q.id}`} key={`question${q.id}-false`} value='0'
                                       checked={selectedLot.qa_frontend[index].value.toString() === '0'}
                                       onChange={(e) => toggleRadioButton(result.qa_id, index)}/>
                                <label className='qa-form-radio-label-no' key={`qa-form-radio-label-no-${result.qa_id}`} htmlFor='no'>No</label>
                                <div className='qa-form-history-button' key={`qa-form-history-button-${result.qa_id}`}
                                     onClick={(e) => toggleHistoryDisplay(result.qa_id)}>{(showHistory.toString() === result.qa_id.toString() || showAllHistory) ? '\u2191' : '\u2193'}</div>
                                {(showHistory.toString() === result.qa_id.toString() || showAllHistory) &&
                                    <div className='qa-form-question-history-box'>
                                        {/*<div>{q.name} = {JSON.stringify(selectedLot.qa_history[0][q.name])}</div>*/}
                                        {history && history[0] && history[0][q.name] ?
                                            history[0][q.name].map((h, hindex) => {
                                                return (
                                                    <div className='qa-form-question-history' key={`qa-form-${hindex}`}>
                                                        <div
                                                            className='qa-form-question-history-date'>{Globals.formatDateToDisplay(h.date)}</div>
                                                        <div
                                                            className='qa-form-question-history-name'>{h.user.firstname} {h.user.lastname}</div>
                                                        <div
                                                            className='qa-form-question-history-result'>{h.value.toString() === '1' ? 'Yes' : 'No'}</div>
                                                    </div>
                                                )
                                            })
                                            : <div className='qa-form-question-no-history'>No History To Display</div>}
                                    </div>}
                            </div>
                        )
                    })}
                    <div className='qa-form-comment-box' key={`qa-form-comment-box`}>
                        <div className='qa-form-comments-header' key={`qa-form-comments-header`}>Comments:</div>
                        {comments.length > 0 &&
                            comments.map((comment, index) => {
                                return (
                                    <div key={`qa-form-comment-${index}`} className='qa-form-comment-history'>
                                        <div className='qa-form-comment-history-date' key={`qa-form-comment-history-date-${index}`}>{Globals.formatDateToDisplay(comment.date)}</div>
                                        <div className='qa-form-comment-history-name' key={`qa-form-comment-history-name-${index}`}>{comment.user}</div>
                                        <div className='qa-form-comment-history-comment' key={`qa-form-comment-history-comment-${index}`}>{comment.value}</div>
                                    </div>
                                )
                            })}
                        {canEditCameraQAForm &&
                            <div className='qa-form-comment-text-area' key={`qa-form-comment-text-area`}>
                                <textarea className="qa-form-comment-text-input" key={`qa-form-comment-text-input`}
                                          name='qa-form-comment' rows='6' cols='47' placeholder='Enter New Comment'
                                          value={newComment} onChange={((e) => setNewComment(e.target.value))}/>
                            </div>}
                    </div>
                    {canEditCameraQAForm &&
                        <div className='qa-form-submit' key={`qa-form-submit`}>
                            <div tabIndex='0' className='qa-form-submit-button' key={`qa-form-submit-button`}
                                 onClick={handleSubmitCameraQAForm}>Submit Camera QA Form
                            </div>
                        </div>}
                </div>
            }
            {success && <Popup color={PopupSuccessColor} message={`Camera - QA Form has been submitted`} handlePopup={togglePopup}/>}
        </PlumbingCameraQAFormContainer>
    )
}

export default PlumbingCameraQAForm;