
// TODO This component needs a rewrite
import { useState } from 'react';
import Auth from '../authentication/Auth.js';

import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { Button } from '../utilities/Button.js';
import MainHeader from '../utilities/MainHeader.js';
import Popup, {PopupFailureColor, PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import EditLotContainer from './styles/EditLotContainer.js';

const EditLot = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);
    const [lotToEdit, setLotToEdit] = useState(null);
    
    const [errorNoJobNumber, setErrorNoJobNumber] = useState(false);
    const [errorJobDoesNotExist, setErrorJobDoesNotExist] = useState(false);
    const [errorNoLotNumber, setErrorNoLotNumber] = useState(false);
    const [errorDuplicateLotNumber, setErrorDuplicateLotNumber] = useState(false);
    const [success, setSuccess] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);
        setJobs(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));
    }

    if(!didLoadData){
        loadData();
    }

    const selectJob = async (e) => {
        if(e.target.value === ''){
            setLots([]);
            return;
        }

        setLots(await Lots.loadLotsForDropDown({job_id: e.target.value}));
    }

    const selectLot = (e) => {
        if(e.target.value === ''){
            setLotToEdit(null);
            return;
        }

        setLotToEdit(lots.find(lotToFind => lotToFind.id === Number.parseInt(e.target.value)));
    }

    const setError = (error) => {
        if(error === "ERROR_NO_JOB_NUMBER"){setErrorNoJobNumber(true); return;}
        if(error === "ERROR_JOB_DOES_NOT_EXIST"){setErrorJobDoesNotExist(true); return;}
        if(error === "ERROR_NO_LOT_NUMBER"){setErrorNoLotNumber(true); return;}
        if(error === "ERROR_DUPLICATE_LOT_NUMBER"){setErrorDuplicateLotNumber(true); return;}
    }

    const updateLot = async () => {
        const result = await Lots.checkLotForErrors(lotToEdit);
        if(result !== "SUCCESS"){
            setError(result);
            return;
        }
        
        delete lotToEdit.display;
        await Auth.hitEndpoint("PATCH", "LOTS", "", lotToEdit);
        setLots(await Lots.loadLotsForDropDown({job_id: lotToEdit.job_id}));
        setSuccess(true);
    }

    const toggleWarnings = (e) => {
        setErrorNoJobNumber(false);
        setErrorJobDoesNotExist(false);
        setErrorNoLotNumber(false);
        setErrorDuplicateLotNumber(false);
    }

    const handleSuccess = (e) => {
        setLotToEdit(null);
        setSuccess(false);
    }

    return (
        <EditLotContainer>
            <MainHeader/>
            <div className='header'>Edit Lot</div>
            <div className='sub-header'>Select Job And Lot</div>
            <div className='pre-select-form'>
                <DropDownWithLabel className="select-job" id="select-job-id" name="select-job" label="Select Job" handleDropDown={selectJob} addBlankOption={true} selectionList={jobs} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                <DropDownWithLabel className="select-lot" id="select-lot-id" name="select-lot" label="Select Lot" value={lotToEdit ? lotToEdit.id : 0} handleDropDown={selectLot} addBlankOption={true} selectionList={lots} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
            </div>
            {lotToEdit && 
            <div>
                <div className='sub-header'>Lot Details</div>
                <div className='lot-details'>
                    <TextboxWithLabel className="number" name="number" label="Lot Number" placeholder="Lot Number" value={lotToEdit.number} handleTextbox={((e) => setLotToEdit({...lotToEdit, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                    <TextboxWithLabel className="phase" name="phase" label="Phase ID" placeholder="Phase ID" value={lotToEdit.phase} handleTextbox={((e) => setLotToEdit({...lotToEdit, phase: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                    <TextboxWithLabel className="address" name="address" label="Address" placeholder="Address" value={lotToEdit.address} handleTextbox={((e) => setLotToEdit({...lotToEdit, address: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                    <TextboxWithLabel className="plan-number" name="plan-number" label="Plan Number" placeholder="Plan Number" value={lotToEdit.plan_number} handleTextbox={((e) => setLotToEdit({...lotToEdit, plan_number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                    <CheckboxWithLabel className="is-active" name="is-active" label="Is Active" checked={lotToEdit.active} handleCheckbox={((e) => setLotToEdit({...lotToEdit, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <CheckboxWithLabel className="is-pre-release" name="is-pre-release" label="Allow Pre-Release" checked={lotToEdit.allow_schedule_no_release} handleCheckbox={((e) => setLotToEdit({...lotToEdit, allow_schedule_no_release: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                    <CheckboxWithLabel className="is-multi-schedule" name="is-multi-schedule" label="Can Multi-Schedule" checked={lotToEdit.all_tasks_multi_schedule} handleCheckbox={((e) => setLotToEdit({...lotToEdit, all_tasks_multi_schedule: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <Button className="update-lot" handleKeyPress={((e) => {if(e.key === 'Enter'){updateLot()}})} handleClick={((e) => updateLot())} buttonText="Update Lot" width="120"/>
                </div>
            </div>}
            {errorNoJobNumber && <Popup color={PopupWarningColor} message={'Please select a job number'} handlePopup={toggleWarnings}/>}
            {errorJobDoesNotExist && <Popup color={PopupWarningColor} message={'Please select a job number, that job does not exist'} handlePopup={toggleWarnings}/>}
            {errorNoLotNumber && <Popup color={PopupWarningColor} message={'Please select a lot number'} handlePopup={toggleWarnings}/>}
            {errorDuplicateLotNumber && <Popup color={PopupFailureColor} message={'Lot already exists for this job. Please enter a unique lot'} handlePopup={toggleWarnings}/>}
            {success && <Popup color={PopupSuccessColor} message={`Lot has been updated.`} handlePopup={handleSuccess}/>}
        </EditLotContainer>
    );
}

export default EditLot;
