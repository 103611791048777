import styled from "styled-components";
import {useState} from "react";


const SliderAvailableCheckboxContainer = styled.div`

    display: grid;
    
     /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      background-color: #cc0d00;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: #cc0d00;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #00FF00;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(44px);
      -ms-transform: translateX(44px);
      transform: translateX(44px);
    }

    .hide-label{
        display: grid;
        font-size: xx-small;
        text-align: right;
        margin-top: -9px;
    }
    .hide-label.hide{
        width: 95%;
    }
    .hide-label.show{
        width: 90%;
    }
`

export const SliderAvailableCheckbox = ({data, searchTerm, setData}) => {
    const [hideData, setHideData] = useState(false)

    const handleShowHide = () => {
        let hide = hideData !== true
        setHideData(hide)
        let filterData
        if (hide) {
            filterData = data.filter(x => (x.number.includes(searchTerm) || x.address.includes(searchTerm)) && x.available === null)
        } else {
            filterData = data.filter(x => (x.number.includes(searchTerm) || x.address.includes(searchTerm)))
        }
        setData(filterData)
    }
    return (
         <SliderAvailableCheckboxContainer>
            <div>
                <label className="switch">
                    <input type="checkbox" onChange={handleShowHide}/>
                    <span className="slider"></span>
                </label>
                {!hideData && <span className={'hide-label show'}>Showing All</span>}
                {hideData && <span className={'hide-label hide'}>Showing Available</span>}
            </div>
         </SliderAvailableCheckboxContainer>
    );
}
