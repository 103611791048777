import {useState} from 'react';
import moment from "moment/moment";

import Auth from '../authentication/Auth.js';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import Globals from '../utilities/Globals.js';
import MainHeader from '../utilities/MainHeader.js';
import ViewLotsContainer from './styles/ViewLotsContainer.js'; 
import PagesData, { Pages } from '../utilities/Pages.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { DateboxWithLabel } from '../utilities/Datebox.js';
import { Button } from '../utilities/Button.js';
import { LoadingMessage } from '../utilities/LoadingMessage.js';
import LotReleases from './LotReleases.js';
import BlueStake from './BlueStake.js';
import ViewLotsEditLot from './ViewLotsEditLot.js';
import LotNotes from './LotNotes.js';
import Popup, {PopupFailureColor, PopupWarningColor} from '../utilities/Popup.js';

const ViewLots = (props) => {
    const [didLoadStats, setDidLoadStats] = useState(false);
    const [didLoadData, setDidLoadData] = useState(false);

    const [stats, setStats] = useState([]);

    const [isLoadingLots, setIsLoadingLots] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
        jobId: "",
        lotId: "",
        includeBluestake: false,
        phase: "",
        status: "",
        startDate: null,
        endDate: null
    });

    const [allJobs, setAllJobs] = useState([]);
    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);

    const [lots, setLots] = useState([]);
    const [lotsForDropDowns, setLotsForDropDowns] = useState([]);

    const [phasesForDropDowns, setPhasesForDropDowns] = useState([]);

    const [displayEditLot, setDisplayEditLot] = useState("");
    const [displayNotes, setDisplayNotes] = useState("");
    const [displayBlueStake, setDisplayBlueStake] = useState("");
    const [displayLotReleases, setDisplayLotReleases] = useState("");

    const [errorNoFilters, setErrorNoFilters] = useState(false);
    const [errorPhaseWithoutStatus, setErrorPhaseWithoutStatus] = useState(false);
    const [errorFilterReturnsNoLotsToDisplay, setFilterReturnsNoLotsToDisplay] = useState(false);

    const [pagesData, setPagesData] = useState(PagesData.createPagesData());

    let canEditLot = props.isAuthorized('lots', 'WRITE');
    let canViewBlueStake = props.isAuthorized('blueStake', 'READ');
    let canViewLotReleases = props.isAuthorized('lotReleases', 'READ');

    const loadStats = async () => {
        setDidLoadStats(true);
        const tempStats = await Auth.hitEndpoint("GET", "STATS", `selector=twentyfour`);
        setStats(tempStats);
    }

    if(!didLoadStats){
        loadStats();
    }

    const loadData = async () => {
        setDidLoadData(true);
        setAllJobs(await Jobs.loadJobs(null));
        setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, Builders.sortBuilders("name", "ascending", await Builders.loadBuilders())));
        setPhasesForDropDowns((await Auth.hitEndpointNew("GET", "phases")).map(p => {
            p.display = p.stat_name;
            return p;
        }).sort((p1, p2) => p1.stats_order_by < p2.stats_order_by ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const resetErrorPopups = () => {
        setErrorNoFilters(false);
        setErrorPhaseWithoutStatus(false);
        setFilterReturnsNoLotsToDisplay(false);
    }

    const loading = (isLoading) => {
        if(isLoading){
            setLots([]);
        }
        setIsLoadingLots(isLoading);
    }

    const handleJobSelect = async (e) => {
        setFilterOptions({...filterOptions, jobId: e.target.value});
        setLotsForDropDowns(await Lots.loadLotsForDropDown("", e.target.value));
    }

    const applyFilters = async () => {
        if(isLoadingLots){
            return;
        }

        if( filterOptions.jobId === "" && filterOptions.phase === "" && filterOptions.status === "" && !filterOptions.startDate && !filterOptions.endDate){
            setErrorNoFilters(true);
            return;
        }
        if(filterOptions.phase !== "" && filterOptions.status === ""){
            setErrorPhaseWithoutStatus(true);
            return;
        }

        loading(true);
        
        let queryString = '';
        if(filterOptions.jobId !== ""){
            queryString += `job_id=${filterOptions.jobId} and `;
        }

        if(filterOptions.lotId !== ""){
            queryString += `lot.id=${filterOptions.lotId} and `;
        }

        if(filterOptions.phase !== ""){
            queryString += `lot_phase.phase_id=${filterOptions.phase} and `
        }
        if(filterOptions.status === "Not Released"){
            queryString += 'lot_phase.release_date is null and '
        }
        if(filterOptions.status === "Released"){
            if(filterOptions.startDate !== null){
                queryString += `lot_phase.release_date>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD H:mm:ss')}' and `
            }
            if(filterOptions.endDate !== null){
                queryString += `lot_phase.release_date<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD H:mm:ss')}' and `
            }
            if(filterOptions.startDate === null && filterOptions.endDate === null){
                queryString += 'lot_phase.release_date is not null and '
            }
        }
        if(filterOptions.status !== "" && filterOptions.status !== "Not Released" && filterOptions.status !== "Released"){
            queryString += `lot_phase.starts_status='${filterOptions.status}' and `;
        }
        
        // Trim 'and' off end of whereStr
        if(queryString !== ''){
            queryString = queryString.slice(0, queryString.length - 5);
        }
        
        let tempLots = await Auth.hitEndpointNew("GET", `lots/index-new`, `queryString=${queryString}`);
        if(tempLots.length === 0){
            setFilterReturnsNoLotsToDisplay(true);
        }
        if(filterOptions.includeBluestake){
            const withoutBluestake = tempLots.filter(l => l.bluestake.length === 0);
            const withBluestake = tempLots.filter(l => l.bluestake.length > 0);
            withoutBluestake.sort((l1, l2) => {
                const j1 = allJobs.find(j => parseInt(j.id) === parseInt(l1.job_id));
                const j2 = allJobs.find(j => parseInt(j.id) === parseInt(l2.job_id));
                
                if(parseInt(j1.number) === parseInt(j2.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                
                return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;
            });
            withBluestake.sort((l1, l2) => {
                const j1 = allJobs.find(j => parseInt(j.id) === parseInt(l1.job_id));
                const j2 = allJobs.find(j => parseInt(j.id) === parseInt(l2.job_id));
                
                if(parseInt(j1.number) === parseInt(j2.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                
                return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;
            });
            tempLots = [...withoutBluestake, ...withBluestake];
        }
        else{
            tempLots.sort((l1, l2) => {
                const j1 = allJobs.find(j => parseInt(j.id) === parseInt(l1.job_id));
                const j2 = allJobs.find(j => parseInt(j.id) === parseInt(l2.job_id));
                
                if(parseInt(j1.number) === parseInt(j2.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                
                return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;
            });
        }
        

        setLots(tempLots);

        pagesData.currentPage = 1;
        loading(false);
    }

    const closeNotes = () => {
        setDisplayNotes("");
    }

    const closeEditLot = (lot, didDelete, didCancel) => {
        if(didCancel){
            setDisplayEditLot("");
            return;
        }
        if(didDelete){
            setLots(lots.filter(lotToFilter => lotToFilter.id !== lot.id));
        }
        else{
            const tempLots = [...lots]
            const index = tempLots.findIndex(lotToFind => lotToFind.id === lot.id);
            tempLots[index] = lot;
            setLots([...tempLots]);
        }

        setDisplayEditLot("");
    }

    const closeBlueStake = (lot) => {
        const tempLots = [...lots]
        const index = tempLots.findIndex(lotToFind => lotToFind.id === lot.id);
        tempLots[index] = lot;
        setLots([...tempLots]);
        setDisplayBlueStake("");
    }

    const closeLotReleases = async (lot) => {
        const tempLots = [...lots]
        const index = tempLots.findIndex(lotToFind => lotToFind.id === lot.id);
        tempLots[index] = lot;
        setLots([...tempLots]);
        setDisplayLotReleases("");
    }

    const exportToXLSX = async () => {
        document.getElementById('export-to-xlsx-id').innerHTML = 'Exporting...';

        const exportData = [];
        const builders = await Builders.loadBuilders();
        for(let i = 0; i < lots.length; i++){
            const currentJob = allJobs.find(job => job.id === lots[i].job_id);
            const currentBuilder = builders.find(builder => builder.id === currentJob.builder_id);

            const rfgt = lots[i].phases.find(p => parseInt(p.phase_id) === 6);
            const concreteFoundation = lots[i].phases.find(p => parseInt(p.phase_id) === 1);
            const plumbingSoil = lots[i].phases.find(p => parseInt(p.phase_id) === 3);
            const plumbingTopOut = lots[i].phases.find(p => parseInt(p.phase_id) === 4);
            const masonry = lots[i].phases.find(p => parseInt(p.phase_id) === 7);
            const concreteDriveway = lots[i].phases.find(p => parseInt(p.phase_id) === 2);
            const paver = lots[i].phases.find(p => parseInt(p.phase_id) === 8);
            const plumbingTrim = lots[i].phases.find(p => parseInt(p.phase_id) === 5);

            exportData.push({
                "Is Active":                    lots[i].active === '1' ? 'X' : '',
                "Builder":                      currentBuilder.name,
                "Job #":                        currentJob.number,
                "Project Name":                 currentJob.project_name,
                "Lot #":                        lots[i].number,
                "Phase ID":                     lots[i].phase,
                "Address":                      lots[i].address,
                "Plan ID":                      lots[i].plan_number,
                "RFGT Status":                  rfgt !== undefined ? rfgt.starts_status : '',
                "RFGT Date":                    rfgt !== undefined && rfgt !== null ? Globals.formatDateToDisplay(rfgt.release_date) : '',
                "Concrete Foundation Status":   concreteFoundation !== undefined ? concreteFoundation.starts_status : '',
                "Concrete Foundation Date":     concreteFoundation !== undefined && concreteFoundation !== null ? Globals.formatDateToDisplay(concreteFoundation.release_date) : '',
                "Plumbing Soil Status":         plumbingSoil !== undefined ? plumbingSoil.starts_status : '',
                "Plumbing Soil Date":           plumbingSoil !== undefined && plumbingSoil !== null ? Globals.formatDateToDisplay(plumbingSoil.release_date) : '',
                "Plumbing Top Out Status":      plumbingTopOut !== undefined ? plumbingTopOut.starts_status : '',
                "Plumbing Top Out Date":        plumbingTopOut !== undefined && plumbingTopOut !== null ? Globals.formatDateToDisplay(plumbingTopOut.release_date) : '',
                "Masonry Status":               masonry !== undefined ? masonry.starts_status : '',
                "Masonry Date":                 masonry !== undefined && masonry !== null ? Globals.formatDateToDisplay(masonry.release_date) : '',
                "Concrete Driveway Status":     concreteDriveway !== undefined ? concreteDriveway.starts_status : '',
                "Concrete Driveway Date":       concreteDriveway !== undefined && concreteDriveway !== null ? Globals.formatDateToDisplay(concreteDriveway.release_date) : '',
                "Paver Status":                 paver !== undefined ? paver.starts_status : '',
                "Paver Date":                   paver !== undefined && paver !== null ? Globals.formatDateToDisplay(paver.release_date) : '',
                "Plumbing Trim Status":         plumbingTrim !== undefined ? plumbingTrim.starts_status : '',
                "Plumbing Trim Date":           plumbingTrim !== undefined && plumbingTrim !== null ? Globals.formatDateToDisplay(plumbingTrim.release_date) : '',
            });
        }

        Globals.exportToXLSXFileFormat(exportData, 'View Lots', './ViewLots.xlsx');

        document.getElementById('export-to-xlsx-id').innerHTML = 'Export To XLSX';
    }

    const buildPhaseClassName = (job, lot, phaseId, jobAttributeId) => {
        const phase = lot.phases.find(phase => parseInt(phase.phase_id) === parseInt(phaseId));

        let className = '';
        if(lot.phases.find(phase => parseInt(phase.phase_id) === parseInt(phaseId))){
            className += phase.starts_status === 'Processed' ? '' : 'red ';
        }
        className += job.attributes.find(a => parseInt(a.attribute_id) === parseInt(jobAttributeId)) ? '' : 'gray ';

        return className;
    }

    const parsePhaseDataForDisplay = (lot, phaseId) => {
        const phase = lot.phases.find(phase => parseInt(phase.phase_id) === parseInt(phaseId));
        if(!phase){
            return "";
        }
        return phase.starts_status === 'Processed' ? Globals.formatDateToDisplay(phase.release_date) : `${phase.starts_status} ${Globals.formatDateToDisplay(phase.release_date)}`;
    }

    const parseBlueStakeDataForDisplay = (lot) => {
        if(lot.bluestake && lot.bluestake.length > 0){
            lot.bluestake.sort((b1, b2) => (new Date(b1.date)) < (new Date(b2.date)) ? 1 : -1)
            return lot.bluestake[0].date !== null ? Globals.formatDateToDisplay(lot.bluestake[0].date) : '';
        }
        return '';
    }

    const columns = ['Job #', 'Project Name', 'Lot #', 'Phase', 'Address',  'Plan', '\u{1f4d3}', 
                     'Blue Stake', 'RFGT', 'Concrete Foundation', <div>Plumbing<br/>Soil</div>, <div>Plumbing<br/>Top Out</div>, 
                     'Masonry', <div>Concrete<br/>Driveway</div>, 'Paver', <div>Plumbing<br/>Trim</div>];
    const lotsToDisplay = PagesData.getCurrentPageData(pagesData, lots);

    return (
        <ViewLotsContainer>
            <MainHeader/>
            <div className='top-boxes'>
                <div className='filter-box'>
                    <div className='filter-box-header'>View Lots Filters</div>
                    <div className="job-lot-box">
                        <DropDownWithLabel className="job-filter" id="job-filter-id" name="job" label="Job" handleDropDown={handleJobSelect} addBlankOption={true} selectionList={jobsForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                        <DropDownWithLabel className="lot-filter" id="lot-filter-id" name="lot" label="Lot" handleDropDown={(e) => setFilterOptions({...filterOptions, lotId: e.target.value})} addBlankOption={true} selectionList={lotsForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                    </div>
                    <div className="phase-status-box">
                        <DropDownWithLabel className="phase-filter" id="phase-filter-id" name="phase" label="Phase" handleDropDown={(e) => setFilterOptions({...filterOptions, phase: e.target.value})} addBlankOption={true} selectionList={phasesForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                        <CheckboxWithLabel className="include-bluestake" name="include-bluestake" label="By Bluestake" checked={filterOptions.includeBluestake} handleCheckbox={(e) => setFilterOptions({...filterOptions, includeBluestake: e.target.checked})} columns="10" labelStart="1" labelEnd="7" inputStart="7" inputEnd="10"/>
                        <DropDownWithLabel className="status-filter" id="status-filter-id" name="status" label="Status" handleDropDown={(e) => setFilterOptions({...filterOptions, status: e.target.value})} addBlankOption={true} selectionList={Lots.getReleasePhaseStatuses()} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={10}/>
                    </div>
                    <div className="date-range-box">
                        <DateboxWithLabel className="start-date" label="Start Date" selected={filterOptions.startDate} handleDatebox={(date) => setFilterOptions({...filterOptions, startDate: date})} enableTabLoop={false} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                        <DateboxWithLabel className="end-date" label="End Date" selected={filterOptions.endDate} handleDatebox={(date) => setFilterOptions({...filterOptions, endDate: date})} enableTabLoop={false} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    </div>
                    <Button className="apply-filter" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await applyFilters()}})} handleClick={(async (e) => await applyFilters())} buttonText="Apply" width="120"/>
                </div>
                <div className='stats-box'>
                    <div className='stats-box-header'>Statistics</div>
                        <div className='stats'>
                            {stats.map((stat, index) => {
                                return (
                                    <div key={`stat-row${index}`} className='stat-row'>
                                        <div className='stat-row-data'>{stat[0]}</div>
                                        <div className='stat-row-data'>{stat[1]}</div>
                                        <div className='stat-row-data'>{stat[2]}</div>
                                        <div className='stat-row-data'>{stat[3]}</div>
                                        <div className='stat-row-data'>{stat[4]}</div>
                                        <div className='stat-row-data'>{stat[5]}</div>
                                        <div className='stat-row-data'>{stat[6]}</div>
                                        <div className='stat-row-data'>{stat[7]}</div>
                                    </div>
                                )
                            })}
                        </div>
                </div>
            </div>
            {isLoadingLots && <LoadingMessage message="Loading Lots..." width="600px"/>}
            {!isLoadingLots && (lotsToDisplay.length > 0) && 
            <div className='page-header'>
                <Button className="export-to-xlsx" handleClick={((e) => exportToXLSX())} buttonText="Export To XLSX" buttonMargin="0 0 0 0" width="120" height="20"/>
                <div className='header-title'>View Lots</div>
            </div>}
            {!isLoadingLots && (lotsToDisplay.length > 0) && 
            <div className='table-header'>
                {columns.map((column, index)=> {
                    return <div key={`${column}-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                })}
            </div>}
            {lotsToDisplay.map((lotToDisplay, index) => {
                const currentJob = allJobs.find(jobToFind => jobToFind.id === lotToDisplay.job_id);
                const position = index === 0 ? 'first' : index === (lots.length - 1) ? 'last' : '';
                const notesDisplay = lotToDisplay.notes === "" ? "" : '\u{1f4d3}';
                return (
                    <div key={`${lotToDisplay.id}-${index}`} className={`grid-table-row ${lotToDisplay.active ? '' : 'in-active'}`}>
                        <div className={`grid-table-row-data ${position} far-left`}>{currentJob.number}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayEditLot(lotToDisplay.id)}>{currentJob.project_name}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayEditLot(lotToDisplay.id)}>{lotToDisplay.number}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayEditLot(lotToDisplay.id)}>{lotToDisplay.phase}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayEditLot(lotToDisplay.id)}>{lotToDisplay.address}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayEditLot(lotToDisplay.id)}>{lotToDisplay.plan_number}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayNotes(lotToDisplay.id)}>{notesDisplay}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setDisplayBlueStake(lotToDisplay.id)}>{parseBlueStakeDataForDisplay(lotToDisplay)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 6, 7)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 6)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 1, 1)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 1)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 3, 2)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 3)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 4, 3)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 4)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 7, 9)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 7)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 2, 1)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 2)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 8, 10)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 8)}</div>
                        <div className={`grid-table-row-data ${position} ${buildPhaseClassName(currentJob, lotToDisplay, 5, 4)}`} onClick={(e) => setDisplayLotReleases(lotToDisplay.id)}>{parsePhaseDataForDisplay(lotToDisplay, 5)}</div>
                        {parseInt(displayNotes) === parseInt(lotToDisplay.id) && <LotNotes isAuthorized={props.isAuthorized} closeNotes={closeNotes} lot={lotToDisplay} type='lot' width='600'/>}
                        {canEditLot && parseInt(displayEditLot) === parseInt(lotToDisplay.id) && <ViewLotsEditLot isAuthorized={props.isAuthorized} closeEditLot={closeEditLot} lot={lotToDisplay}/>}
                        {canViewBlueStake && parseInt(displayBlueStake) === parseInt(lotToDisplay.id) && <BlueStake isAuthorized={props.isAuthorized} closeBlueStake={closeBlueStake} lot={lotToDisplay}/>}
                        {canViewLotReleases && parseInt(displayLotReleases) === parseInt(lotToDisplay.id) && <LotReleases isAuthorized={props.isAuthorized} closeLotReleases={closeLotReleases} job={currentJob} lot={lotToDisplay}/>}
                    </div>
                )
            })}
            {!isLoadingLots && (lotsToDisplay.length > 0) &&  <Pages contentLength={lots.length} pagesData={pagesData} pageBack={() => setPagesData({...pagesData, currentPage: PagesData.pageBack(pagesData)})} pageForward={() => setPagesData({...pagesData, currentPage: PagesData.pageForward(pagesData, lots.length)})} handleInputPageNumber={(value) => setPagesData({...pagesData, currentPage: PagesData.handleInputPageNumber(value, pagesData, lots.length)})}/>}
            {errorNoFilters && <Popup color={PopupFailureColor} message={"Please select at least 1 filter."} handlePopup={resetErrorPopups}/>}
            {errorPhaseWithoutStatus && <Popup color={PopupFailureColor} message={"Phase filter requires a Status. Please select a Status."} handlePopup={resetErrorPopups}/>}
            {errorFilterReturnsNoLotsToDisplay && <Popup color={PopupWarningColor} message={"No lots matching current filter selection."} handlePopup={resetErrorPopups}/>}
        </ViewLotsContainer>
    )
};

export default ViewLots;
