import Auth from "../components/authentication/Auth";

import utc from"dayjs/plugin/utc";
import weekday from 'dayjs/plugin/weekday'
import weekYear from 'dayjs/plugin/weekYear' // dependent on weekOfYear plugin
import weekOfYear from 'dayjs/plugin/weekOfYear'
import EWAs from "./Ewas";
import {createTheme} from "@mui/material/styles";
import Phases from "./Phases";
import Employees from "./Employees";
import Crews from "./Crews";
import PhaseTasks from "./PhaseTasks";
import dayjs from "dayjs";


dayjs.extend(utc);
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
class Schedules {

    static async loadFields(queryObj) {

        let dbData = await Auth.hitEndpointNew("GET", `fields`);
        let data = []
        data.push({
            id: -1,
            name: "select_all",
            display: "Select All",
            group_parent_id: -1,
            group_parent_name: "Select All"
        })


        return dbData;
    }

    static async loadSupers(queryObj) {

        let data = [];
        let dbData;
        if(queryObj !== undefined && queryObj !== null) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", `supers`,`jsonString=${jsonString}`);
        }
        else{
            dbData = await Auth.hitEndpointNew("GET", `supers`);
        }
        data = [...data, ...dbData];

        data.forEach(s => {
            s.display = `${s.firstname} ${s.lastname}`
            s.value = s.id
            s.label = `${s.firstname} ${s.lastname}`
        })

        return data;
    }

    static async loadSuppliers(queryObj) {

        let data = [];
        let dbData;
        if(queryObj !== undefined && queryObj !== null) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", `suppliers`,`jsonString=${jsonString}`);
        }
        else{
            dbData = await Auth.hitEndpointNew("GET", `suppliers`);
        }
        data = [...data, ...dbData];

        data.forEach(s => {
            s.display = `${s.name}`
            s.value = s.id
            s.label = `${s.name}`
        })

        return data;
    }

    static async loadSchedule(date, queryObj, employees) {
        let dateStr = dayjs(date).format('YYYY-MM-DD')

        let data = [];
        let dbData;
        const keys = Object.keys(queryObj)
        if(keys.length > 0) {
            const jsonString = JSON.stringify(queryObj);
            if(employees === '' || employees === null || employees.length === 0) {
                dbData = await Auth.hitEndpointNew("GET", `schedule/${dateStr}`, `jsonString=${jsonString}`);
            }
            else{
                dbData = await Auth.hitEndpointNew("GET", `schedule/${dateStr}`, `jsonString=${jsonString}&employees=${employees}`);
            }
        }
        else{
            if(employees === '' || employees === null || employees.length === 0) {
                dbData = await Auth.hitEndpointNew("GET", `schedule/${dateStr}`);
            }
            else{
                dbData = await Auth.hitEndpointNew("GET", `schedule/${dateStr}`, `employees=${employees}`);
            }
        }
        data = dbData;

        return data;
    }

    static async loadScheduleReportData(position, userId, date, fields, crews, employees, reportIds) {

        let dateStr = dayjs(new Date(date)).format('YYYY-MM-DD')
        let queryObj = {};
        if (position !== undefined && position !== null && position.name === 'super') {
            queryObj['job_assignment.user_id'] = userId
        }
        if(fields !== undefined && fields.length > 0){
            queryObj['field.id'] = fields
        }
        if (crews !== undefined && crews.length > 0) {
            queryObj['schedule_crew.crew_id'] = crews
        }
        if(employees !== undefined && employees.length > 0){
            queryObj['schedule_crew_employee.employee_id'] = employees
        }
        if(reportIds !== undefined && reportIds !== null && reportIds.length > 0){
            queryObj['schedule_report.id'] = reportIds
        }

        let tasks = []
        let dbData
        const keys = Object.keys(queryObj)
        if(keys.length > 0) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", `schedule/report/${dateStr}`, `jsonString=${jsonString}`);
        }
        else{
            dbData = await Auth.hitEndpointNew("GET", `schedule/report/${dateStr}`, ``);
        }

        return dbData
    }

    static async loadScheduledTask(jobId, lotId, taskId) {
        return  await Auth.hitEndpointNew("GET", `schedule/task/${jobId}/${lotId}/${taskId}`, ``);
    }


    static async loadAvailableJobs(queryObj) {
        let data = [];
        let dbData;
        const jsonString = JSON.stringify(queryObj);
        dbData = await Auth.hitEndpointNew("GET", "schedule/jobs/available", jsonString !== undefined ? `jsonString=${jsonString}` : '');
        data = [...data, ...dbData];

        data.forEach(job => {
                job.display = `${job.number} - ${job.builder.name} - ${job.project_name}`
                job.label = `${job.number} - ${job.builder.name} - ${job.project_name}`
                job.value = job.id
        })

        data.sort((j1, j2) => {
            return Number.parseInt(j1.number) < Number.parseInt(j2.number) ? -1 : 1;
        })

        return data.filter(x => !x.remove);
    }

    static async loadLockDate(date) {
        let week = dayjs(date).week()
        let data = [];
        let dbData;
        dbData = await Auth.hitEndpointNew("GET", `schedule/lock/${week}`,  '');
        data = [...data, ...dbData];

        return data
    }

    static async createLockDates(date, locks) {
        let week = dayjs(date).week()
        let data = [];
        let dbData;
        dbData = await Auth.hitEndpointNew("POST", `schedule/locks/new/${week}`,  '', locks);
        data = [...data, ...dbData];

        return data
    }
    static async updateLockDates(locks) {
        let week =locks[0].week
        let data = [];
        let dbData;
        dbData = await Auth.hitEndpointNew("PATCH", `schedule/locks/update/${week}`,  '', locks);
        data = [...data, ...dbData];
        return data
    }

    static async checkLocked (locks, phase){
        let locked = false;
        if(phase === null || phase === undefined || phase === '') {
            locked = locks.length > 0
        }
        else{
            locked = locks.filter(x => x.phase_id === phase).length > 0
        }
        return locked
    }

    static async loadAvailableLots(jobId, phaseId, tasks, schedule) {
        let data = [];
        let dbData;

        dbData = await Auth.hitEndpointNew("GET", `schedule/lots/available/${jobId}/${phaseId}/${tasks}`, null);
        data = [...data, ...dbData];
        data.forEach(lot => {
            let existingLot = undefined
            if(schedule !== undefined) {
                existingLot = schedule.find(s => s.lot_id.toString() === lot.id.toString() && tasks.includes(s.phase_task_id.toString()) && s.remove !== 1)
            }
            if (existingLot === undefined) {
                lot.display = `${lot.number} - ${lot.phase} - ${lot.address}`
                lot.label = `${lot.number} - ${lot.phase} - ${lot.address}`
                lot.value = lot.id
            } else {
                lot.available = 'lot is on the schedule for ' + existingLot.date
            }
        })
        data.sort((l1, l2) => {
            return Number.parseInt(l1.number) < Number.parseInt(l2.number) ? -1 : 1;
        })
        return data.filter(x => !x.remove);
    }

    static async addLineToSchedule(lots, phaseId, selectedPhaseTasks, schedule, availableLots, selectedLots, selectedEmployees, selectedDate, userId, notes, selectedLotsWithDetail, hasEwa=false, isRequest = false) {

        let rows = [...schedule]
        let newAvailableLots = [...availableLots]
        let newSelectedLots = [...selectedLots]

        let scheduleDate = dayjs(new Date(selectedDate)).format('YYYY-MM-DD')
        let scheduleYear = dayjs(new Date(selectedDate)).format('YYYY')
        let ewas = []
        let ewaNumber

        for (let i = 0; i < lots.length; i++) {

            const o = lots[i]
            const lot = newAvailableLots.find(l => l.id.toString() === o.toString())

            let employees = []
            for (let e = 0; e < selectedEmployees.length; e++) {
                employees.push({
                    employee_id: selectedEmployees[e].id,
                    is_lead: e === 0,
                    approved: null,
                    approval_by: null,
                    approval_date: null,
                    deleted_at: null,
                    deleted_by: null,
                    employee: selectedEmployees[e],
                })
            }

            let detail = selectedLotsWithDetail.find(l => l.id.toString() === o.toString()) === undefined ? null : selectedLotsWithDetail.find(l => l.id.toString() === o.toString()).detail
            if (lot !== undefined) {

                let ewa = {}
                if (hasEwa) {
                    let phase = selectedPhaseTasks[0].phases.find(x => x.id.toString() === phaseId.toString())
                    let company = phase.company
                    let suffix = phase.ewa_suffix
                    ewaNumber = await EWAs.getNextNumber(company, suffix, scheduleYear, ewaNumber)
                    ewa = {
                        ewa_type_id: 1,
                        lot_id: lot.id,
                        number: ewaNumber.ewa_number,
                        line_items: [],
                        approved: false,
                        approval_by: null,
                        approval_at: null,
                        created_at: dayjs(new Date()).format('YYYY-MM-DD H:mm:ss'),
                        created_by: userId,
                        modified_at: null,
                        modified_by: null,
                        printed: false,
                        printed_at: null,
                        printed_by: null
                    }
                }
                let canRepeat = false
                if(lot.all_tasks_multi_schedule){
                    canRepeat = true
                }
                selectedPhaseTasks.forEach((pt, pIndex) => {
                    let row = {
                        crew: {
                            crew_id: selectedEmployees.length > 0 ? selectedEmployees[0].membership.crew.id : null,
                            crew: selectedEmployees.length > 0 ? selectedEmployees[0].membership.crew : null,
                            employees: employees
                        },
                        crew_id: selectedEmployees.length > 0 ? selectedEmployees[0].membership.crew.id : null,
                        schedule_date: scheduleDate,
                        lot: lot,
                        job_number: lot.job.number,
                        lot_id: lot.id,
                        lot_number: lot.number,
                        lot_phase: lot.phase,
                        task: pt,
                        phase_task_id: pt.id,
                        complete: false,
                        notes: notes,
                        phase_id: phaseId,
                        detail: pt.detail.length > 0 ? detail : [],
                        scheduled: true,
                        requested: false,
                        log: [],
                    }
                    let existing = rows.find(x => x.lot_id === lot.id && x.phase_task_id === pt.id)
                    if((existing === undefined || pt.can_repeat || lot.all_tasks_multi_schedule) && pt.type === 'work') {
                        rows.push(row)
                    }
                    if(pt.can_repeat){
                        canRepeat = true
                    }
                    if((existing === undefined || pt.can_repeat || lot.all_tasks_multi_schedule) && pt.type === 'ewa'){
                        ewa.line_items.push(row)
                    }
                })
                lot.available = canRepeat ? null : `lot is on the schedule for ${dayjs(new Date(selectedDate)).format('MM/DD/YYYY')}`
                ewas.push(ewa)
            }
            const currentIndex = newSelectedLots.indexOf(o);
            newSelectedLots.splice(currentIndex, 1);
        }

        schedule = rows

        return ({
            schedule: schedule,
            ewas: ewas,
            availableLots: newAvailableLots,
        })
    }

    static async updateRouting (schedule){
        let response = await Auth.hitEndpointNew("PATCH", `schedule/update/routing`, null, schedule);
        return response
    }
    static async updateById (schedule){
        let response = await Auth.hitEndpointNew("PATCH", `schedule`, null, schedule, schedule.id);
        return response
    }
    static async updateRequestToSchedule(schedule){
        let response = await Auth.hitEndpointNew("PATCH", `schedule/update/request-to-schedule`, null, schedule, schedule.id);
        return response
    }
    static async updateStatus (schedule){
        schedule = await this.formatLogActionDate(schedule)
        let response = await Auth.hitEndpointNew("PATCH", `schedule/update/status`, null, schedule);
        return response
    }
    static async updateDate (schedule){
        schedule = await this.formatLogActionDate(schedule)
        let response = await Auth.hitEndpointNew("PATCH", `schedule/update/date`, null, schedule);
        return response
    }
    static async updateApproval (schedule){
        schedule = await this.formatLogActionDate(schedule)
        let response = await Auth.hitEndpointNew("PATCH", `schedule/update/approval`, null, schedule);
        return response
    }
    static async updateCrew (scheduleCrews){
        let response = await Auth.hitEndpointNew("PATCH", `schedule/update/crew`, null, scheduleCrews);
        return response
    }
    static async remove (remove){
        let response = await Auth.hitEndpointNew("POST", `schedule/remove`, null, remove);
        return response
    }
    static async removeCrews (remove){
        let response = await Auth.hitEndpointNew("POST", `schedule/remove/crew`, null, remove);
        return response
    }
    static async removeEmployees (remove){
        let response = await Auth.hitEndpointNew("POST", `schedule/remove/employee`, null, remove);
        return response
    }
    static async saveLog (log) {
        for (let l = 0; l < log.length; l++) {
            log[l].action_date = dayjs(log[l].action_date).utc(true).format('YYYY-MM-DD')
        }
        let response = await Auth.hitEndpointNew("POST", `schedule/log`, '', log);
        return response
    }

    static async formatLogActionDate(schedule){
        for(let s=0; s< schedule.length; s++){
            if(schedule[s].log !== undefined) {
                if(schedule[s].log !== undefined) {
                    for (let l = 0; l < schedule[s].log.length; l++) {
                        schedule[s].log[l].action_date = dayjs(schedule[s].log[l].action_date).utc(true).format('YYYY-MM-DD')
                    }
                }
            }
        }
        return schedule
    }

    static async saveSchedule (schedule){
        // for(let s = 0; s< schedule.length; s++){
        //     schedule[s].schedule_date = dayjs(schedule[s].schedule_date).utc(true).format('YYYY-MM-DD')
        //     for(let l =0; l< schedule[s].log.length; l++){
        //         schedule[s].log[l].action_date = dayjs(schedule[s].log[l].action_date).utc(true).format('YYYY-MM-DD')
        //     }
        // }
        let response = await Auth.hitEndpointNew("POST", `schedule`, null, schedule);
        return response
    }

    static async updateDetail(detail){
        let response = await Auth.hitEndpointNew("PATCH", `schedule-details`, null, detail);
        return response
    }



    ////Adding core shared functionality here i.e. swap crews, edit details, roll forward, etc.
    static async handleDateChange (selectedRows, schedule, date, newSelectedDate, userId){
        let rows = selectedRows
        let current = [...schedule]
        let updated = []
        let curFormattedDate = dayjs(date).format('YYYY-MM-DD')
        let newFormattedDate = dayjs(newSelectedDate).format('YYYY-MM-DD')
        for (let r = 0; r < rows.length; r++) {
            rows[r].backgroundColor = "red"
            for (let c = 0; c < current.length; c++) {
                if (current[c].id === rows[r].original.id) {
                    current[c].schedule_date = newFormattedDate
                    current[c].is_dirty = true
                    current[c].remove = true

                    rows[r].original.log.push({
                        schedule_id: current[c].id,
                        action: 'moved',
                        original_value: curFormattedDate,
                        new_value: newFormattedDate,
                        action_date: dayjs(new Date()).format('YYYY-MM-DD'),
                        action_by: userId
                    })
                    current[c].log = rows[r].original.log

                    updated.push({
                        id: current[c].id,
                        schedule_date: newFormattedDate,
                        log: current[c].log
                    })
                }
            }
        }

        let response = await this.updateDate(updated)
        return current.filter(x => dayjs(x.schedule_date).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD'))
    }

    static async handleDetailSave (schedule, editDetails, selectedRows){
        let saveDetails = {"data": []}
        let current = [...schedule]

        for(let e=0; e< editDetails.length; e++){
            for(let d=0; d<editDetails[e].details.length; d++) {
                saveDetails.data.push(editDetails[e].details[d])
            }
        }

        let response = await this.updateDetail(saveDetails)

        for(let r=0; r<selectedRows.length; r++){
            selectedRows[r].original.detail = response.filter(x => x.schedule_id === selectedRows[r].original.id)
        }

        return current
    }

    static async handleNotesSave (value, row) {
        row.original['notes'] = value === null || value === "" ? "" : value.toString()
        let response = await this.updateById({
            id: row.original.id,
            notes: row.original.notes
        })
        return response;
    }

    static async handlePrepAssignmentChange (phases, tableRows, setEmployees, setCrews, setSelectedRows, setSelectedTableRows, setEmpsToRemove,){
        let rows = []
        let remove = []
        for(let r=0; r<tableRows.length; r++) {
            let row = tableRows[r]
            let tempPhase = await Phases.findPhaseById(phases, row.original.phase_id)
            if(tempPhase !== undefined) {
                let queryObj = tempPhase !== null ? {[tempPhase.company.toLowerCase()]: 1} : null
                setEmployees(await Employees.loadEmployeesForDropDown(queryObj))
                queryObj = tempPhase !== null ? {company: tempPhase.company} : null
                setCrews(await Crews.loadCrewsForDropdown(queryObj))
            }

            let existing = rows.find(x => x.id === row.original.id)
            if(existing === undefined) {
                rows.push(row.original)
            }
        }
        setSelectedRows(tableRows)
        setSelectedTableRows(rows)

        let emps = []
        for(let r=0;r<rows.length; r++){
            if(rows[r].crew !== null && rows[r].crew.employees.length > 0){
                for (let e = 0; e < rows[r].crew.employees.length; e++) {
                    if(rows[r].crew.employees[e].employee === null){
                        remove.push(rows[r].crew.employees[e].id)
                    }
                    else {
                        let exists = emps.find(x => x.id === rows[r].crew.employees[e].employee.id)
                        if (exists === undefined) {
                            emps.push(rows[r].crew.employees[e].employee)
                        }
                    }
                }
            }
        }
        setEmpsToRemove(remove)
        return emps
    }

    static async handleAssignmentSave(selectedRows, schedule, employees, selectedEmployeesReassign, empsToRemove, userId){
        let rows = selectedRows
        let current = [...schedule]

        let scheduleCrews = []
        let scheduleEmployees = []
        let lead, crewId
        if(selectedEmployeesReassign.length > 0) {
            lead = employees.find(x => x.id === selectedEmployeesReassign[0].id)
            crewId = lead.membership.crew.id
        }

        let statusDate = dayjs(new Date()).format('YYYY-MM-DD')
        let remove = []
        let log = []
        let removeCrews = []
        for (let r = 0; r < rows.length; r++) {
            let currentRow = current.find(x => x.id === rows[r].original.id)
            if (selectedEmployeesReassign.length === 0) {
                if(currentRow.crew !== null) {
                    removeCrews.push(currentRow.crew.id)
                    let logEntry = {
                        schedule_id: currentRow.id,
                        action: 'remove scheduled crew',
                        original_value: currentRow.crew.id,
                        new_value: '',
                        action_date: statusDate,
                        action_by: userId
                    }
                    log.push(logEntry)
                }
                currentRow.crew = null;
            } else {
                let scheduleCrew = {
                    id: currentRow.crew !== null ? currentRow.crew.id : null,
                    crew_id: crewId,
                    schedule_id: currentRow.id
                }
                if (currentRow.crew === null) {
                    currentRow.crew = {}
                }
                currentRow.crew.crew_id = crewId
                currentRow.crew.crew = lead.membership.crew

                //Removing
                for (let d = 0; d < empsToRemove.length; d++) {
                    let emp = currentRow.crew.employees.find(x => x.employee_id === empsToRemove[d].id)
                    if (emp !== undefined) {
                        remove.push(emp.id)
                        let logEntry = {
                            schedule_id: currentRow.id,
                            action: 'remove scheduled employee',
                            original_value: emp.employee_id,
                            new_value: '',
                            action_date: statusDate,
                            action_by: userId
                        }
                        let index = currentRow.crew.employees.indexOf(emp.id)
                        currentRow.crew.employees.splice(index, 1)
                        currentRow.log.push(logEntry)
                        log.push(logEntry)
                    }
                }

                currentRow.crew.employees = []
                //Making sure the existing members stay on and Adding New
                for (let ea = 0; ea < selectedEmployeesReassign.length; ea++) {
                    currentRow.crew.employees.push({
                        schedule_crew_id: currentRow.crew.id,
                        employee_id: selectedEmployeesReassign[ea].id,
                        is_lead: ea === 0,
                        employee: selectedEmployeesReassign[ea],
                    })
                    scheduleEmployees.push({
                        schedule_crew_id: currentRow.crew.id,
                        employee_id: selectedEmployeesReassign[ea].id,
                        is_lead: ea === 0,
                        employee: selectedEmployeesReassign[ea],
                    })
                    let logEntry = {
                        schedule_id: currentRow.id,
                        action: 'added scheduled employee',
                        original_value: '',
                        new_value: selectedEmployeesReassign[ea].id,
                        action_date: statusDate,
                        action_by: userId
                    }
                    currentRow.log.push(logEntry)
                    log.push(logEntry)
                }
                scheduleCrew.employees = scheduleEmployees
                scheduleCrews.push(scheduleCrew)
            }
        }

        if(removeCrews.length > 0){await this.removeCrews(removeCrews)}
        if(remove.length > 0){await this.removeEmployees(remove)}
        if(scheduleCrews.length > 0){await this.updateCrew(scheduleCrews)}
        if(log.length > 0) {await this.saveLog(log)}

        return current
    }

    static getEmployees(row){
        let scheduledEmployees = []
        if(row.crew !== null){
            for(let e=0; e<row.crew.employees.length; e++){
                let emp = row.crew.employees[e]
                emp.parent_crew_id = row.crew.crew_id
                emp.parent_crew = row.crew.crew.number
                emp.complete = row.complete
                scheduledEmployees.push(emp)
            }
        }
        return scheduledEmployees
    }

    static async handleRemove(table, schedule, phase, tasks, selectedJob, setAvailableLots, userId){
        let submitRows = []
        let current = [...schedule]
        let updated = [...schedule]
        let rows = table.getSelectedRowModel().rows
        for (let r = 0; r < rows.length; r++) {
            rows[r].original.remove = true
            if(rows[r].original.id !== undefined && rows[r].original.id !== null &&  rows[r].original.id !== ''){
                submitRows.push({
                    id: rows[r].original.id,
                    deleted_at: dayjs(new Date()).format('YYYY-MM-DD'),
                    deleted_by: userId
                })
            }
        }
        let response = await this.remove(submitRows)
        let phaseTaskIds = await PhaseTasks.getSelectedPhaseTaskIds(tasks)
        if(phaseTaskIds.length > 0) {
            setAvailableLots(await this.loadAvailableLots(selectedJob.id, phase, phaseTaskIds, schedule))
        }
        updated = current.filter(x => x.remove !== true)
        table.resetRowSelection()
        return updated
    }
    static async handleEditDetails(rows, schedule, setSelectedTasks, setEditDetails){
        let lots = []
        let taskIds = []
        let lotDetails = []

        for(let r=0; r< rows.length; r++){
            let row = schedule.find(x => x.id === rows[r].original.id)
            if(row !== undefined){
                lots.push(row)
            }
            taskIds.push(rows[r].original.phase_task_id)
        }
        let tasks = await PhaseTasks.loadPhaseTasks({'phase_task.id': taskIds})
        setSelectedTasks(tasks)

        //set all options so warnings go away
        for(let t=0; t<tasks.length; t++) {
            for (let l = 0; l < lots.length; l++) {
                lotDetails.push({
                    lot_id: lots[l].lot_id,
                    lot_number: lots[l].lot_number,
                    lot_phase: lots[l].lot_phase,
                    details: [],
                })
                for (let d = 0; d < tasks[t].detail.length; d++) {
                    let current = lots[l].detail.find(x => x.phase_task_detail_id === tasks[t].detail[d].id)
                    lotDetails[l][tasks[t].detail[d].type.key] = current === undefined || current.schedule_value === null ? "" : current.schedule_value
                    lotDetails[l].details.push({
                        id: current === undefined ? null : current.id,
                        schedule_id: current === undefined ? lots[l].id : current.schedule_id,
                        phase_task_detail_id: current === undefined ? tasks[t].detail[d].id : current.phase_task_detail_id,
                        schedule_value: current === undefined ? "" : current.schedule_value,
                    })
                }
            }
        }
        setEditDetails(lotDetails)
        return lotDetails
    }
    static async handleMoveToSchedule(date, schedule, rows, userId){
        let updatedSchedule = [...schedule]
        for(let r=0; r< rows.length; r++){
            let row = updatedSchedule.find(x => parseInt(x.id) === parseInt(rows[r].original.id))
            if(row !== undefined){
                row.requested = false;
                row.scheduled = true
                row.scheduled_by = userId
            }
        }
        await this.saveSchedule(updatedSchedule)
        return await this.loadSchedule(date, {}, '')
    }
    static async handleMoveToRequest(date, schedule, rows, userId){
        let updatedSchedule = [...schedule]
        for(let r=0; r< rows.length; r++){
            let row = updatedSchedule.find(x => parseInt(x.id) === parseInt(rows[r].original.id))
            if(row !== undefined){
                if(row.notMovable !== undefined){
                    delete row.notMovable;
                }
                row.requested = true;
                row.scheduled = false
                row.scheduled_by = userId
            }
        }
        await this.saveSchedule(updatedSchedule)
        return await this.loadSchedule(date, {}, '')
    }
    static async handleComplete(isComplete, schedule, rows, userId){
        let updated = [...schedule]

        let updates = []
        for(let r=0; r< rows.length; r++){
            let row = updated.find(x => x.id === rows[r].original.id)
            if(row !== undefined && !row.is_ewa){
                rows[r].original.complete = isComplete
                row.complete = isComplete
                row.completed_date = dayjs(new Date()).format('YYYY-MM-DD')
                row.completed_by = userId

                if(updates.filter(x => x.id === rows[r].original.id).length === 0) {
                    updates.push({
                        id: row.id,
                        complete: row.complete,
                        completed_by: row.complete_by,
                        completed_date: row.complete_at
                    })
                }
            }
        }
        await this.updateStatus(updates)
        return updates
    }


    ////ROUTING - SEQ
    static async getDistinctCrews (schedule, phase){
        let scheduledCrews = []
        let data = []
        if(phase === null || phase === ''){
            data = schedule
        }
        else {
            data = schedule.filter(x => x.phase_id === phase.value)
        }

        let crews = []
        for(let s=0; s<data.length; s++){
            let existing = crews.find(x => x.id === data[s].crew.crew_id)
            if(existing === undefined){
                let lead = ''
                let leadObj = data[s].crew.employees[0]
                if(leadObj === undefined){
                    lead = '00000 Not Assigned'
                }
                else{
                    lead = `${data[s].crew.crew.number} ${leadObj.employee.alias !== null ? leadObj.employee.alias : ''} ${leadObj.employee.first_name} ${leadObj.employee.last_name}`
                }
                crews.push({
                    id: data[s].crew.crew_id,
                    value: data[s].crew.crew_id,
                    label: lead
                })
            }
        }

        return crews
    }
    static async handleRoutingSave (schedule, routeSchedule){
        let saveRouting = {"data": []}
        let current = [...schedule]

        for(let d=0; d< routeSchedule.length; d++) {
            let row = current.find(x => x.id === routeSchedule[d].id)
            row.routing_seq = routeSchedule[d].routing_seq
            saveRouting.data.push({
                id: routeSchedule[d].id,
                routing_seq: routeSchedule[d].routing_seq
            })
        }

        let response = await this.updateRouting(saveRouting)
        return current
    }
}

export default Schedules;
