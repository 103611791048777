import {useState} from 'react';
import auth from '../authentication/Auth.js';

import Lots from '../../classes/Lots.js';
import NotesIcon from '../../img/NotesIcon.png';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import LotNotes from '../lots/LotNotes.js';
import Globals from '../utilities/Globals.js';
import Popup, {PopupSuccessColor} from '../utilities/Popup.js';
import TopOutTrimReleaseContainer from './styles/TopOutTrimReleaseContainer.js';

import dayjs from 'dayjs';

const TopOutTrimRelease = (props) => {

    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);

    const [lots, setLots] = useState([]);

    const [selectedLot, setSelectedLot] = useState(null);
    const [topoutTrimReleaseInformation, setTopoutTrimReleaseInformation] = useState(null);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [didLoadData, setDidLoadData] = useState(false);

    const [successTopout, setSuccessTopout] = useState(false);
    const [successTrim, setSuccessTrim] = useState(false);

    let noteType = '';

    // Get date 30 days ago for trim release
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const loadData = async () => {
        setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));

        setDidLoadData(true);
    }

    if(!didLoadData){
        loadData();
    }

    const loadLots = async (jobId) => {
        setLots(Lots.sortLots("jobNumber", "ascending", await Lots.loadLots({job_id: jobId}, jobId), await Jobs.loadJobs(null)));
    }

    const selectJob = (e) => {
        loadLots(e.target.value);
    }

    const getTopoutTrimInformation = async (lot) => {
        if(lot !== undefined) {
            const topoutPhase = lot.phases.find(p => parseInt(p.phase_id) === 4);
            const trimPhase = lot.phases.find(p => parseInt(p.phase_id) === 5);
            setTopoutTrimReleaseInformation({
                topoutReleaseDate: topoutPhase.release_date,
                topoutStartsStatus: topoutPhase.starts_status,
                trimReleaseDate: trimPhase.release_date,
                trimStartsStatus: trimPhase.starts_status
            });
        }
    }

    const selectLot = (e) => {
        // Get selected lot
        const tempSelectedLot = lots.find(lot => lot.id.toString() === e.target.value.toString());
        console.log(tempSelectedLot);
        setSelectedLot(tempSelectedLot);

        // Fill in top out and trim information
        getTopoutTrimInformation(tempSelectedLot);
    }

    const releasePhase = async (phase_id) => {
        const topoutPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 4);
        const trimPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 5);
        
        if(phase_id === 4){
            topoutPhase.release_date = dayjs(new Date()).format('YYYY-MM-DD');
            if(topoutPhase.starts_status === '' || topoutPhase.starts_status === null){
                topoutPhase.starts_status = 'Received';
            }
        }
        if(phase_id === 5){
            trimPhase.release_date = dayjs(new Date()).format('YYYY-MM-DD');
            if(trimPhase.starts_status === '' || trimPhase.starts_status === null){
                trimPhase.starts_status = 'Received';
            }
        }

        await Lots.updateLot(selectedLot, auth.getUserId());

        setTopoutTrimReleaseInformation({
            topoutReleaseDate: topoutPhase.release_date,
            topoutStartsStatus: topoutPhase.starts_status,
            trimReleaseDate: trimPhase.release_date,
            trimStartsStatus: trimPhase.starts_status
        });
    }

    const handleReleaseTopOut = () => {
        // Update phase to release top out
        releasePhase(4);
        setSuccessTopout(true);
    }

    const handleReleaseTrim = () => {
        // Update phase to release top out
        releasePhase(5);
        setSuccessTrim(true);
    }

    const togglePopup = () => {
        setSuccessTopout(false);
        setSuccessTrim(false);
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = () => {
        setDisplayNotes(false);
    }

    const canEditTopOutTrim = props.isAuthorized('topOutAndTrimRelease', 'WRITE');

    // Set Trim grayed out button text if needed
    let trimButtonText = 'Error';
    if(topoutTrimReleaseInformation){
        if(topoutTrimReleaseInformation.topoutReleaseDate === null){trimButtonText = 'Top Out Not Released'}
        else if(topoutTrimReleaseInformation.trimReleaseDate !== null){trimButtonText = 'Released'}
        else if(thirtyDaysAgo < new Date(topoutTrimReleaseInformation.topoutReleaseDate)){
            const thirtyDaysFromTopOutRelease = new Date(topoutTrimReleaseInformation.topoutReleaseDate);
            thirtyDaysFromTopOutRelease.setDate(thirtyDaysFromTopOutRelease.getDate() + 30);
            const daysUntilOpen = Number.parseInt((thirtyDaysFromTopOutRelease.getTime() - today.getTime()) / 1000 / 60 / 60 / 24);
            trimButtonText = `${daysUntilOpen} Days Until Available`
        }
    }

    return (
        <TopOutTrimReleaseContainer>
            <div className='topout-trim-release-form-header'>Top Out & Trim Release</div>
            <div className='topout-trim-release-job-sub-header'>Select Job & Lot</div>
            <div className='topout-trim-release-job-drop-down-box'>
                <label className="topout-trim-release-job-drop-down-label" htmlFor='topout-trim-release-job-form-select'>Job</label>
                <select className="topout-trim-release-job-drop-down-select" name='topout-trim-release-job-form-select' onChange={selectJob}>
                    <option className="topout-trim-release-job-drop-down-option" value=''></option>
                    {jobsForDropDowns.map(job => {
                        return (
                            <option key={job.id} className="topout-trim-release-job-drop-down-option" value={job.id}>{job.display}</option>
                        )
                    })}

                </select>
            </div>
            <div className='topout-trim-release-lot-drop-down-box'>
                <label className="topout-trim-release-lot-drop-down-label" htmlFor='topout-trim-release-lot-form-select'>Lot</label>
                <select className="topout-trim-release-lot-drop-down-select" name='topout-trim-release-lot-form-select' onChange={selectLot}>
                    <option className="topout-trim-release-lot-drop-down-option" value=''></option>
                    {lots.map(lot => {
                        const value = lot.id;
                        const display = `${lot.number} - ${lot.phase} - ${lot.address}`;
                        return (
                            <option key={value} className="topout-trim-release-lot-drop-down-option" value={value}>{display}</option>
                        )
                    })}
                </select>
            </div>
            {selectedLot && topoutTrimReleaseInformation &&
            <div className='topout-trim-release-box'>
                <div className='topout-trim-release-sub-header'>Top Out</div>
                <div className='topout-trim-release-date-box'>
                    <div className='topout-trim-release-date-label'>Release Date:</div>
                    {topoutTrimReleaseInformation.topoutReleaseDate !== null && <div className='topout-trim-release-date-value'>{Globals.formatDateToDisplay(topoutTrimReleaseInformation.topoutReleaseDate)}</div>}
                    {topoutTrimReleaseInformation.topoutReleaseDate === null && <div className='topout-trim-release-date-value'>Not Released</div>}
                </div>
                <div className='topout-trim-release-status-box'>
                    <div className='topout-trim-release-status-label'>Starts Status:</div>
                    {topoutTrimReleaseInformation.topoutStartsStatus !== '' && <div className='topout-trim-release-status-value'>{topoutTrimReleaseInformation.topoutStartsStatus}</div>}
                    {topoutTrimReleaseInformation.topoutStartsStatus === '' && <div className='topout-trim-release-status-value'>N/A</div>}
                </div>
                <div className='topout-trim-lot-notes-box'>
                    <div className='topout-trim-lot-notes-label'>Lot Notes:</div>
                    <div className='topout-trim-lot-notes-value' onClick={(e) => {handleDisplayNotes(); noteType = 'TopOut';}}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                </div>
                {topoutTrimReleaseInformation.topoutReleaseDate === null && canEditTopOutTrim &&
                <div className='submit'>
                    <div className='button' onClick={handleReleaseTopOut}>Release Top Out</div>
                </div>}
                {topoutTrimReleaseInformation.topoutReleaseDate !== null && canEditTopOutTrim &&
                <div className='submit'>
                    <div className='button grayed'>Top Out Released</div>
                </div>}
                <div className='topout-trim-release-sub-header'>Trim</div>
                <div className='topout-trim-release-date-box'>
                    <div className='topout-trim-release-date-label'>Release Date:</div>
                    {topoutTrimReleaseInformation.trimReleaseDate !== null && <div className='topout-trim-release-date-value'>{Globals.formatDateToDisplay(topoutTrimReleaseInformation.trimReleaseDate)}</div>}
                    {topoutTrimReleaseInformation.trimReleaseDate === null && <div className='topout-trim-release-date-value'>Not Released</div>}
                </div>
                <div className='topout-trim-release-status-box'>
                    <div className='topout-trim-release-status-label'>Starts Status:</div>
                    {topoutTrimReleaseInformation.trimStartsStatus !== "" && <div className='topout-trim-release-status-value'>{topoutTrimReleaseInformation.trimStartsStatus}</div>}
                    {topoutTrimReleaseInformation.trimStartsStatus === "" && <div className='topout-trim-release-status-value'>N/A</div>}
                </div>
                <div className='topout-trim-lot-notes-box'>
                    <div className='topout-trim-lot-notes-label'>Lot Notes:</div>
                    <div className='topout-trim-lot-notes-value' onClick={(e) => {handleDisplayNotes(); noteType = 'Trim';}}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                </div>
                {!canEditTopOutTrim ? null :
                topoutTrimReleaseInformation.topoutReleaseDate !== null && 
                (thirtyDaysAgo > new Date(topoutTrimReleaseInformation.topoutReleaseDate)) && 
                topoutTrimReleaseInformation.trimReleaseDate === null ? 
                <div className='submit'>
                    <div className='button' onClick={handleReleaseTrim}>Release Trim</div>
                </div> : 
                <div className='submit'>
                    <div className='button grayed'>
                        {trimButtonText}
                    </div>
                </div>}
            </div>}
            {displayNotes ? <LotNotes isAuthorized={props.isAuthorized} closeNotes={closeNotes} lot={selectedLot} type={noteType} width='400'/> : null}
            {successTopout && <Popup color={PopupSuccessColor} message={`Top out has been released`} handlePopup={togglePopup}/>}
            {successTrim && <Popup color={PopupSuccessColor} message={`Trim has been released`} handlePopup={togglePopup}/>}
        </TopOutTrimReleaseContainer>
    )
}

export default TopOutTrimRelease;