import styled from 'styled-components';

const PourLogContainer = styled.div`
    display: grid;
    margin: 10px 0 0 10px;
`;

export const InfoBox = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    margin: 10px auto;

    .info-label{
        grid-column: 1/6;
        display: grid;
        align-content: center;
        text-align: end;
        padding: 5px;
    }

    .info-value{
        grid-column: 6/10;
        display: grid;
        align-content: center;
    }
`;

export default PourLogContainer;