import { useMemo, useState} from 'react';
import dayjs from "dayjs";

import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader.js';
import PourLogContainer, { InfoBox } from './styles/PourLogContainer.js';

import {MaterialReactTable} from "material-react-table";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button  from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import { Switch } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const PourLog = (props) => {
    const [cursor, setCursor] = useState('default');
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        builder_invoiced: false,
        vender_invoiced: false
    })

    const [pourLogs, setPourLogs] = useState([]);
    const [editPourLog, setEditPourLog] = useState(null);

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const canEditInvoice = props.isAuthorized('pourLog', 'WRITE');

    const columns = useMemo(
        () => [
            {
                accessorKey: 'job_number',
                header: 'Job #',
            },
            {
                accessorKey: 'lot_number',
                header: 'Lot #',
            },
            {
                accessorKey: 'phase_task',
                header: 'Phase Task',
            },
            {
                accessorKey: 'builder',
                header: 'Builder',
            },
            {
                accessorKey: 'project_name',
                header: 'Project',
            },
            {
                accessorKey: 'yards',
                header: 'Concrete Yards',
            },
            {
                accessorKey: 'supplier',
                header: 'Concrete Supplier',
            },
            {
                accessorKey: 'pump',
                header: 'Pump',
            },
            {
                accessorKey: 'time',
                header: 'Time',
            },
            {
                accessorKey: 'order',
                header: 'Order #',
            },
            {
                accessorKey: 'completed_date',
                header: 'Completed',
            },
            {
                accessorKey: 'builder_invoice_number',
                header: 'Builder Invoice #',
            },
            {
                accessorFn: (row) => row.builder_billed ? 'X' : '', 
                header: 'Builder Billed',
            },
            {
                accessorKey: 'vender_invoice_number',
                header: 'Vender Invoice #',
            },
            {
                accessorKey: 'vender_invoice_value',
                header: 'Vender Invoice Yards',
            },
            {
                accessorFn: (row) => row.vender_invoice_complete ? 'X' : '',
                header: 'Vender Invoice Complete',
            },
            {
                accessorKey: 'vender_invoice_date',
                header: 'Vender Invoice Date',
            },
        ],
        [],
    );

    const loadData = async () => {
        setDidLoadData(true);

        let queryStr = 'jsonString=phase_task_detail.detail_type_id = 1 and schedule.complete = 1';
        if(!filters.builder_invoiced){
            queryStr += ' and schedule_detail.builder_invoice_number is null';
        }
        if(!filters.vender_invoiced){
            queryStr += ' and schedule_detail.vender_invoice_number is null';
        }
        const data = await Auth.hitEndpointNew("GET", `schedule/detail/reconcile`, queryStr);

        const tempPourLogs = [];
        for(let i = 0; i < data.length; i++){
            const yards = data[i].detail.find(d => parseInt(d.phase_task_detail_id) === 1);
            const supplier = data[i].detail.find(d => parseInt(d.phase_task_detail_id) === 2);
            const pump = data[i].detail.find(d => parseInt(d.phase_task_detail_id) === 3);
            const time = data[i].detail.find(d => parseInt(d.phase_task_detail_id) === 4);
            const order = data[i].detail.find(d => parseInt(d.phase_task_detail_id) === 5);

            tempPourLogs.push({
                        builder: data[i].lot.job.builder.name,
                        job_number: data[i].lot.job.number,
                        project_name: data[i].lot.job.project_name,
                        lot_number: data[i].lot.number,
                        phase_task: `${data[i].task.code} - ${data[i].task.description}`,
                        completed_date: data[i].completed_date !== null ? dayjs(new Date(data[i].completed_date)).format('MM-DD-YYYY') : null,
                        id: data[i].id,
                        yards: yards && yards.schedule_value ? yards.schedule_value : null,
                        supplier: supplier && supplier.supplier && supplier.supplier.name ? supplier.supplier.name  : null,
                        pump: pump && pump.supplier && pump.supplier.name ? pump.supplier.name : null,
                        time: time && time.schedule_value ? time.schedule_value : null,
                        order: order && order.schedule_value ? order.schedule_value : null,
                        builder_invoice_number: yards ? yards.builder_invoice_number : null,
                        builder_billed: yards && yards.builder_billed ? true : false,
                        vender_invoice_number: yards ? yards.vender_invoice_number : null,
                        vender_invoice_value: yards ? yards.vender_invoice_value : null,
                        vender_invoice_complete: yards && yards.vender_invoice_complete ? true : false,
                        vender_invoice_date: yards && yards.vender_invoice_date ? dayjs(new Date(yards.vender_invoice_date)).format('MM-DD-YYYY') : null,
                        vender_invoice_by: yards ? yards.vender_invoice_by : null,
                        details: data[i].detail
            })
        }
        setPourLogs([...tempPourLogs]);
    }

    if(!didLoadData){
        loadData();
    }

    const handleCloseNoSave = () => {
        setError({
            type: '',
            message: ''
        });
        setEditPourLog(null);
    }

    const handleCloseSave = async (pourLog) => {
        let venderInvoiceBy = '';
        if(pourLog.dialog === 'vender'){
            venderInvoiceBy = await Auth.getUserId();

            if(pourLog.vender_invoice_number === null || pourLog.vender_invoice_number === ''){
                setError({type: 'NO_INVOICE_NUMBER', message: 'Please enter a invoice number'});
                return;
            }
            if(pourLog.vender_invoice_value === null || pourLog.vender_invoice_value === ''){
                setError({type: 'NO_INVOICE_YARDS', message: 'Please enter a invoice yards'});
                return;
            }
            if(pourLog.vender_invoice_date === null){
                setError({type: 'NO_INVOICE_DATE', message: 'Please enter a invoice date'});
                return;
            }
            if(pourLog.vender_invoice_complete === null || !pourLog.vender_invoice_complete){
                setError({type: 'INVOICE_NOT_COMPLETED', message: 'Please mark invoice as complete'});
                return;
            }
        }
        if(pourLog.dialog === 'builder'){
            if(pourLog.builder_invoice_number === null || pourLog.builder_invoice_number === ''){
                setError({type: 'NO_INVOICE_NUMBER', message: 'Please enter a invoice number'});
                return;
            }
            if(pourLog.builder_billed === null || !pourLog.builder_billed){
                setError({type: 'INVOICE_NOT_COMPLETED', message: 'Please mark invoice as complete'});
                return;
            }
        }
        const dbObj = {
            id: pourLog.id,
            detail: pourLog.details.map(d => {
                if(d.phase_task_detail_id === 1){
                    return {
                        id: d.id,
                        builder_billed: pourLog.builder_billed ? true : false,
                        builder_invoice_number: pourLog.builder_invoice_number,
                        vender_invoice_by: venderInvoiceBy === '' ? pourLog.vender_invoice_by : venderInvoiceBy,
                        vender_invoice_complete: pourLog.vender_invoice_complete ? true : false,
                        vender_invoice_date: pourLog.vender_invoice_date !== null ? dayjs(new Date(pourLog.vender_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                        vender_invoice_number: pourLog.vender_invoice_number,
                        vender_invoice_value: pourLog.vender_invoice_value
                    }
                }

                return {id: d.id}
            })
        }
        await Auth.hitEndpointNew("PATCH", "schedule", "", dbObj);

        const tempPourLogs = [...pourLogs];
        const index = tempPourLogs.findIndex(pl => parseInt(pl.id) === parseInt(pourLog.id));
        if(index !== -1){
            pourLog.vender_invoice_date = dayjs(new Date(pourLog.vender_invoice_date)).format('MM-DD-YYYY')
            tempPourLogs[index] = pourLog;
            setPourLogs([...tempPourLogs]);
        }

        setError({
            type: '',
            message: ''
        });
        setEditPourLog(null);
    }

    return (
        <PourLogContainer>
            <MainHeader/>
            <FormGroup sx={{ 
                display: 'grid',
                width: '300px',
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '3px',
                marginBottom: '10px'
            }}>
                <div style={{
                    fontSize: '20px', 
                    textAlign: 'center', 
                    padding: '8px 0'}}
                >Filters</div>
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.builder_invoiced} 
                        onChange={(e) => setFilters({...filters, builder_invoiced: e.target.checked})}/>} 
                    label="Builder Invoiced" 
                />
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.vender_invoiced} 
                        onChange={(e) => setFilters({...filters, vender_invoiced: e.target.checked})}/>} 
                    label="Vender Invoiced" 
                />
                <Button 
                    sx={{
                        justifySelf: 'center',
                        width: '200px',
                        margin: '10px auto 15px'
                    }}
                    variant='contained'
                    onClick={(e) => setDidLoadData(false)}
                >Update Table</Button>
            </FormGroup>
            <MaterialReactTable
                id="pour-log-table"
                muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                columns={columns}
                data={pourLogs}
                enableSorting
                layoutMode="grid-no-grow"
                enableColumnResizing
                enableStickyHeader
                muiTableBodyCellProps={({ cell }) => ({
                    sx: {
                        cursor: canEditInvoice ? cursor : 'default',
                    },
                    onMouseOver: () => {
                        if(cell.column.id === 'builder_invoice_number' || cell.column.id === 'builder_billed' ||
                            cell.column.id === 'vender_invoice_number' || cell.column.id === 'vender_invoice_value' ||
                            cell.column.id === 'vender_invoice_complete' || cell.column.id === 'vender_invoice_date'
                        ){
                            setCursor('pointer');
                        }
                        else{
                            setCursor('default');
                        }
                    },
                    onClick: () => {
                        if(!canEditInvoice){
                            return;
                        }

                        const pourLog = pourLogs.find(pl => parseInt(pl.id) === parseInt(cell.row.original.id));
                        if(pourLog !== undefined){
                            if(cell.column.id === 'builder_invoice_number' || cell.column.id === 'builder_billed'){
                                pourLog.dialog = 'builder';
                                setEditPourLog(pourLog);
                            }
                            if(cell.column.id === 'vender_invoice_number' || cell.column.id === 'vender_invoice_value' ||
                                cell.column.id === 'vender_invoice_complete' || cell.column.id === 'vender_invoice_date'
                            ){
                                pourLog.dialog = 'vender';
                                setEditPourLog(pourLog);
                            }
                        }
                    }
                })}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {left: ['job_number', 'lot_number', 'phase_task']}
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <div style={{
                            display: 'flex',
                            width: "100%",
                            justifyContent: "center",
                            fontSize: "30px",
                            fontWeight: "bold",
                            cursor: "default"
                            }}
                        >
                            Pour Log
                        </div>
                    );
                }}
            />
            {editPourLog !== null && 
            <EditInvoiceDialog 
                pourLog={editPourLog} 
                save={handleCloseSave} 
                noSave={handleCloseNoSave}
                error={error}
            />}
            <Dialog open={error.type !== ''}>
                <DialogTitle style={{borderBottom: "2px solid black"}}>{`ERROR: ${error.type}`}</DialogTitle>
                <DialogContent style={{textAlign: 'center', marginTop: '10px'}}>
                    {error.message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setError({
                        type: '',
                        message: ''
                    })}>OK</Button>
                </DialogActions>
            </Dialog>
        </PourLogContainer>
    );
}

const EditInvoiceDialog = (props) => {
    const [pourLog, setPourLog] = useState(props.pourLog)

    return (
        <Dialog open={pourLog !== null} onClose={props.noSave}>
            {pourLog !== null && pourLog.dialog === 'builder' ? 
            <DialogTitle style={{borderBottom: "2px solid black"}}>Pour Log Builder Invoice</DialogTitle> : null}
            {pourLog !== null && pourLog.dialog === 'vender' ?
            <DialogTitle style={{borderBottom: "2px solid black"}}>Pour Log Vender Invoice</DialogTitle> : null}
            {pourLog &&
            <DialogContent>
                <InfoBox>
                    <div className='info-label'>Concrete Yards:</div>
                    <div className='info-value'>{pourLog.yards}</div>
                </InfoBox>
                <InfoBox>
                    <div className='info-label'>Concrete Supplier:</div>
                    <div className='info-value'>{pourLog.supplier}</div>
                </InfoBox>
                <InfoBox>
                    <div className='info-label'>Pump:</div>
                    <div className='info-value'>{pourLog.pump}</div>
                </InfoBox>
                <InfoBox>
                    <div className='info-label'>Time:</div>
                    <div className='info-value'>{pourLog.time}</div>
                </InfoBox>
                <InfoBox>
                    <div className='info-label'>Order #:</div>
                    <div className='info-value'>{pourLog.order}</div>
                </InfoBox>
                {pourLog.dialog === 'builder' &&
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                            backgroundColor: props.error.type === "NO_INVOICE_NUMBER" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        placeholder="Builder Invoice #"
                        value={pourLog.builder_invoice_number !== null ? pourLog.builder_invoice_number : ""}
                        onChange={(e) => setPourLog({...pourLog, builder_invoice_number: e.target.value})}
                    />
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center',
                            backgroundColor: props.error.type === "INVOICE_NOT_COMPLETED" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        control={<Checkbox 
                            defaultChecked={pourLog.builder_billed === 'X'} 
                            onChange={(e) => setPourLog({...pourLog, builder_billed: e.target.checked})}/>} 
                        label="Builder Billed" 
                    />
                </DialogContent>}
                {pourLog.dialog === 'vender' &&
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                            backgroundColor: props.error.type === "NO_INVOICE_NUMBER" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        placeholder="Vender Invoice #"
                        value={pourLog.vender_invoice_number !== null ? pourLog.vender_invoice_number : ""}
                        onChange={(e) => setPourLog({...pourLog, vender_invoice_number: e.target.value})}
                    />
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                            backgroundColor: props.error.type === "NO_INVOICE_YARDS" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        placeholder="Vender Invoice Yards"
                        value={pourLog.vender_invoice_value !== null ? pourLog.vender_invoice_value : ""}
                        onChange={(e) => setPourLog({...pourLog, vender_invoice_value: e.target.value})}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                width: '100%',
                                margin: '5px auto',
                                backgroundColor: props.error.type === "NO_INVOICE_DATE" ? 'rgb(230, 100, 100)' : 'white',
                            }}
                            selected={pourLog.vender_invoice_date !== null ? dayjs(pourLog.vender_invoice_date) : null}
                            onChange={(date) => setPourLog({...pourLog, vender_invoice_date: date})}
                        />
                    </LocalizationProvider>
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center',
                            backgroundColor: props.error.type === "INVOICE_NOT_COMPLETED" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        control={<Checkbox 
                            defaultChecked={pourLog.vender_invoice_complete === 'X'} 
                            onChange={(e) => setPourLog({...pourLog, vender_invoice_complete: e.target.checked})}/>} 
                        label="Vender Invoice Complete" 
                    />
                </DialogContent>}
            </DialogContent>}
            <DialogActions>
                <Button onClick={props.noSave}>Cancel</Button>
                <Button onClick={() => props.save(pourLog)}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PourLog;
