import styled from 'styled-components';

const BlueStakeContainer = styled.div`
    display: grid;
    width: 754px;
    // border-right: 5px solid rgb(58, 64, 67);
    // border-bottom: 5px solid rgb(58, 64, 67);
    // border-left: 5px solid rgb(58, 64, 67);

    .blue-stake-header {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(20, 1fr);
        background: rgb(58, 64, 67);

        .close-blue-stake-popup{
            grid-column: 1/5;
        }

        .blue-stake-header-title{
            font-size: 18px;
            grid-column: 8/14;
            padding: 3px 0;
            text-align: center;
            background: rgb(58, 64, 67);
            color: white;
        }
    }

    .blue-stake-grid-table {
        display: grid;
        border: 2px solid black;

        .blue-stake-grid-table-row, 
        .blue-stake-grid-table-header {
            display: grid;
            text-align: center;
            grid-template-columns: 90px 250px 280px 130px;
        }

        .blue-stake-grid-table-header {
            background: rgb(210, 200, 190);

            .blue-stake-grid-table-header-data {
                display: grid;
                align-items: center;
                padding: 3px 0;
                border: 1px solid black;
            }
        }

        .blue-stake-grid-table-row {
            background: #b3e5fc;

            .blue-stake-grid-table-row-data {
                display: grid;
                align-items: center;
                padding: 3px 0;
                border: 1px solid black;

                .text-input{
                    display: grid;
                    align-content: center;
                }
            }

            .selectable-cursor{
                cursor: pointer;
            }
        }
    }

    .add-blue-stake{
        display: grid;
        padding-left: 15px;
        border-right: 3px solid black;
        border-bottom: 2px solid black;
        border-left: 3px solid black;

        .add-blue-stake-line-item{
            justify-self: start;
        }
    }
`;

export default BlueStakeContainer;