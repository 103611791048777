import styled from 'styled-components';

const ViewLotsEditLotContainer = styled.div`
    display: grid;
    width: 800px;
    background: rgb(210, 200, 190);
    padding-bottom: 15px;
    border-top: 3px solid white;
    border-bottom: 3px solid white;

    .edit-lot-header{
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        margin-bottom: 10px;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 3px solid white;
    }

    .two-button-box{
        display: grid;
        justify-self: center;
        width: 300px;
        grid-template-columns: repeat(2, 1fr);
    }

    .three-button-box{
        display: grid;
        justify-self: center;
        width: 450px;
        grid-template-columns: repeat(3, 1fr);
    }
`;

export default ViewLotsEditLotContainer;