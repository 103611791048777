import styled from 'styled-components';

const LotReleasePhasesOnlyContainer = styled.div`
    display: grid;

    .lot-release-header {
        display: grid;
        width: 1400px;
        height: 30px;
        align-items: center;
        background: rgb(58, 64, 67);
        color: white;
        grid-template-columns: repeat(20, 1fr);

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 185px;
        z-index: 4;
        // ********************************

        .lot-release-save {
            grid-column: 2/3;
        }

        .lots-release-title {
            grid-column: 5/16;
            justify-self: center;
            font-size: 18px;
            display: grid;
            align-items: center;
        }
    }

    .lot-release-body {
        display: grid;
        width: 1400px;
        border-right: 2px solid black;
        border-bottom: 2px solid black;
        border-left: 2px solid black;
        grid-template-columns: 600px 200px 200px 398px;
        grid-template-rows: repeat(9, 1fr);


            .lot-release-phases-header {
                display: grid;
                grid-column: 1/5;
                grid-row: 1/2;
                grid-template-columns: 200px 150px 200px 50px;

                .lot-release-phases-header-data{
                    display: grid;
                    justify-content: center;
                    align-content: center;
                    border: 1px solid black;
                    background: rgb(210, 200, 190);
                }
            }

            .phases-table{
                display: grid;
                grid-column: 1/5;
                grid-row: 2/10;
                
                .lot-release-phases-row{
                    display: grid;
                    grid-template-columns: 200px 150px 200px 50px;
                    
                    .lot-release-phase-row-data{
                        display: grid;
                        justify-content: center;
                        align-content: center;
                        border: 1px solid black;

                        .release-date-picker{
                            background: inherit;
                            border: none;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            cursor: pointer;
                            font: inherit;
                            
                            .react-datepicker-popper{
                                z-index: 7;
                            }
                        }
                    }
                }
            }
        }

        .concrete-box{
            display: grid;
            grid-column: 2/3;
            grid-row: 1/10;
            grid-template-rows: repeat(9, 1fr);

            .concrete-header {
                display: grid;
                grid-row: 1/2;
                justify-content: center;
                align-content: center;
                border: 1px solid black;
                background: rgb(210, 200, 190);
            }

            .concrete-body{
                display: grid;
                grid-row: 2/10;
                grid-template-rows: repeat(10, 1fr);
                border: 1px solid black;
                
                .no-concrete-message{
                    display: grid;
                    justify-content: center;
                    align-content: center;
                    grid-row: 1/11;
                    padding: 0 5px;
                }

                .measurement-box{
                    display: grid;
                    grid-template-columns: 80px 70px 50px;

                    .measurement-name{
                        display: grid;
                        justify-content: end;
                        align-content: center;
                    }

                    .measurement{
                        display: grid;
                        justify-content: end;
                        align-content: center;
                        padding-right: 2px;
                    }

                    .measurement-suffix{
                        display: grid;
                        justify-content: start;
                        align-content: center;
                        padding-left: 2px;
                    }
                }
            }
        }

        .paver-box{
            display: grid;
            grid-column: 3/4;
            grid-row: 1/10;
            grid-template-rows: repeat(9, 1fr);

            .paver-header {
                display: grid;
                grid-row: 1/2;
                justify-content: center;
                align-content: center;
                border: 1px solid black;
                background: rgb(210, 200, 190);
            }

            .paver-body{
                display: grid;
                grid-row: 2/10;
                grid-template-rows: repeat(10, 1fr);
                border: 1px solid black;

                .no-paver-message{
                    display: grid;
                    justify-content: center;
                    align-content: center;
                    grid-row: 1/11;
                    padding: 0 5px;
                }
                
                .measurement-box{
                    display: grid;
                    grid-template-columns: 80px 70px 50px;

                    .measurement-name{
                        display: grid;
                        justify-content: end;
                        align-content: center;
                    }

                    .measurement{
                        display: grid;
                        justify-content: end;
                        align-content: center;
                        padding-right: 2px;
                    }

                    .measurement-suffix{
                        display: grid;
                        justify-content: start;
                        align-content: center;
                        padding-left: 2px;
                    }
                }

                .information-box{
                    display: grid;
                    grid-template-columns: 60px 140px;

                    .information-name{
                        display: grid;
                        justify-content: end;
                        align-content: center;
                        padding-right: 2px;
                    }

                    .information{
                        display: grid;
                        justify-content: start;
                        align-content: center;
                        padding-left: 2px;
                    }
                }

                .add-optional-pavers{
                    display: grid;
                    justify-content: center;
                    align-content: center;
                    grid-row: 1/11;
                }
            }
        }

        .gate-information-box{
            grid-column: 4/5;
            grid-row: 1/10;
            display: grid;
            grid-template-rows: 34px 270px;
            border: 1px solid black;

            .gate-information-header {
                display: grid;
                grid-row: 1/2;
                justify-content: center;
                align-content: center;
                background: rgb(210, 200, 190);
                border-bottom: 2px solid black;
            }
        }
    }
`;

export default LotReleasePhasesOnlyContainer;