import {useState} from 'react';
import dayjs from "dayjs";

import NotesIcon from '../../img/NotesIcon.png';
import { DropDownWithLabel, DropDown } from '../utilities/DropDown';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { Button } from '../utilities/Button.js';
import LotNotes from '../lots/LotNotes.js';
import Popup, {PopupWarningColor} from '../utilities/Popup.js';
import GateInformationContainer from './styles/GateInformationContainer';
import Auth from '../authentication/Auth.js';

const GateInformation = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [measurementTypes, setMeasurementTypes] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);

    const [job, setJob] = useState(null);
    const [lot, setLot] = useState(null);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const canCreateGatesInformation = props.isAuthorized('gateInformation', 'WRITE');
    const canEditGatesInformation = props.isAuthorized('gateInformation', 'WRITE'); 

    const loadData = async () => {
        setDidLoadData(true);

        const tempMeasurementTypes = await Auth.hitEndpointNew("GET", "measurement-types")
        setMeasurementTypes(tempMeasurementTypes.map(m => {
            return {
                ...m,
                display: m.name
            }
        }));

        const tempJobs = await Auth.hitEndpointNew("GET", "jobs-new");
        setJobs(tempJobs.map(j => {
            return{
                ...j,
                display: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => {
            return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;
        }))
    }

    if(!didLoadData){
        loadData();
    }

    const handleJobSelect = async (e) => {
        const tempJob = jobs.find(j => parseInt(j.id) === parseInt(e.target.value));
        setJob(tempJob);
        setLot(null);

        const tempLots = await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=job_id=${tempJob.id}`);
        setLots(tempLots.map(l => {
            return {
                ...l,
                display: `${l.number} - ${l.phase} - ${l.address}`
            }
        }).sort((l1, l2) => {
            const n1 = parseInt(l1.number);
            const n2 = parseInt(l2.number);
            if(n1 === n2){
                return l1.phase < l2.phase ? -1 : 1;
            }
            return n1 < n2 ? -1 : 1;
        }))
    }

    const handleLotSelect = async (e) => {
        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${e.target.value}`))[0];
        tempLot.masonryPhase = tempLot.phases.find(p => parseInt(p.phase_id) === 7)
        tempLot.gate_information = tempLot.gate_information.map(g => {
            return {
                ...g,
                displayMode: 'display'
            }
        })
        setLot({...tempLot});
    }

    const handleSaveGateInformation = async (gateId) => {
        const index = gateId !== undefined ? lot.gate_information.findIndex(g => parseInt(g.id) === parseInt(gateId)) : 0;

        if(lot.gate_information[index].width === ''){
            setError({
                type: 'NO_WIDTH',
                message: 'Please enter a width'
            })
            return;
        }
        if(lot.gate_information[index].measurement_type_id === null || lot.gate_information[index].measurement_type_id === ''){
            setError({
                type: 'NO_MEASUREMENT_TYPE',
                message: 'Please select the measurement type'
            })
            return;
        }
        if(lot.gate_information[index].handedness === ''){
            setError({
                type: 'NO_HANDEDNESS',
                message: 'Please select a handedness'
            })
            return;
        }

        const dbObj = {
            id: lot.id,
            gate_information: lot.gate_information.map(g => {
                delete g.measurement_type;
                delete g.displayMode;
                delete g.user;
                g.created_at = dayjs(new Date(g.created_at)).format('YYYY-MM-DD');
                g.level = g.level ? g.level : null;
                return g
            })
        }

        await Auth.hitEndpointNew("PATCH", `lot/no-processing`, '', dbObj);

        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${lot.id}`))[0];
        tempLot.masonryPhase = tempLot.phases.find(p => parseInt(p.phase_id) === 7)
        tempLot.gate_information = tempLot.gate_information.map(g => {
            return {
                ...g,
                displayMode: 'display'
            }
        })
        setLot({...tempLot});
    }

    const handleCancelCreateEditGateInformation = async () => {
        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${lot.id}`))[0];
        tempLot.masonryPhase = tempLot.phases.find(p => parseInt(p.phase_id) === 7)
        tempLot.gate_information = tempLot.gate_information.map(g => {
            return {
                ...g,
                displayMode: 'display'
            }
        })
        setLot({...tempLot});
    }

    const handleAddGateInformation = async () => {
        const tempLot = {...lot};
        tempLot.gate_information.splice(0, 0, {
            displayMode: 'create',
            lot_id: '',
            width: '',
            measurement_type: {
                id: 1,
                name: 'inches'
            },
            measurement_type_id: 1,
            handedness: '',
            decorative_iron: false,
            level: '',
            created_by: await Auth.getUserId(),
            created_at: new Date()
        });
        setLot({...tempLot});
    }

    return (
        <GateInformationContainer>
            <div className='gate-information-header'>Gate Information</div>
            <div className='gate-information-job-lot-header'>Select Job & Lot</div>
            <div className='gate-information-job-lot-select-box'>
                <DropDownWithLabel 
                    className="gate-information-job-drop-down" 
                    name="gate-information-job-drop-down" 
                    label="Job" 
                    value={job !== null && job.id !== undefined ? job.id : ''} 
                    handleDropDown={handleJobSelect} 
                    addBlankOption={true} 
                    selectionList={jobs} 
                    margin="10px 0 10px 0" 
                    height="23" 
                    columns={20} 
                    labelStart={1} 
                    labelEnd={5} 
                    inputStart={5} 
                    inputEnd={18}/>
                <DropDownWithLabel 
                    className="gate-information-lot-drop-down" 
                    name="gate-information-lot-drop-down" 
                    label="Lot" 
                    value={lot !== null && lot.id !== undefined ? lot.id : ''} 
                    handleDropDown={handleLotSelect} 
                    addBlankOption={true} 
                    selectionList={lots} 
                    margin="10px 0 10px 0"  
                    columns={20} 
                    labelStart={1} 
                    labelEnd={5} 
                    inputStart={5} 
                    inputEnd={18}/>
            </div>
            {lot && <div className='gate-information-sub-header'>Gate Information</div>}
            {lot !== null && lot.masonryPhase !== undefined &&
            <div className='release-date-box'>
                <div className='gate-information-release-date-box'>
                    <div className='gate-information-release-date-label'>Release Date:</div>
                    {lot.masonryPhase.release_date !== null ? 
                    <div className='gate-information-release-date-value'>
                        {dayjs(new Date(lot.masonryPhase.release_date)).format('YYYY-MM-DD')}
                    </div> :
                    <div className='gate-information-release-date-value'>Not Released</div>}
                </div>
                <div className='gate-information-release-status-box'>
                    <div className='gate-information-release-status-label'>Starts Status:</div>
                    {(lot.masonryPhase.starts_status !== null && lot.masonryPhase.starts_status !== '') ?
                    <div className='gate-information-release-status-value'>{lot.masonryPhase.starts_status}</div> :
                    <div className='gate-information-release-status-value'>N/A</div>}
                </div>
                <div className='gate-information-lot-notes-box'>
                    <div className='gate-information-lot-notes-label'>Lot Notes:</div>
                    <div 
                        className='gate-information-lot-notes-value' 
                        onClick={() => setDisplayNotes(true)}>
                            {lot.notes !== ''  ? 
                            <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : 
                            <div className='add-note-button-box'>
                                <div className='add-note-button'>Notes</div>
                        </div>}
                    </div>
                </div>
                <Button 
                    className="add-gate-information" 
                    handleKeyPress={(async (e) => {if(e.key === 'Enter'){await handleAddGateInformation()}})} 
                    handleClick={(async () => await handleAddGateInformation())} 
                    buttonText="Add Gate" 
                    buttonMargin="20px auto"
                    width="120"
                />
            </div>}
            {lot !== null && lot.gate_information !== null &&
            lot.gate_information.filter(g => g.deleted_at === null).map((g, index) => {
                if((g.displayMode === 'edit' && canEditGatesInformation)
                    || (g.displayMode === 'create' && canCreateGatesInformation)){
                    return (
                        <div key={index} className='gate-information-input-box'>
                            <TextboxWithLabel 
                                className="gate-information-width" 
                                name="gate-information-width" 
                                label="Width:" 
                                value={g.width} 
                                handleTextbox={(e) => {
                                    const tempLot = {...lot};
                                    const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                    tempLot.gate_information[index].width = e.target.value;
                                    setLot({...tempLot});
                                }} 
                                height="23px" 
                                margin="10px 0" 
                                columns={7} 
                                labelStart={1} 
                                labelEnd={5} 
                                inputStart={5} 
                                inputEnd={9}
                            />
                            <DropDown
                                className="gate-information-measurement-type" 
                                name="gate-information-measurement-type" 
                                value={g.measurement_type_id} 
                                handleDropDown={(e) => {
                                    const tempLot = {...lot};
                                    const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                    tempLot.gate_information[index].measurement_type_id = e.target.value;
                                    tempLot.gate_information[index].measurement_type = measurementTypes.find(t => parseInt(t.id) === parseInt(e.target.value));
                                    setLot({...tempLot});
                                }} 
                                addBlankOption={true} 
                                selectionList={measurementTypes} 
                                height="23px" 
                                margin="10px 0" 
                                columns={4} 
                                inputStart={2} 
                                inputEnd={3}
                            />
                            <DropDownWithLabel 
                                className="gate-information-handedness" 
                                name="gate-information-handedness" 
                                label="Left or Right:" 
                                value={g.handedness} 
                                handleDropDown={(e) => {
                                    const tempLot = {...lot};
                                    const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                    tempLot.gate_information[index].handedness = e.target.value;
                                    setLot({...tempLot});
                                }} 
                                addBlankOption={true} 
                                selectionList={[{id: 'Left', display: 'Left'}, {id: 'Right', display: 'Right'}, {id: 'Double', display: 'Double'}]}
                                height="23px" 
                                margin="10px 0" 
                                columns={10} 
                                labelStart={1} 
                                labelEnd={5} 
                                inputStart={5} 
                                inputEnd={8}
                            />
                            <CheckboxWithLabel 
                                className="gate-information-decorative-iron" 
                                name="gate-information-decorative-iron" 
                                label="Decorative Iron:" 
                                checked={g.decorative_iron} 
                                handleCheckbox={(e) => {
                                    const tempLot = {...lot};
                                    const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                    tempLot.gate_information[index].decorative_iron = e.target.checked;
                                    setLot({...tempLot});
                                }} 
                                margin="10px 0 15px" 
                                columns="10" 
                                labelStart="1" 
                                labelEnd="5" 
                                inputStart="5" 
                                inputEnd="8"
                            />
                            <DropDownWithLabel 
                                className="gate-information-level" 
                                name="gate-information-level" 
                                label="Gate Level:" 
                                value={g.level !== null ? g.level : ''} 
                                handleDropDown={(e) => {
                                    const tempLot = {...lot};
                                    const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                    tempLot.gate_information[index].level = e.target.value;
                                    setLot({...tempLot});
                                }} 
                                addBlankOption={true} 
                                selectionList={[{id: '1', display: '1'}, {id: '2', display: '2'}, {id: '3', display: '3'}, {id: '4', display: '4'}, {id: '5', display: '5'}]}
                                height="23px" 
                                margin="10px 0 15px" 
                                columns={10} 
                                labelStart={1} 
                                labelEnd={5} 
                                inputStart={5} 
                                inputEnd={8}
                            />
                            
                            <div>
                                <Button 
                                    className="gate-information-submit" 
                                    handleKeyPress={((e) => {if(e.key === 'Enter'){handleSaveGateInformation(g.id)}})} 
                                    handleClick={(() => handleSaveGateInformation(g.id))} 
                                    buttonText="Save" 
                                    width="120"
                                />
                                <Button 
                                className="gate-information-submit" 
                                handleKeyPress={(async (e) => {if(e.key === 'Enter'){await handleCancelCreateEditGateInformation()}})} 
                                handleClick={(async () => await handleCancelCreateEditGateInformation())} 
                                buttonText="Cancel" 
                                width="120"
                            />
                            </div>
                            
                        </div>
                    )
                }
                return (
                    <div 
                        key={index} 
                        className='gate-information-box'
                        onClick={() => {
                            const tempLot = {...lot};
                            const index = tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id));
                            tempLot.gate_information[index].displayMode = 'edit';
                            setLot({...tempLot});
                        }}
                    >
                        <div className="information-label">Width:</div>
                        <div className="information-data">{`${g.width} ${g.measurement_type ? g.measurement_type.name : ''}`}</div>
                        <div className="information-label">Left or Right:</div>
                        <div className="information-data">{g.handedness}</div>
                        <div className="information-label">Decorative Iron:</div>
                        <div className="information-data">{g.decorative_iron ? 'Yes' : 'No'}</div>
                        <div className="information-label">Gate Level:</div>
                        <div className="information-data">{g.level}</div>
                    </div>
                );
            })}
            {displayNotes ? 
            <LotNotes 
                isAuthorized={props.isAuthorized} 
                closeNotes={() => setDisplayNotes(false)} 
                lot={lot} 
                type='Masonry' 
                width='400'
            /> : null}
            {error.type !== '' && <Popup color={PopupWarningColor} message={error.message} handlePopup={() => setError({type: '', message: ''})}/>}
        </GateInformationContainer>
    )
}

export default GateInformation;