import {useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Auth from '../authentication/Auth.js';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import EWAs from '../../classes/Ewas'
import Employees from '../../classes/Employees.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import Popup, {PopupSuccessColor, PopupFailureColor} from '../utilities/Popup.js';
import EWAFormContainer from './styles/EWAFormContainer';
import dayjs from "dayjs";
import Phases from "../../classes/Phases";
import Utilities from "../../classes/Utilities";
import Config from "../../config";

const EWAForm = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
   // const [user, setUser] = useState('');

    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);

    const [lots, setLots] = useState([]);
   // const [selectedLot, setSelectedLot] = useState(null);

    const [ewa, setEWA] = useState(null);

    const [employeesForDropDown, setEmployeesForDropDown] = useState([]);

    const [errorNoPhaseCodeSelected, setErrorNoPhaseCodeSelected] = useState(false);
    const [errorNoJobDescription, setErrorNoJobDescription] = useState(false);
    const [errorNoScheduledDate, setErrorNoScheduledDate] = useState(false);
    const [errorNoAmountEntered, setErrorNoAmountEntered] = useState(false);
    const [errorNoInternalEstimateOrPO, setErrorNoInternalEstimateOrPO] = useState(false);
    const [errorNoEmployeeSelected, setErrorNoEmployeeSelected] = useState(false);
    const [success, setSuccess] = useState(false);

    const [phases, setPhases] = useState([]);
    const [ewaCodes, setEwaCodes] = useState([])

    const [selectedPhase, setSelectedPhase] = useState('')
    const [selectedEwaCode, setSelectedEwaCode] = useState('')

    const loadData = async () => {
        if(Utilities.isLoggedOut()){
            const uri = Config.BASE_UI();
            window.location.replace(uri);
        }
        else {
            setDidLoadData(true);
            //   setUser(`${await Auth.getFirstName()} ${await Auth.getLastName()}`);
            setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));
            setPhases(await Phases.loadPhases(null))
        }
    }

    if(!didLoadData){
        loadData();
    }

    const selectJob = async (e) => {
        setLots(Lots.sortLots("number", "ascending", await Lots.loadLotsForDropDown({jobId: e.target.value}, e.target.value)));
    }

    const resetPhaseCodeAndEmployeeDropDown = () => {
        const phaseCodeSelector = document.getElementById('ew-phase-code-id');
        if(phaseCodeSelector){
            phaseCodeSelector.value = '';
        }
        const employeeSelector = document.getElementById('employee-select-id');
        if(employeeSelector){
            employeeSelector.value = '';
        }
        const noteBox = document.getElementById('ew-form-job-description');
        if(noteBox !== null) {
            noteBox.value = '';
        }
        setSelectedEwaCode('')
        const internalEstimateBox = document.getElementById('ew-form-internal-estimate-number-input');
        if(internalEstimateBox !== null){
            internalEstimateBox.value = ''
        }
        const builderPoBox = document.getElementById('ew-form-builder-po-number-input');
        if(builderPoBox !== null){
            builderPoBox.value = ''
        }

    }

    const selectLotAndCreateEWA = async (e) => {
        resetPhaseCodeAndEmployeeDropDown();
        const tempSelectedLot = lots.find(lot => lot.id.toString() === e.target.value.toString());
        setEWA(await EWAs.createNewEWA(tempSelectedLot));
    }

    const selectPhase = async (e) => {
        const phase = phases.find(phaseToFind => parseInt(phaseToFind.id) === parseInt(e.target.value))
        setSelectedPhase(phase)
        ewa.line_items[0].phase_id = phase !== undefined ? phase.id : null

        setEwaCodes(await EWAs.loadEWACodes({"phase_task_phases.phase_id": e.target.value}))

        const company = phase !== undefined ? phase.company : null;
        let employees = []
        if(company === null){
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", null);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVC"){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVP"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        setEmployeesForDropDown(employees)
    }

    const selectPhaseCode = async (e) => {
        const ewaCode = ewaCodes.find(codeToFind => codeToFind.id.toString() === e.target.value.toString())
        setSelectedEwaCode(ewaCode)
        ewa.line_items[0].phase_task_id = e.target.value
    }

    const selectEmployee = (e) => {
        const selectedEmployee = employeesForDropDown.find(employee => employee.id.toString() === e.target.value.toString());
        if(selectedEmployee === undefined) {
            ewa.line_items[0].crew = {
                crew_id: null,
                employees: [{
                    employee_id: null,
                    is_lead: false,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                }]
            }
        }
        else{
            ewa.line_items[0].crew = {
                crew_id: selectedEmployee.membership !== null ? selectedEmployee.membership.crew_id : null,
                employees: [{
                    employee_id: selectedEmployee.id,
                    is_lead: true,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                }]
            }
        }
        setEWA({...ewa});
    }

    const setLineItem = (e, field, value) => {
        ewa.line_items[0][field] = value === undefined || value === '' ? e.target.value : value
        setEWA({...ewa})
    }

    const setEWABillable = (e, field, value) => {
        ewa[field] = value === undefined || value === '' ? e.target.value : value
        setEWA({...ewa})
    }

    const handleErrorCode = (errorCode) => {
        switch(errorCode){
            case "ERROR_NO_PHASE_CODE": setErrorNoPhaseCodeSelected(true);break;
            case "ERROR_NO_DESCRIPTION": setErrorNoJobDescription(true);break;
            case "ERROR_NO_SCHEDULED_DATE": setErrorNoScheduledDate(true);break;
            case "ERROR_NO_PAYOUT_AMOUNT": setErrorNoAmountEntered(true);break;
            case "ERROR_NO_INTERNAL_ESTIMATE_OR_BUILDER_PO": setErrorNoInternalEstimateOrPO(true);break;
            case "ERROR_NO_EMPLOYEE_SELECTED": setErrorNoEmployeeSelected(true);break;
            default: break;
        }
    }

    const handleSubmitEWA = async () => {
        const result = await EWAs.checkEWAForErrors(ewa, selectedEwaCode.billable === 1);
        if(result !== "SUCCESS"){
            handleErrorCode(result);
            return;
        }

        let number
        let ewaToSave = {...ewa}
        let scheduleYear = dayjs(new Date(ewa.schedule_date)).format('YYYY')
        let ewaCode = ewaCodes.find(codeToFind => codeToFind.id.toString() === ewa.line_items[0].phase_task_id.toString())
        ewaToSave.line_items[0].scheduled = true
        if(ewaCode !== undefined && ewaCode !== null) {
            let company = selectedPhase !== undefined && selectedPhase !== '' ? selectedPhase.company : 'SVC'
            let suffix = selectedPhase !== undefined && selectedPhase !== '' ? selectedPhase.ewa_suffix : 'ALL'
            number = await EWAs.getNextNumber(company, suffix, scheduleYear, number)
            ewaToSave.number = number.ewa_number
        }

        await EWAs.saveNewEWA(ewaToSave)
        resetPhaseCodeAndEmployeeDropDown();
        setEWA(await EWAs.createNewEWA(null));
        setSuccess(true);
    }

    const togglePopup = () => {
        setErrorNoPhaseCodeSelected(false);
        setErrorNoJobDescription(false);
        setErrorNoScheduledDate(false);
        setErrorNoAmountEntered(false);
        setErrorNoEmployeeSelected(false);
        setErrorNoInternalEstimateOrPO(false);
        setSuccess(false);
    }

    return (
        <EWAFormContainer>
            <div className='ewa-form-header'>Extra Work Authorization</div>
            <div className='ewa-form-sub-header'>Select Job & Lot</div>
            <div className='ewa-form-job-drop-down-box'>
                <label className="ewa-form-job-drop-down-label" htmlFor='ewa-form-job-select'>Job</label>
                <select className="ewa-form-job-drop-down-select" name='ewa-form-job-select' onChange={selectJob}>
                    <option className="ewa-form-job-drop-down-option" value=''></option>
                    {jobsForDropDowns.map(job => {
                        return (
                            <option key={job.id} className="ewa-form-job-drop-down-option" value={job.id}>{job.display}</option>
                        )
                    })}
                </select>
            </div>
            <div className='ewa-form-lot-drop-down-box'>
                <label className="ewa-form-lot-drop-down-label" htmlFor='ewa-form-lot-select'>Lot</label>
                <select className="ewa-form-lot-drop-down-select" name='ewa-form-lot-select' onChange={selectLotAndCreateEWA}>
                    <option className="ewa-form-lot-drop-down-option" value=''></option>
                    {lots.map(lot => {
                        const value = lot.id;
                        const display = `${lot.number} - ${lot.phase} - ${lot.address}`;
                        return (
                            <option key={value} className="ewa-form-lot-drop-down-option" value={value}>{display}</option>
                        )
                    })}
                </select>
            </div>
            {ewa &&
            <div className='ew-form-box'>
                <div className='ew-form-sub-header'>Extra Work Authorization Form</div>
                <div className='ew-form-phase-drop-down-box'>
                    <label className="ew-form-phase-drop-down-label" htmlFor='ew-form-phase-select'>Phase</label>
                    <select id='ew-phase-id' className="ew-form-phase-drop-down-select" name='ew-form-phase-select' onChange={selectPhase}>
                        <option className="ew-form-phase-drop-down-option" value=''></option>
                        {phases.map(phase => {
                            const value = phase.id;
                            const display = `${phase.name}`;
                            return (
                                <option key={value} className="ew-form-phase-drop-down-option" value={value}>{display}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='ew-form-phase-code-drop-down-box'>
                    <label className="ew-form-phase-code-drop-down-label" htmlFor='ew-form-phase-code-select'>Phase Code</label>
                    <select id='ew-phase-code-id' className="ew-form-phase-code-drop-down-select" name='ew-form-phase-code-select' onChange={selectPhaseCode}>
                        <option className="ew-form-phase-code-drop-down-option" value=''></option>
                        {ewaCodes.map(code => {
                            const value = code.id;
                            const display = `${code.code} - ${code.description}`;
                            return (
                                <option key={value} className="ew-form-phase-code-drop-down-option" value={value}>{display}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='ew-form-job-description-box'>
                    <div className='ew-form-job-description-header'>Job Description</div>
                    <div className='ew-form-job-description-text-area'>
                        <textarea className="ew-form-job-description-text-input" name='ew-form-job-description' id={'ew-form-job-description'} rows='3' cols='30' placeholder='Enter Job Description' value={ewa.line_items[0].note} onChange={(e) => setLineItem(e, "notes")}/>
                    </div>
                </div>
                <div className='ew-scheduled-on-date'>
                    <div className='ew-scheduled-on-label'>Scheduled Date</div>
                    <div className='ew-scheduled-on-date-picker-box'>
                        <DatePicker className='ew-scheduled-on-date-picker' enableTabLoop={false} selected={ewa.line_items[0].schedule_date} onChange={date => setLineItem(null, "schedule_date", date)} />
                    </div>
                </div>
                <div className='ew-form-amount-box'>
                    <div className='amount-label'>Payout $</div>
                    <div className='amount-input-box'>
                        <input id='id-amount-input' className='amount-input' type='number' value={ewa.line_items[0].amount} onClick={() => document.getElementById('id-amount-input').select()} onChange={(e) => setLineItem(e, "payout")}/>
                    </div>
                </div>
                {selectedEwaCode.billable === 1 &&
                    <div>
                        <div className='ew-form-internal-estimate-number-box'>
                            <div className='ew-internal-estimate-number-label'>Internal Estimate #</div>
                            <div className='internal-estimate-number-input-box'>
                                <input id='id-internal-estimate-number-input' className='internal-estimate-number-input' value={ewa.line_items[0].internal_estimate_number} onClick={() => document.getElementById('id-internal-estimate-number-input').select()} onChange={(e) => setEWABillable(e, "internal_estimate_number")}/>
                            </div>
                        </div>
                        <div className='ew-form-builder-po-number-box'>
                            <div className='ew-builder-po-number-label'>Builder PO #</div>
                            <div className='builder-po-number-input-box'>
                                <input id='id-builder-po-number-input' className='builder-po-number-input' value={ewa.line_items[0].builder_po_number} onClick={() => document.getElementById('id-builder-po-number-input').select()} onChange={(e) => setEWABillable(e, "builder_po_number")}/>
                            </div>
                        </div>
                    </div>}
                <DropDownWithLabel className="employee-select" id="employee-select-id" name="employee-select" label="Employee" handleDropDown={selectEmployee} addBlankOption={true} selectionList={employeesForDropDown} columns={20} labelStart={1} labelEnd={8} inputStart={8} inputEnd={19}/>
                <div className='ew-form-submit'>
                    <div tabIndex='0' className='ew-form-submit-button' onClick={handleSubmitEWA}>Submit Extra Work Authorization</div>
                </div>
            </div>}
            {errorNoPhaseCodeSelected && <Popup color={PopupFailureColor} message={'No Phase Code Selected. Please select a phase code.'} handlePopup={togglePopup}/>}
            {errorNoJobDescription && <Popup color={PopupFailureColor} message={'No job description entered. Please enter a job description.'} handlePopup={togglePopup}/>}
            {errorNoScheduledDate && <Popup color={PopupFailureColor} message={'No scheduled date selected. Please select a schedule date.'} handlePopup={togglePopup}/>}
            {errorNoAmountEntered && <Popup color={PopupFailureColor} message={'No amount entered. Please enter an amount.'} handlePopup={togglePopup}/>}
            {errorNoInternalEstimateOrPO && <Popup color={PopupFailureColor} message={'No internal estimate # or builder po # entered. Please enter an internal estimate # and/or builder po #.'} handlePopup={togglePopup}/>}
            {errorNoEmployeeSelected && <Popup color={PopupFailureColor} message={'No employee selected. Please select an employee.'} handlePopup={togglePopup}/>}
            {success && <Popup color={PopupSuccessColor} message={`Extra Work Authorization Form has been submitted`} handlePopup={togglePopup}/>}
        </EWAFormContainer>
    )
};

export default EWAForm;