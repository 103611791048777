import Select, {createFilter} from "react-select";
import {useState} from "react";
import Employees from "../../../classes/Employees";
import Button from "@mui/material/Button";
import CheckIcon from '@mui/icons-material/Check';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import Crews from "../../../classes/Crews";

const CrewFilter = ({crews, employees, setEmployees, selectedEmployeesEdit, setSelectedEmployeesEdit, phase}) => {

    const [selectedCrew, setSelectedCrew] = useState('')
    const [selectedEmployee, setSelectedEmployee] = useState('')

    const handleCrewChanged = async (e) => {
        setSelectedEmployee('')
        setSelectedCrew(e)
        handleAddEmployee(e, '')
    }
    const handleEmployeeSelected = async (e) => {
        setSelectedEmployee(e)
        setSelectedCrew('')
        handleAddEmployee('', e)
    }
    const handleAddEmployee = async (crew, employee) => {
        let current = [...selectedEmployeesEdit]
        let existing
        if (crew !== null && crew !== '' && employee === '') {
            const firstValue = -1;
            const secondValue = -firstValue;
            crew.members.sort((m1, m2) => {
                return m1.position < m2.position ? firstValue : secondValue;
            })
            for (let m = 0; m < crew.members.length; m++) {
                let emp = employees.find(e => e.id.toString() === crew.members[m].employee.id.toString())
                existing = current.filter(x => x.id.toString() === emp.id.toString())
                if (existing === undefined || existing.length === 0) {
                    emp.approved = true
                    current.push(emp)
                }
            }
            setSelectedEmployeesEdit(current)
            if ((crew !== undefined && crew.members.length < 2)) {
                setSelectedCrew('')
                await handleLoadEmployees()
            }
        } else {
            if (employee !== null && employee !== '') {
                existing = current.filter(x => x.id.toString() === employee.id.toString())
                if (existing === undefined || existing.length === 0) {
                    employee.approved = true
                    current.push(employee)
                    setSelectedEmployeesEdit(current)
                }
                else{
                    for(let c=0;c< current.length;c++){
                        if(current[c].id.toString() === employee.id.toString()){
                            current[c].approved = true
                        }
                    }
                }
                setSelectedEmployee('')
                if ((crew !== undefined && crew !== '' && crew.members.length < 2)) {
                    setSelectedCrew('')
                    await handleLoadEmployees()
                }
                else{
                }
            }
        }
    }

    const handleLoadEmployees = async () => {
        let queryObj = phase !== undefined && phase !== null ? {[phase.phase.company.toLowerCase()]: 1} : null
        setEmployees(await Employees.loadEmployeesForDropDown(queryObj))
    }
    const handleLoadCrews = async (phase) => {
        let queryObj = phase !== null ? {company: phase.phase.company} : null
       // setCrews(await Crews.loadCrewsForDropdown(queryObj))
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: ('any'),
    };

    return (
            <div style={{marginTop: "0px", paddingBottom: "1px"}}>
                <Select id="crew-filter-select-id"
                        className="crew-filter-select"
                        value={selectedCrew}
                        placeholder="Select A Crew"
                        onChange={(e) => handleCrewChanged(e)}
                        options={crews}
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig)}/>
                <Select id="employee-filter-select-id"
                        className="employee-filter-select"
                        value={selectedEmployee}
                        placeholder="Select An Employee"
                        onChange={(e) => handleEmployeeSelected(e)}
                        options={employees}
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig)}/>
            </div>
    )
}

export default CrewFilter;
