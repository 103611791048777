import {useState} from "react";
import CrewContainer from "../styles/CrewContainer"
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import CrewFilter from "./CrewFilter";
import CrewGrid from "./CrewGrid";

const ScheduleCrew = ({crews, employees, setEmployees, selectedEmployees, setSelectedEmployees, empsToRemove, setEmpsToRemove, setOpen, showActions, phase}) => {

    const [selectedEmployeesEdit, setSelectedEmployeesEdit] = useState(selectedEmployees)

    const handleCloseNoSave = () => {
        setOpen(false);
    };

    const handleCloseSave = () => {

        setSelectedEmployees(selectedEmployeesEdit)
        setOpen(false)
    }

    return (
        <CrewContainer>
            <CrewFilter
                crews={crews}
                employees={employees}
                setEmployees={setEmployees}
                setSelectedEmployeesEdit={setSelectedEmployeesEdit}
                selectedEmployeesEdit={selectedEmployeesEdit}
                phase={phase}
            />
            <CrewGrid
                selectedEmployees={selectedEmployees}
                setSelectedEmployees={setSelectedEmployees}
                setSelectedEmployeesEdit={setSelectedEmployeesEdit}
                selectedEmployeesEdit={selectedEmployeesEdit}
                empsToRemove={empsToRemove}
                setEmpsToRemove={setEmpsToRemove}
                minHeight={"400px"}
                marginTop={"60px"}
            />
            {showActions &&
                <DialogActions>
                    <Button onClick={handleCloseNoSave}>Cancel</Button>
                    <Button onClick={handleCloseSave}>Save</Button>
                </DialogActions>}
        </CrewContainer>
    )
}

export default ScheduleCrew;