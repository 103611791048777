import styled from 'styled-components';

const DeleteLotContainer = styled.div`
    display: grid;
    width: 600px;
    margin-left: 10px;
    margin-top: 10px;

    .header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .delete-lot-box {
        background: rgb(210, 200, 190);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding: 10px 0;
    }
`;

export default DeleteLotContainer;