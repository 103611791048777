import styled from 'styled-components';

const ScheduleRequestContainer = styled.div`
    display: grid;
    margin: 10px 0 0 10px;
    // width: 600px;

//     .phase-code-form{
//         display: grid;

//         .bold{
//             font-weight: bold;
//         }

//         .form-header{
//             font-size: 25px;
//             text-align: center;
//             margin: 10px auto;
//             font-weight: bold;
//         }

//         .form-instructions{
//             text-align: center;
//             margin-bottom: 10px;
            
//             span{
//                 display: block;
//             }

//         }

//         .submit-button{
//             justify-self: center;
//             width: 150px;
//             margin: 10px 0 20px;
//             border: 1px solid rgb(220, 220, 220);
//         }
//     }
`;

export default ScheduleRequestContainer;
