import {jsPDF} from "jspdf";
import dayjs from "dayjs";
import Auth from "../../components/authentication/Auth";
import {Buffer} from "buffer";
import logo from "../../img/HeaderLogo.png";
import * as AWS from "aws-sdk";
import config from "../../config";
import moment from "moment";


class SchedulePDF {

    static async createReport(date, reportData) {

        let user = await Auth.getFullName()
        const pdf = new jsPDF('p', 'pt', 'A4')

        for (let r = 0; r < reportData.length; r++) {
            if (r > 0) {
                pdf.addPage('string', 'p');
                this.addHeader(pdf, reportData[r].group_display, date, reportData[r].data.length)
            }
            else{
                this.addHeader(pdf, reportData[r].group_display, date, reportData[r].data.length)
            }
            await this.buildSchedule(pdf, reportData[r], date)
        }
        this.addFooters(pdf, user)

        let filename = `Schedule Report ${dayjs(date).format('YYYY-MM-DD')} ${await Auth.getFullName()}`
        const buffer = Buffer.from(new Uint8Array(pdf.output('arraybuffer')));
        let url = await this.uploadToAws(filename, buffer)

        return url

    }

    static async buildSchedule (doc, reportData, date) {

        let x = 20
        let y = 74

        let scheduleY = y

        for (let d = 0; d < reportData.data.length; d++) {

            let boxHeight = 0
            let crew = reportData.data[d]

            let dimensions = await this.newPage(doc, crew, x, 0, 0, 0, y, reportData, date, false)
            y = dimensions.y
            y += 25
            boxHeight += 25

            doc.setFontSize(8)
            doc.setTextColor(0,0,0)
            doc.setFont("helvetica", "normal");

            doc.setFillColor(128, 128, 128)
            doc.rect(x, y - 15, 100, 25, 'F')

            doc.setTextColor(255,255,255)
            doc.text(x + 10, y, `Crew - ${crew.crew_number}`)
            doc.setTextColor(0,0,0)

            y += 25
            boxHeight += 25
            scheduleY = y
            let startY = y - 15

            let empDimensions = await this.writeEmployees(doc, crew, x, y, scheduleY, boxHeight, false)
            y = empDimensions.y
            scheduleY = empDimensions.scheduleY
            boxHeight = empDimensions.boxHeight

            if(crew.schedule !== undefined) {
                for (let s = 0; s < crew.schedule.length; s++) {
                    if(s > 0){
                        scheduleY += 25
                    }
                    if(scheduleY > 759){
                        dimensions = await this.newPage(doc, crew, x, startY, boxHeight, scheduleY, y, reportData, date, true)
                        y = dimensions.y
                        scheduleY = dimensions.scheduleY
                        boxHeight = dimensions.boxHeight
                        startY = dimensions.startY
                    }
                    if(scheduleY > y){
                        boxHeight += 25
                    }

                    let row = crew.schedule[s]
                    let schDimensions = await this.writeSchedule(doc, row, x, scheduleY, boxHeight, s, crew.schedule.length)
                    scheduleY = schDimensions.scheduleY
                    boxHeight = schDimensions.boxHeight

                }
            }

            let endY = scheduleY > y ? scheduleY : y
            if((startY + boxHeight) > (endY + 35)){
                boxHeight = boxHeight - ((startY + boxHeight) - (endY + 35))
            }
            doc.rect(x, startY, 550, boxHeight - 20, 'S')
            doc.line(200, startY, 200, startY + (boxHeight - 20) , 'S')
            y += 10
            scheduleY += 10
            if(scheduleY > y){
                y = scheduleY
            }
        }
    }

    static async newPage(doc, crew, x, startY, boxHeight, scheduleY, y, reportData, date, crewContinuation, newPageAdded){

        if(crewContinuation) {
            doc.rect(x, startY, 550, boxHeight - 20, 'S')
            doc.line(200, startY, 200, startY + (boxHeight - 20) , 'S')

            doc.addPage('string', 'p');
            this.addHeader(doc, reportData.group_display, date, reportData.data.length)
            scheduleY = 99
            y = 99

            doc.setFontSize(8)
            doc.setFillColor(128, 128, 128)
            doc.rect(x, y - 15, 100, 25, 'F')
            doc.setTextColor(255,255,255)
            doc.text(x + 10, y, `${crew.crew_number} --- Continued`)
            doc.setTextColor(0,0,0)

            y += 25
            scheduleY += 25
            boxHeight = 25
            boxHeight += 25
            startY = y - 15

            let empDimensions = await this.writeEmployees(doc, crew, x, y, scheduleY, boxHeight, crewContinuation)
            y = empDimensions.y
            scheduleY = empDimensions.scheduleY
            boxHeight = empDimensions.boxHeight
        }
        else{
            let empsLength = crew.employees === undefined ? 0 : crew.employees.length * 25
            let scheduleLength = crew.schedule === undefined ? 0 : crew.schedule.length * 25

            let length = scheduleLength > empsLength ? scheduleLength : empsLength
            if(y > 99 && y + length > 759 && !newPageAdded){
                doc.addPage('string', 'p');
                this.addHeader(doc, reportData.group_display, date, reportData.data.length)
                y = 74
            }
        }

        return {
            y: y,
            scheduleY: scheduleY,
            boxHeight: boxHeight,
            startY: startY,
        }
    }

    static async writeEmployees(doc, crew, x, y, scheduleY, boxHeight, crewContinuation){

        doc.text(x + 10, y, `${crew.lead}${crewContinuation ? ' --- cont.' : ''}`, {maxWidth: 150})
        let leadEmp = crew.employees.find(x => x.employee === crew.lead)
        if(leadEmp !== undefined){
            if(leadEmp.on_crew_note !== ''){
                doc.setFontSize(6)
                doc.setTextColor(142,35,35);
                doc.setFont("helvetica", "bold");
                if(crew.lead.length > 35){
                    y+= 25
                    boxHeight += 25
                }
                doc.text(x + 50, y + 10, leadEmp.on_crew_note)
            }
        }
        for(let e=0; e< crew.employees.length; e++){
            let employee = crew.employees[e]
            if(employee.employee !== crew.lead) {
                y += 25
                boxHeight += 25
                if(y === 99){scheduleY = y}
                doc.setFontSize(8)
                doc.setTextColor(0,0,0);
                doc.setFont("helvetica", "normal");
                doc.text(x + 25, y, `${employee.employee}${crewContinuation ? ' --- cont.' : ''}`, {maxWidth: 200})

                if(employee.on_crew_note !== ''){
                    doc.setFontSize(6)
                    doc.setTextColor(142,35,35);
                    doc.setFont("helvetica", "bold");
                    if(employee.employee.length > 35){
                        y+= 25
                        boxHeight += 25
                    }
                    doc.text(x + 50, y + 10, employee.on_crew_note)
                }
                if(employee.from_crew_note !== ''){
                    doc.setFontSize(6)
                    doc.setTextColor(142,35,35);
                    doc.setFont("helvetica", "bold");
                    if(employee.employee.length > 35){
                        y+= 25
                        boxHeight += 25
                    }
                    doc.text(x + 50, y + 10, employee.from_crew_note)
                }
            }
        }

        return {
            y: y,
            scheduleY: scheduleY,
            boxHeight: boxHeight
        }
    }

    static async writeSchedule(doc, row, x, scheduleY, boxHeight, s, scheduleLength){

        //A lot of effort to get the alternating rows color implemented but... ¯\_(ツ)_/¯
        if(row.complete === 1){
            if(s % 2 === 0) {
                doc.setFillColor(144, 238, 144)
            }
            else{
                doc.setFillColor(188, 245, 188)
            }
        }
        else if(s % 2 === 0){
            doc.setFillColor(255, 255, 204)
        }
        else{
            doc.setFillColor(255, 255, 255)
        }

        let rowBoxHeight = 25
        if(row.task_name.length > 26){
            scheduleY += 5
            boxHeight += 5
            rowBoxHeight += 5
        }
        if(row.detail.length > 0){
            rowBoxHeight += 15
        }
        if(row.notes !== null && row.notes !== '') {
            rowBoxHeight += 15
        }
        if(s === scheduleLength - 1 || scheduleY + rowBoxHeight + 10 > 759){
            rowBoxHeight += 5
        }
        doc.rect(200, scheduleY - 15, 370, rowBoxHeight, 'F')
        if(s > 0) {
            doc.line(200, scheduleY - 15, 570, scheduleY - 15, 'S')
        }
        doc.setFontSize(8)
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");
        doc.text(x + 200, scheduleY, row.task_name, {maxWidth: 100})
        doc.text(x + 300, scheduleY, row.job_number, {maxWidth: 30})
        doc.text(x + 330, scheduleY, row.project, {maxWidth: 100})
        doc.text(x + 450, scheduleY, row.lot_phase_number, {maxWidth: 100})
        doc.text(x + 530, scheduleY, row.routing_seq === null ? '' : row.routing_seq.toString(), {maxWidth: 20})
        if(row.detail.length > 0){
            doc.setFontSize(6)
            scheduleY += 15
            boxHeight += 15
            let dx = x + 200
            for(let d=0; d<row.detail.length; d++) {
                let dxToAdd = 85
                let name = row.detail[d].taskDetail.type.name
                let key = row.detail[d].taskDetail.type.key
                let uom = row.detail[d].taskDetail.type.uom

                let value = row.detail[d].supplier === null ? row.detail[d].schedule_value : row.detail[d].supplier.name
                if (key === 'time') {
                    value = moment(value, 'HH:mm:ss').format('h:mm:ss A');
                    dxToAdd = 45
                }
                if (uom !== null) {
                    value = `${value} ${uom}${parseFloat(value) > 1 && uom !== '"'? 's' : ''}`
                    dxToAdd = 55
                }
                if (key === 'order') {
                    value = `${name} ${value}`
                    dxToAdd = 55
                }
                doc.text(dx, scheduleY, value, {maxWidth: 100})
                dx += dxToAdd
            }
        }
        doc.setFontSize(6)
        if(row.notes !== null && row.notes !== '') {
            scheduleY += 15
            boxHeight += 15
            doc.text(x + 200, scheduleY, row.notes, {maxWidth: 300})
        }
        doc.setFontSize(8)
        return {
            scheduleY: scheduleY,
            boxHeight: boxHeight,
        }
    }

    static addHeader(doc, display, date, dataLength){
        let middlePos = doc.internal.pageSize.width / 2
        doc.addImage(logo, 'PNG', 24, 15, 110, 60);
        doc.setFontSize(20)
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");
        doc.text(`${display} Daily Schedule`, middlePos, 34, {halign: 'center', align: 'center'});
        doc.text(`${dayjs(date).format('MM/DD/YYYY')}`, middlePos, 64, {halign: 'center', align: 'center'});
        if (dataLength === 0) {
            doc.setFontSize(15)
            doc.text(`No Lots Scheduled & No Crews Assigned to ${display}`, middlePos, 104, {halign: 'center', align: 'center'});
        }
    }

    static addFooters (doc, user) {

        const pageCount = doc.internal.getNumberOfPages()

        doc.setFontSize(8)
        doc.setTextColor(0,0,0);
        doc.setFont('helvetica', 'italic')
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 30 , 807, {
                align: 'right'
            })
            //May want to add some text to this. Leaving it as is for now.
            doc.text(`${user}`, 20, 807, {
                align: 'left'
            })
        }
    }


    static async uploadToAws (filename, file){
        const s3 = new AWS.S3(config.S3_INFO);

        const params = {
            Bucket: 'svcts-schedules-user',
            Key: filename, // File name you want to save as in S3
            Body: file,
            ACL: "public-read",
            ContentType: "application/pdf"
        };

        return await new Promise((resolve) => {
            s3.upload(params, async function (error, data) {
                return resolve(data.Location);
            });
        });
    }
}

export default SchedulePDF;
