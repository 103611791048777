import {useState} from 'react';

import Lots from '../../classes/Lots';
import Auth from '../authentication/Auth';
import Globals from '../utilities/Globals';
import LotNotesContainer from './styles/LotNotesContainer';

const LotNotes = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [user, setUser] = useState('');

    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');

    const canCreateNote = props.isAuthorized('lotNotes', 'WRITE');

    const loadData = async () => {
        setUser(await Auth.getFullName());

        const tempNotes = [];
        const noteString = props.lot.notes;

        if(noteString.length > 0){
            const splitNotes = noteString.split('@').filter(note => note !== '');
            for(let i = 0; i < splitNotes.length; i++){
                const note = splitNotes[i].split('^');
                const noteObj = {
                    date: note[0],
                    user: note[1],
                    type: note[2],
                    note: note[3]
                }
                tempNotes.push(noteObj);
            }
        }
        setNotes(tempNotes);

        setDidLoadData(true);
    }

    if(!didLoadData){
        loadData();
    }

    const saveNotes = async () => {
        let result = '';
        for(let i = 0; i < notes.length; i++){
            result += `${notes[i].date}^${notes[i].user}^${notes[i].type}^${notes[i].note}@`;
        }
        const tempLot = {
            id: props.lot.id,
            notes: result
        }
        props.lot.notes = result;
        
        await Auth.hitEndpointNew("PATCH", `lot/no-processing`, '', tempLot);
    }

    const handleSubmitNote = async () => {
        if(newNote === ''){
            return;
        }
        const tempNotes = notes;
        const tempNewObject = {
            date: (new Date()).toISOString(),
            user: user,
            type: props.type,
            note: newNote,
        }
        tempNotes.push(tempNewObject);
        setNotes(tempNotes);
        setNewNote('');
    }

    return (
        <LotNotesContainer width={props.width}>
            <div className='notes-header'>
                <div className='title'>Notes</div>
                <div className='close-out'>
                    <div className='close-out-button' onClick={(e) => {saveNotes(); props.closeNotes();}}>Close</div>
                </div>
            </div>
            {notes.map((note, index) => {
                return (
                    <div key={`${index}-note`} className='note-box'>
                        <div className='note-info-box'>
                            <div className='note-date'>{Globals.formatDateToDisplay(note.date)}</div>
                            <div className='note-user'>{note.user}</div>
                            <div className='note-type'>{note.type}</div>
                        </div>
                        <div className='note-note-box'>
                            <div className='note-note'>{note.note}</div>
                        </div>
                    </div>
                )
            })}
            {canCreateNote &&
            <div className='input-box'>
                <input className="note-input" type='text' name='note' placeholder='Enter New Note' value={newNote} onChange={((e) => setNewNote(e.target.value))}/>
                <div className='submit'>
                    <div tabIndex='0' className='button' onClick={handleSubmitNote}>Submit</div>
                </div>
            </div>}
        </LotNotesContainer>
    );
};

export default LotNotes;