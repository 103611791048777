import {useState} from 'react';
import moment from "moment/moment";

import Globals from '../utilities/Globals.js';
import Auth from '../authentication/Auth';
import BlueStakeContainer from './styles/BlueStakeContainer';
import { Button } from '../utilities/Button.js';
import { Textbox } from '../utilities/Textbox.js';
import PopupOk, {PopupOkWarningColor} from '../utilities/PopupOk';

const BlueStake = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [lot, setLot] = useState(null);
    
    const [users, setUsers] = useState([]);

    const [errorNoTicketNumber, setErrorNoTicketNumber] = useState(false);

    const canCreateBlueStake = props.isAuthorized('blueStake', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);
        
        setUsers(await Auth.hitEndpoint("GET", "USERS"));

        const tempLot = props.lot;
        const tempUserId = await Auth.getUserId();
        tempLot.bluestake.sort((b1, b2) => (new Date(b1.date)) < (new Date(b2.date)) ? 1 : -1).forEach(b => {
            if(b.date === null){
                b.date = new Date();
            }
            if(b.user_id === null){
                b.user_id = tempUserId;
            }
        });
        setLot(tempLot);
    }

    if(!didLoadData){
        loadData();
    }

    const ticketInput = async (value, index) => {
        const tempLot = {...lot};
        tempLot.bluestake[index].ticket = value;
        if(tempLot.bluestake[index].new !== undefined){delete tempLot.bluestake[index].new;}
        setLot({...tempLot});
    }

    const commentInput = async (value, index) => {
        const tempLot = {...lot};
        tempLot.bluestake[index].comment = value;
        if(tempLot.bluestake[index].new !== undefined){delete tempLot.bluestake[index].new;}
        setLot({...tempLot});
    }

    const closeBlueStakePopup = async () => {
        for(let i = 0; i < lot.bluestake.length; i++){
            if(lot.bluestake[i].ticket === '' || lot.bluestake[i].ticket === null){
                setErrorNoTicketNumber(true);
                return;
            }
        }

        const savedLot = {
            id: lot.id,
            bluestake: lot.bluestake.map(b => {
                b.date = moment(new Date(b.date)).format('YYYY-MM-DD H:mm:ss');
                b.closed = true;
                return b;
            })
        }

        await Auth.hitEndpointNew("PATCH", "lot/no-processing", "", savedLot);

        props.closeBlueStake(lot);
    }

    const handleNoTicketNumber = (e) => {
        if(e.target.value === 'cancel'){
            setErrorNoTicketNumber(false);
            return;
        }

        lot.bluestake = lot.bluestake.filter(b => b.ticket !== '' && b.ticket !== null);
        closeBlueStakePopup();
    }

    const addBlueStakeLineItem = async () => {
        const tempLot = {...lot};
        tempLot.bluestake.push({
            new: true,
            lot_id: tempLot.id,
            // processed: false,
            // closed: false,
            date: new Date(),
            user_id: await Auth.getUserId(),
            ticket: '',
            comment: ''
        });
        tempLot.bluestake.sort((b1, b2) => (new Date(b1.date)) < (new Date(b2.date)) ? 1 : -1);
        setLot({...tempLot});
    }

    const columns = ['Date', 'Ticket #', 'Comments', 'Edited By'];

    if(!lot){
        return <div></div>
    }

    return (
        <BlueStakeContainer>
            <div className='blue-stake-header'>
                <Button className="close-blue-stake-popup" handleKeyPress={((e) => {if(e.key === 'Enter'){closeBlueStakePopup()}})} handleClick={((e) => closeBlueStakePopup())} buttonText="Save and Close" width="140"/>
                <div className='blue-stake-header-title'>Blue Stake</div>
            </div>
            <div className='blue-stake-grid-table'>
                <div className='blue-stake-grid-table-header'>
                    {columns.map((column, index)=> {
                        return <div key={index} className='blue-stake-grid-table-header-data'>{column}</div>
                    })}
                </div>
                {lot.bluestake.map((bs, index) => {
                    const editedBy = bs.user_id !== null ? users.find(user => parseInt(user.id) === parseInt(bs.user_id)) : null;
                    if(canCreateBlueStake && !bs.closed){
                        return (
                            <div key={`${index}`} className='blue-stake-grid-table-row'>
                                <div className='blue-stake-grid-table-row-data'>{bs.date ? Globals.formatDateToDisplay(bs.date) : ''}</div>
                                <div className='blue-stake-grid-table-row-data text-input'>
                                <Textbox className="ticket" name="ticket" placeholder="Ticket #" value={bs.ticket} handleTextbox={((e) => ticketInput(e.target.value, index))} margin="0" columns="10" inputStart="2" inputEnd="10"/>
                                </div>
                                <div className='blue-stake-grid-table-row-data text-input'>
                                <Textbox className="comment" name="comment" placeholder="Enter comment" value={bs.comment} handleTextbox={((e) => commentInput(e.target.value, index))} margin="0" columns="10" inputStart="2" inputEnd="10"/>
                                </div>
                                <div className='blue-stake-grid-table-row-data'>{editedBy !== null && editedBy !== undefined ? `${editedBy.firstname} ${editedBy.lastname}` : ''}</div>
                            </div>
                        )
                    }

                    return (
                        <div key={`${index}`} className='blue-stake-grid-table-row'>
                            <div className='blue-stake-grid-table-row-data'>{bs.date ? Globals.formatDateToDisplay(bs.date) : ''}</div>
                            <div className='blue-stake-grid-table-row-data'>{bs.ticket}</div>
                            <div className='blue-stake-grid-table-row-data'>{bs.comment}</div>
                            <div className='blue-stake-grid-table-row-data'>{editedBy !== null && editedBy !== undefined ? `${editedBy.firstname} ${editedBy.lastname}` : ''}</div>
                        </div>
                    )
                })}
            </div> 
            <div className='add-blue-stake'>
            <Button className="add-blue-stake-line-item" handleKeyPress={((e) => {if(e.key === 'Enter'){addBlueStakeLineItem()}})} handleClick={((e) => addBlueStakeLineItem())} buttonText="+" width="40"/>
            </div>
            {/* {errorNoTicketNumber && <Popup color={PopupWarningColor} message={'Please add the ticket number'} handlePopup={toggleWarnings}/>} */}
            {errorNoTicketNumber && <PopupOk color={PopupOkWarningColor} message={`Ticket number missing. Saving will remove this line item. Do you wish to continue?`} handlePopup={handleNoTicketNumber}/>}

        </BlueStakeContainer>
    );
};

export default BlueStake;
