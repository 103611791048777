import styled from 'styled-components';

const HomeScreenContainer = styled.div`

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    row-gap: 10px;
    column-gap: 10px;
    margin-left: 10px;
    
    .messaging{
        grid-column: 1/11;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;

        .main-header-message{
            font-size: 20px;

            :nth-child(1){grid-column: 1/2;}
            :nth-child(2){grid-column: 2/3;}
        }

        .reg-message{
            padding: 5px;
            color: red;
            background: rgb(210, 200, 190);
            border: 4px solid rgb(58, 64, 67);
        }

        .dev-message{
            padding: 5px;
            color: rgb(200, 50, 50);
            background: rgb(255, 255, 100);
            border: 4px dashed black;
        }
    }

    .links-box{
        grid-column: 1/3;
    }

    .info-box{
        grid-column: 3/11;
    }

    .quick-link-box {
        margin-bottom: 10px;
    }

    .stats-box {
        margin-bottom: 10px;
    }

    .specialty-link-header, .quick-link-header, .stats-header, .weather-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .specialty-link-body, .quick-link-body, .stats-body, .weather-body {
        background: rgb(210, 200, 190);
    }

    .specialty-link-body, .quick-link-body {
        display: grid;
        padding: 10px 0 15px 20px;
    }

    .specialty-link-link, .quick-link-link {
        color: rgb(0, 0, 200); // hyper-link color
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
        padding: 3px 0;
    }

    .stats{
        display: grid;
        justify-content: center;
        padding: 10px 0;
        border-bottom: 10px solid white;

        .stat-row{
            display: grid;
            grid-template-columns: 100px 100px 100px 100px 
                                   100px 100px 100px 100px 100px;

            border-bottom: 2px solid black;

            .stat-row-data{
                display: grid;
                align-self: center;
                text-align: center;
                padding: 5px;
            }
        }
    }

    .weather-body {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }

    .weather {
        padding: 10px 0;
    }

    .date {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding: 5px 0;
    }

    .icon {
        display: grid;
        padding: 5px;

        img {
            margin: 0 auto;
            // border-radius: 50%;
        }
    }

    .description {
        text-align: center;
        height: 80px;
        padding: 10px 0 5px;
        font-size: 18px;
        font-weight: bold;
    }

    .temperature-box {
        display: grid;
        grid-template-rows: 1fr 1fr;
        padding: 15px 0 10px;
    }

    .temperature {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        padding: 1px 0;
    }

    .temp-label {
        grid-column: 1/12;
        text-align: right;
    }

    .temp-value {
        grid-column: 13/21;
    }
`;

export default HomeScreenContainer;