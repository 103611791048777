import styled from 'styled-components';

const LoadLotsContainer = styled.div`
    display: grid;
    margin: 10px 0 0 10px;
    width: 800px;

    .drag-n-drop-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
    }

    .drop-area {
        height: 150px;
        background: rgb(210, 200, 190);
        border-left: 5px dashed rgb(128, 128, 128); 
        border-bottom: 5px dashed rgb(128, 128, 128); 
        border-right: 5px dashed rgb(128, 128, 128);
        display: grid;
        align-items: center;
        text-align: center;
        font-size: 30px;
    }

    .drop-area.hovering {
        border-left: 5px dashed rgb(220, 220, 220); 
        border-bottom: 5px dashed rgb(220, 220, 220); 
        border-right: 5px dashed rgb(220, 220, 220);
    }

    .keys-box {
        display: grid;
        margin-top: 10px;
    }

    .main-header, .sub-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .sub-header{
        background: rgb(128, 128, 128);
    }

    .keys {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        background: rgb(210, 200, 190);
        padding: 10px 0;
    }

    .key {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        grid-template-rows: 1fr;
        padding: 5px 0;
    }

    .key-yellow, .key-orange, .key-red {
        width: 15px;
        height: 15px;
        align-self: center;
        justify-self: start;
        grid-column: 2/3;
    }

    .key-yellow { background: yellow; }
    .key-orange{ background: orange; }
    .key-red { background: red; }

    .key-text {
        grid-column: 3/21;
    }

    .key-label {
        font-weight: bold;
    }

    .table-header {
        font-size: 18px;
        padding: 3px 0;
        text-align: center;
        background: rgb(58, 64, 67);
        color: white;
        
        border-bottom: 3px solid white;
        border-top: 3px solid white;
    }

    .grid-table {
        display: grid;
        border-right: 2px solid black;   // ADD THIS TO GET LOCKING HEADER
        border-bottom: 2px solid black;  // ADD THIS TO GET LOCKING HEADER
        border-left: 2px solid black;    // ADD THIS TO GET LOCKING HEADER
    }

    .grid-table-row, .grid-table-header {
        display: grid;
        text-align: center;
        grid-template-columns: 1fr 1fr 1fr 4fr 1fr;
    }

    .grid-table-header {
        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        z-index: 5;
        border-top: 3px solid black;
        // ********************************
        background: rgb(210, 200, 190); // table header color
    }

    .grid-table-row:nth-child(even) {
        background: rgb(230, 230, 220); // table row color
    }

    .grid-table-row:nth-child(odd) {
        background: white;
    }

    .grid-table-row.red {
        background: red;
    }

    .grid-table-header-data, .grid-table-row-data {
        display: grid;
        align-items: center;
        padding: 3px 0;
        border: 1px solid black;
    }

    .grid-table-header-data {
        padding: 5px 0;
    }

    .img-spinner {
        justify-self: center;
    }

    .create-lot-button-box{
        display: grid;
        justify-content: center;
        align-items: center;
        height: 50px;
        background: rgb(210, 200, 190);
        border-top: 2px solid white;
    }
`

export default LoadLotsContainer;