

class Links {

    static quickLinks = [
        {display: 'Builders Page', navigation: '/builders-page'},
        {display: 'Jobs Page', navigation: '/jobs-page'},
        {display: 'View Lots', navigation: '/lots/view'},
        {display: 'View Even Flow - Lots', navigation: '/lots/evenflow'},
    ]

    static specialtyLinks = [
        {display: 'View Daily Schedules', navigation: '/daily-schedule'},
        {display: 'Field Utilities', navigation: '/field-forms'},
        {display: 'Starts Lots', navigation: '/lots/starts'},
    ]
}


export default Links;