import {useState} from 'react';
import Auth from '../authentication/Auth.js';

import Lots from '../../classes/Lots.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { Button } from '../utilities/Button.js';
import ViewLotsEditLotContainer from './styles/ViewLotsEditLotContainer';
import Popup, { PopupSuccessColor, PopupFailureColor } from '../utilities/Popup.js';

const ViewLotsEditLot = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [user, setUser] = useState('');

    const [lot, setLot] = useState({});

    const [errorNoLotNumber, setErrorNoLotNumber] = useState(false);
    const [errorDuplicateLotNumber, setErrorDuplicateLotNumber] = useState(false);

    const [editLotSuccess, setEditLotSuccess] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);
        setUser(await Auth.getUserId());
        setLot(() => props.lot);
    }

    if(!didLoadData){
        loadData();
    }

    const handleLotErrors = (errorCode) => {
        if(errorCode === "ERROR_NO_LOT_NUMBER"){ setErrorNoLotNumber(true); return;}
        if(errorCode === "ERROR_DUPLICATE_LOT_NUMBER"){ setErrorDuplicateLotNumber(true); return;}
    }

    const saveLot = async (saveLot) => {
        if(saveLot){
            const result = await Lots.checkLotForErrors(lot);
            if(result !== "SUCCESS"){
                handleLotErrors(result);
                return;
            }

            await Lots.updateLot(lot, user);
        }

        setEditLotSuccess(true);
    }

    const exitEditLot = (didDelete, didCancel = false) => {
        props.closeEditLot(lot, didDelete, didCancel);
    }
    
    const resetErrorPopups = () => {
        setErrorNoLotNumber(false);
        setErrorDuplicateLotNumber(false);
    }

    return (
        <ViewLotsEditLotContainer>
            <div className='edit-lot-header'>Edit Job</div>
            <TextboxWithLabel className="lot-number" name="lot-number" label="Lot Number" placeholder="Lot Number" value={lot.number} handleTextbox={((e) => setLot({...lot, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
            <TextboxWithLabel className="phase-id" name="phase-id" label="Phase ID" placeholder="Phase ID" value={lot.phase} handleTextbox={((e) => setLot({...lot, phase: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
            <TextboxWithLabel className="address" name="address" label="Address" placeholder="Address" value={lot.address} handleTextbox={((e) => setLot({...lot, address: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
            <TextboxWithLabel className="plan-id" name="plan-id" label="Plan ID" placeholder="Plan ID" value={lot.plan_number} handleTextbox={((e) => setLot({...lot, plan_number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
            <CheckboxWithLabel className="is-active" name="is-active" label="Is Active" checked={lot.active} handleCheckbox={((e) => setLot({...lot, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
            <CheckboxWithLabel className="is-pre-release" name="is-pre-release" label="Allow Pre-Release" checked={lot.allow_schedule_no_release} handleCheckbox={((e) => setLot({...lot, allow_schedule_no_release: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
            <CheckboxWithLabel className="is-multi-schedule" name="is-multi-schedule" label="Can Multi-Schedule" checked={lot.all_tasks_multi_schedule} handleCheckbox={((e) => setLot({...lot, all_tasks_multi_schedule: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
            <div className="two-button-box">
                <Button className="submit-edit-lot" handleKeyPress={((e) => {if(e.key === 'Enter'){saveLot(true)}})} handleClick={((e) => saveLot(true))} buttonText="Submit" width="120"/>
                <Button className="cancel-edit-lot" handleKeyPress={((e) => {if(e.key === 'Enter'){exitEditLot(false, true)}})} handleClick={((e) => exitEditLot(false, true))} buttonText="Cancel" width="120"/>
            </div>
            {errorNoLotNumber && <Popup color={PopupFailureColor} message={"Please enter a lot number."} handlePopup={resetErrorPopups}/>}
            {errorDuplicateLotNumber && <Popup color={PopupFailureColor} message={"Please enter a unique lot number"} handlePopup={resetErrorPopups}/>}
            {editLotSuccess && <Popup color={PopupSuccessColor} message={'The lot has been updated and saved to the database'} handlePopup={() => exitEditLot(false)}/>}
        </ViewLotsEditLotContainer>
    );
};

export default ViewLotsEditLot;
