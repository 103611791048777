import Auth from "../components/authentication/Auth";
import moment from "moment/moment";
import dayjs from "dayjs";

// Employee data from database goal
// {
//     _id: "",
//     firstName: "",
//     lastName: "",
//     active: true,
//     email: "",
//     phoneNumber: "",
//     quickBooksId: "",
//     isSVC: false,
//     isSVP: false,
//     isSub: false,
//     company: "",
//     isSubPlumbing: false,
//     isSubConcrete: false,
//     crewNumbers: "",
//     editedBy: {
//         name: "",
//         date: new Date()
//     }
// }

class Employees{
    static async loadEmployees(queryObj){
        let data = [];
            let dbData;
            if(queryObj){
                const jsonString = JSON.stringify(queryObj);
                dbData = await Auth.hitEndpoint("GET", "EMPLOYEES", `jsonString=${jsonString}`);
            }
            else{
                dbData = await Auth.hitEndpoint("GET", "EMPLOYEES");
            }
            data = [...data, ...dbData];

        return data;
    }

    static async loadEmployeesForDropDown(queryObj){
        let data = await Employees.loadEmployees(queryObj);

        data.forEach(employee => {
            employee.display = employee.crews !== null && employee.crews !== "" ? `${employee.crews} - ` : "";
            employee.display += employee.alias !== null && employee.alias !== '' ? `${employee.alias} - (${employee.first_name} ${employee.last_name})` : `${employee.first_name} ${employee.last_name}`;
            employee.editedBy = {
                date: new Date(employee.modified_at),
                editedBy: employee.modified_by
            }
            employee.value = employee.id;
            employee.label = employee.alias !== null && employee.alias !== '' ? `${employee.alias} - (${employee.first_name} ${employee.last_name})` : `${employee.first_name} ${employee.last_name}`
        })

        data.sort((e1, e2) => {
            const e1CN = e1.crews !== null && e1.crews !== "" ? Number.parseInt(e1.crews.split(", ").sort((n1, n2) => Number.parseInt(n1) < Number.parseInt(n2) ? -1 : 1)[0]) : 0;
            const e2CN = e2.crews !== null && e2.crews !== "" ? Number.parseInt(e2.crews.split(", ").sort((n1, n2) => Number.parseInt(n1) < Number.parseInt(n2) ? -1 : 1)[0]) : 0;

            if(e1CN && e2CN){
                return e1CN < e2CN ? -1 : 1
            }
            else if(e1CN){
                return -1
            }
            else if(e2CN){
                return 1;
            }

            if(e1.first_name === e2.first_name){
                return e1.last_name < e2.last_name ? -1 : 1;
            }

            return e1.first_name < e2.first_name ? -1 : 1;
        })

        return data;
    }

    static convertEmployeesToDropDown(employees){
        return employees.map(emp => {
            emp.display = emp.crews !== null && emp.crews !== "" ? `${emp.crews} - ` : "";
            emp.display += emp.alias !== null && emp.alias !== '' ? `${emp.alias} - (${emp.first_name} ${emp.last_name})` : `${emp.first_name} ${emp.last_name}`
            return emp;
        }).sort((e1, e2) => {
            const e1CN = e1.crews !== null && e1.crews !== "" ? Number.parseInt(e1.crews.split(", ").sort((n1, n2) => Number.parseInt(n1) < Number.parseInt(n2) ? -1 : 1)[0]) : 0;
            const e2CN = e2.crews !== null && e2.crews !== "" ? Number.parseInt(e2.crews.split(", ").sort((n1, n2) => Number.parseInt(n1) < Number.parseInt(n2) ? -1 : 1)[0]) : 0;

            if(e1CN && e2CN){
                return e1CN < e2CN ? -1 : 1
            }
            else if(e1CN){
                return -1
            }
            else if(e2CN){
                return 1;
            }

            if(e1.first_name === e2.first_name){
                return e1.last_name < e2.last_name ? -1 : 1;
            }

            return e1.first_name < e2.first_name ? -1 : 1;
        })
    }

    static createEmployeeDBObject(employee){
        const dbObject = {};

        if(employee.active !== undefined){ dbObject.active = employee.active; }
        if(employee.remove_from_crew !== undefined){ dbObject.remove_from_crew = employee.remove_from_crew; }
        if(employee.cdl !== undefined){ dbObject.cdl = employee.cdl; }
        if(employee.comments !== undefined){ dbObject.comments = employee.comments; }
        if(employee.company !== undefined){ dbObject.company = employee.company; }
        if(employee.crews !== undefined){ dbObject.crews = employee.crews; }
        if(employee.driver_license !== undefined){ dbObject.driver_license = employee.driver_license; }
        if(employee.email !== undefined){ dbObject.email = employee.email; }
        if(employee.first_name !== undefined){ dbObject.first_name = employee.first_name; }
        if(employee.hire_date !== undefined){ dbObject.hire_date = employee.hire_date && employee.hire_date !== null ? moment(new Date(employee.hire_date)).format('YYYY-MM-DD H:mm:ss') : null; }
        if(employee.id !== undefined){ dbObject.id = employee.id; }
        if(employee.inquiry_date !== undefined){ dbObject.inquiry_date = employee.inquiry_date && employee.inquiry_date !== null ? moment(new Date(employee.inquiry_date)).format('YYYY-MM-DD H:mm:ss') : null; }
        if(employee.last_name !== undefined){ dbObject.last_name = employee.last_name; }
        if(employee.modified_at !== undefined){ dbObject.modified_at = moment(new Date(employee.modified_at)).format('YYYY-MM-DD H:mm:ss'); }
        if(employee.modified_by !== undefined){ dbObject.modified_by = employee.modified_by; }
        if(employee.phone_number !== undefined){ dbObject.phone_number = employee.phone_number; }
        if(employee.qb_id !== undefined){ dbObject.qb_id = employee.qb_id; }
        if(employee.referred_by !== undefined){ dbObject.referred_by = employee.referred_by; }
        if(employee.sub !== undefined){ dbObject.sub = employee.sub; }
        if(employee.sub_concrete !== undefined){ dbObject.sub_concrete = employee.sub_concrete; }
        if(employee.sub_plumbing !== undefined){ dbObject.sub_plumbing = employee.sub_plumbing; }
        if(employee.sub_framing !== undefined){ dbObject.sub_framing = employee.sub_framing; }
        if(employee.svc !== undefined){ dbObject.svc = employee.svc; }
        if(employee.svp !== undefined){ dbObject.svp = employee.svp; }
        if(employee.svf !== undefined){ dbObject.svf = employee.svf; }
        if(employee.version !== undefined){ dbObject.version = employee.version; }

        return dbObject;
    }

    static async saveNewEmployee(employee){
        employee.modified_by = await Auth.getUserId();
        employee.modified_at = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
        return await Auth.hitEndpointNew("POST", "employee", "", employee);
    }

    static async updateEmployee(employee, user){
        employee.modified_by = await Auth.getUserId();
        employee.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');

        await Auth.hitEndpointNew("PATCH", "employee", "", employee);

        return employee;
    }

    static async deleteEmployee(employee){
        await Auth.hitEndpoint("DELETE", "EMPLOYEES", "", employee);
        return employee;
    }

    static sortEmployees(sortBy, order, employees){
        const firstValue = order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        return employees.sort((e1, e2) => {
            if(sortBy === "firstName"){
                if(e1.first_name === e2.first_name){
                    return e1.last_name < e2.last_name ? firstValue : secondValue;
                }

                return e1.first_name < e2.first_name ? firstValue : secondValue;
            }

            return firstValue;
        })
    }

    static createNewEmployee(user){
        return {
            first_name: "",
            last_name: "",
            active: 1,
            email: "",
            phone_number: "",
            qb_id: "",
            svc: false,
            svp: false,
            svf: false,
            sub: false,
            company: "",
            sub_concrete: false,
            sub_plumbing: false,
            sub_framing: false,
            crews: "",
            modified_by: user,
            modified_at: new Date()
        };
    }

    static async checkEmployeeForErrors(employee){
        if(employee.first_name === ''){return "ERROR_NO_FIRST_NAME";}
        if(employee.last_name === ''){return "ERROR_NO_LAST_NAME";}
        if(employee.svc || employee.svp || employee.svf){
            if(employee.qb_id === ''){return "ERROR_NO_QUICKBOOKS_ID";}
        }
        if(!(employee.svc || employee.svp || employee.svf || employee.sub)){return "ERROR_NO_COMPANY";}
        if(employee.sub && employee.company === ''){return "ERROR_NO_SUB_COMPANY";}
        if(!employee.sub && employee.company !== ''){return "ERROR_COMPANY_WITHOUT_SUB_CHECKED";}
        if(employee.sub && !(employee.sub_concrete || employee.sub_plumbing || employee.sub_framing)){return "ERROR_SUB_WORK_NOT_DEFINED";}

        // Check for duplicate names
        let jsonString = JSON.stringify({first_name: employee.first_name, last_name: employee.last_name});
        let dupData = await Auth.hitEndpoint("GET", "EMPLOYEES", `jsonString=${jsonString}`);
        if(employee.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id !== employee.id);
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_NAME";}

        if(employee.svc || employee.svp || employee.svf){
            // Check for duplicate QuickBooks ID
            jsonString = JSON.stringify({qb_id: employee.qb_id});
            dupData = await Auth.hitEndpoint("GET", "EMPLOYEES", `jsonString=${jsonString}`);
            if(employee.id){
                dupData = dupData.filter(dataToFilter => dataToFilter.id !== employee.id);
            }
            if(dupData.length > 0){return "ERROR_DUPLICATE_QUICK_BOOKS_ID";}
        }

        return "SUCCESS";
    }

    static addCrewNumber(number, employee){
        let crewNumbers;
        if(employee.crews !== null && employee.crews !== ""){
            const crewNumbersArray = employee.crews.split(", ").filter(crewNumberToFilter => crewNumberToFilter !== number)
            crewNumbersArray.push(number);
            crewNumbers = crewNumbersArray.join(", ");
        }
        else{
            crewNumbers = number;
        }

        employee.crews = crewNumbers;
        return employee;
    }

    static removeCrewNumber(number, employee){
        let crewNumbers = employee.crews.split(", ");
        const newCrewNumbers = crewNumbers.filter(crewNumberToFilter => parseInt(crewNumberToFilter) !== parseInt(number));
        employee.crews = newCrewNumbers.join(", ");
        return employee;
    }

    static getNameWithAlias(employee){
        if(employee.alias === undefined || employee.alias === null || employee.alias === ''){
            return employee.first_name + ' ' + employee.last_name
        }
        else{
            return employee.alias + ' ' + employee.first_name + ' ' + employee.last_name
        }
    }
}

export default Employees;
