import {useState} from 'react';
import dayjs from "dayjs";

import Auth from '../../authentication/Auth.js';
import ReporterContainer from '../styles/ReporterContainer';

import Button  from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '15mm',
        backgroundColor: 'white',
        width: '280mm',
        height: '216mm'
      },
    companyInfo: {
        margin: '7mm auto 0',
        fontSize: '10mm',
        width: '43%',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        borderBottom: '1mm solid black'
    },
    docTitle: {
        margin: '3mm auto 0',
        padding: '2mm 0',
        fontSize: '8mm',
        width: '40%',
        textAlign: 'center',
        fontFamily: 'Helvetica',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '170mm',
        width: '100%',
        fontSize: '3mm',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2pt',
    },
    columns: {
        marginLeft: '.5mm',
        marginRight: '.5mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        fontFamily: 'Helvetica',
        fontSize: '3mm',
        textAlign: 'center',
    },
    dataColumns: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    label1: {
        textAlign: 'right',
        width: '20mm',
        padding: '.5mm 1mm .5mm 0'
    },
    data1: {
        textAlign: 'left',
        width: '60mm',
        padding: '.5mm 0'
    },
    label2: {
        textAlign: 'right',
        width: '20mm',
        padding: '.5mm 1mm .5mm 0'
    },
    data2: {
        textAlign: 'left',
        width: '30mm',
        padding: '.5mm 0'
    },
    label3: {
        textAlign: 'right',
        width: '20mm',
        padding: '.5mm 1mm .5mm 0'
    },
    data3: {
        textAlign: 'left',
        width: '20mm',
        padding: '.5mm 0'
    }
});

const PourLogReport = () => {
    const [pourLogs, setPourLogs] = useState([]);

    const [loadParameters, setLoadParameters] = useState({
        completedStartDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0)
            .subtract(dayjs().set('hour', 0).set('minute', 0).set('second', 0).get('day') + 1, 'day'),
        completedEndDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0)
            .add(6 - dayjs().set('hour', 0).set('minute', 0).set('second', 0).get('day'), 'day'),
        invoiced: false,
    })

    const [buttonText, setButtonText] = useState('Create Pour Log Report');

    const createPourLogReport = async () => {
        setButtonText('Fetching Report...');

        let whereStr = 'jsonString=phase_task_detail.detail_type_id = 1';
        if(loadParameters.completedStartDate !== null){
            whereStr += ` and schedule.schedule_date >= '${dayjs(new Date(loadParameters.completedStartDate)).format('YYYY-MM-DD')}'`;
        }
        if(loadParameters.completedEndDate !== null){
            whereStr += ` and schedule.schedule_date < '${dayjs(new Date(loadParameters.completedEndDate)).format('YYYY-MM-DD')}'`;
        }
        if(!loadParameters.invoiced){
            whereStr += ' and schedule_detail.invoice_value is null';
        }
        const result = await Auth.hitEndpointNew("GET", `schedule/detail/reconcile`, whereStr);

        setPourLogs(result);

        setButtonText('Create Pour Log Report');
    }

    return (
        <ReporterContainer>
            <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                <div style={{marginBottom: "5px"}}>Completed Start Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, completedStartDate: new Date(date)})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {width: '100%', minWidth: '120px'},
                            },
                        }}
                        defaultValue={loadParameters.completedStartDate}
                    />
                </LocalizationProvider>
            </div>
            <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                <div style={{marginBottom: "5px"}}>Completed End Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, completedEndDate: new Date(date)})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {width: '100%', minWidth: '120px'},
                            },
                        }}
                        defaultValue={loadParameters.completedEndDate}
                    />
                </LocalizationProvider>
            </div>
            <FormControlLabel 
                sx={{
                    justifySelf: 'center',
                }}
                control={<Checkbox 
                    defaultChecked={loadParameters.invoiced} 
                    onChange={(e) => setLoadParameters({...loadParameters, invoiced: e.target.checked})}/>} 
                label="Show Invoiced" 
            />
            <Button 
                sx={{
                    width: '300px',
                    justifySelf: 'center',
                }}
                onClick={() => createPourLogReport()}
                variant='contained'
            >{buttonText}</Button>

            <PDFViewer style={styles.viewer}>
                <Document title="Pour Log Report">
                    <Page size="LETTER" orientation="landscape" style={styles.page} wrap>
                        <View style={styles.companyInfo} fixed>
                            <Text>Sierra Verde Companies</Text>
                        </View>
                        <View style={styles.docTitle} fixed>
                            <Text>Pour Log Report</Text>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        <View style={styles.tableHeader} fixed>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '80mm'}]}>Lot Information</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '50mm'}]}>Phase Task</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '50mm'}]}>Details</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '40mm'}]}>Vender</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '40mm'}]}>Builder</Text>
                        </View>
                        <View wrap>
                            {pourLogs.map((p, index) => {
                                const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';

                                const orderNumberDetail = p.detail.find(d => parseInt(d.phase_task_detail_id) === 5);
                                const timeDetail = p.detail.find(d => parseInt(d.phase_task_detail_id) === 4);
                                const supplierDetail = p.detail.find(d => parseInt(d.phase_task_detail_id) === 2);
                                const pumpDetail = p.detail.find(d => parseInt(d.phase_task_detail_id) === 3);
                                const yardsDetail = p.detail.find(d => parseInt(d.phase_task_detail_id) === 1);

                                const builder = p.lot.job.builder.name;
                                const job = `${p.lot.job.number} - ${p.lot.job.project_name}`;
                                const lot = p.lot.phase ? `${p.lot.number} - ${p.lot.phase}` :  p.lot.number;
                                const phaseCode = p.task.code;
                                const phaseDescription = p.task.description;
                                const orderNumber = orderNumberDetail && orderNumberDetail.schedule_value ? orderNumberDetail.schedule_value : '';
                                const time = timeDetail && timeDetail.schedule_value ? timeDetail.schedule_value : '';
                                const supplier = supplierDetail && supplierDetail.supplier && supplierDetail.supplier.name ? supplierDetail.supplier.name  : '';
                                const pump = pumpDetail && pumpDetail.supplier && pumpDetail.supplier.name ? pumpDetail.supplier.name : '';
                                const yards = yardsDetail && yardsDetail.schedule_value ? yardsDetail.schedule_value : '';
                                const completedDate = p.schedule_date !== null ? dayjs(new Date(p.schedule_date)).format('MM-DD-YYYY') : null;
                                const venderInvoiceNumber = yardsDetail && yardsDetail.vender_invoice_number ? yardsDetail.vender_invoice_number : '';
                                const venderInvoiceDate = yardsDetail && yardsDetail.vender_invoice_date ? dayjs(new Date(yardsDetail.vender_invoice_date)).format('MM-DD-YYYY') : '';
                                const venderInvoiceYards = yardsDetail && yardsDetail.vender_invoice_value ? yardsDetail.vender_invoice_value : '';
                                const venderInvoiceComplete = yardsDetail && yardsDetail.vender_invoice_complete ? 'X' : '';
                                const builderInvoiceNumber = yardsDetail && yardsDetail.builder_invoice_number ? yardsDetail.builder_invoice_number : '';
                                const builderInvoiceComplete = yardsDetail && yardsDetail.builder_billed ? 'X' : '';

                                return (
                                    <View key={`${p.id}-${index}`} style={styles.tableRow} wrap={false}>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '80mm'}]}>
                                            <Text style={[styles.label1]}>Builder:</Text>
                                            <Text style={[styles.data1]}>{builder}</Text>
                                            <Text style={[styles.label1]}>Job:</Text>
                                            <Text style={[styles.data1]}>{job}</Text>
                                            <Text style={[styles.label1]}>Lot:</Text>
                                            <Text style={[styles.data1]}>{lot}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '50mm'}]}>
                                            <Text style={[styles.label2]}>Phase Code:</Text>
                                            <Text style={[styles.data2]}>{phaseCode}</Text>
                                            <Text style={[styles.label2]}>Description:</Text>
                                            <Text style={[styles.data2]}>{phaseDescription}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '50mm'}]}>
                                            <Text style={[styles.label2]}>Order #:</Text>
                                            <Text style={[styles.data2]}>{orderNumber}</Text>
                                            <Text style={[styles.label2]}>Time:</Text>
                                            <Text style={[styles.data2]}>{time}</Text>
                                            <Text style={[styles.label2]}>Supplier:</Text>
                                            <Text style={[styles.data2]}>{supplier}</Text>
                                            <Text style={[styles.label2]}>Pump:</Text>
                                            <Text style={[styles.data2]}>{pump}</Text>
                                            <Text style={[styles.label2]}>Yards:</Text>
                                            <Text style={[styles.data2]}>{yards}</Text>
                                            <Text style={[styles.label2]}>Completed:</Text>
                                            <Text style={[styles.data2]}>{completedDate}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '40mm'}]}>
                                            <Text style={[styles.label3]}>Invoice #:</Text>
                                            <Text style={[styles.data3]}>{venderInvoiceNumber}</Text>
                                            <Text style={[styles.label3]}>Date:</Text>
                                            <Text style={[styles.data3]}>{venderInvoiceDate}</Text>
                                            <Text style={[styles.label3]}>Yards:</Text>
                                            <Text style={[styles.data3]}>{venderInvoiceYards}</Text>
                                            <Text style={[styles.label3]}>Complete:</Text>
                                            <Text style={[styles.data3]}>{venderInvoiceComplete}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '40mm'}]}>
                                            <Text style={[styles.label3]}>Invoice #:</Text>
                                            <Text style={[styles.data3]}>{builderInvoiceNumber}</Text>
                                            <Text style={[styles.label3]}>Billed:</Text>
                                            <Text style={[styles.data3]}>{builderInvoiceComplete}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>

        </ReporterContainer>
    );
}

export default PourLogReport;