
// TODO This component needs a rewrite
import { useState } from 'react';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { CheckboxWithLabel } from '../utilities/Checkbox.js';
import { Button } from '../utilities/Button.js';
import MainHeader from '../utilities/MainHeader.js';
import Popup, {PopupFailureColor, PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import CreateLotContainer from './styles/CreateLotContainer.js';

const CreateLot = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);

    const [newLot, setNewLot] = useState(null);

    const [errorJobNotSelected, setErrorJobNotSelected] = useState(false);
    const [errorNoLotNumber, setErrorNoLotNumber] = useState(false);
    const [errorRepeatLotNumber, setErrorRepeatLotNumber] = useState(false);
    const [success, setSuccess] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);

        setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, Builders.sortBuilders("name", "ascending", await Builders.loadBuilders())));
        setNewLot(Lots.createNewLot())
    }

    if(!didLoadData){
        loadData();
    }

    const createLot = async () => {
        if(newLot.job_id === ''){setErrorJobNotSelected(true); return;}
        if(newLot.number === ''){setErrorNoLotNumber(true); return;}
        const dupLots = await Lots.loadLots({job_id: newLot.job_id, "lot.number": newLot.number, phase: newLot.phase});
        if(dupLots.length > 0){setErrorRepeatLotNumber(true); return;}

        await Lots.saveNewLot(newLot);
        setSuccess(true);
    }

    const reset = () => {
        setNewLot(Lots.createNewLot());
        setSuccess(false);
    }

    const toggleWarnings = (e) => {
        setErrorJobNotSelected(false);
        setErrorNoLotNumber(false);
        setErrorRepeatLotNumber(false);
    }

    return (
        <CreateLotContainer>
            <MainHeader/>
            <div className='create-lot-header'>Create Lot</div>
            <div className='sub-header'>Lot Details</div>
            {newLot &&
            <div className='lot-details-box'>
                <DropDownWithLabel className="select-job" id="select-job-id" name="job" label="Select Job" value={newLot.job_id} handleDropDown={((e) => setNewLot({...newLot, job_id: e.target.value}))} addBlankOption={true} selectionList={jobsForDropDowns} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <TextboxWithLabel className="lot-number" name="lot-number" label="Lot Number" placeholder="Lot Number" value={newLot.number} handleTextbox={((e) => setNewLot({...newLot, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <TextboxWithLabel className="phase" name="phase" label="Phase" placeholder="Phase" value={newLot.phase} handleTextbox={((e) => setNewLot({...newLot, phase: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <TextboxWithLabel className="address" name="address" label="Address" placeholder="Address" value={newLot.address} handleTextbox={((e) => setNewLot({...newLot, address: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <TextboxWithLabel className="plan-number" name="plan-number" label="Plan Number" placeholder="Plan Number" value={newLot.plan_number} handleTextbox={((e) => setNewLot({...newLot, plan_number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <CheckboxWithLabel className="is-active" name="is-active" label="Is Active" checked={newLot.active} handleCheckbox={((e) => setNewLot({...newLot, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <CheckboxWithLabel className="is-pre-release" name="is-pre-release" label="Allow Pre-Release" checked={newLot.allow_schedule_no_release} handleCheckbox={((e) => setNewLot({...newLot, allow_schedule_no_release: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <CheckboxWithLabel className="is-multi-schedule" name="is-multi-schedule" label="Can Multi-Schedule" checked={newLot.all_tasks_multi_schedule} handleCheckbox={((e) => setNewLot({...newLot, all_tasks_multi_schedule: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <Button className="create-lot" handleKeyPress={((e) => {if(e.key === 'Enter'){createLot()}})} handleClick={((e) => createLot())} buttonText="Create Lot" width="120"/>
            </div>}
            {errorJobNotSelected && <Popup color={PopupWarningColor} message={'Please select a job number'} handlePopup={toggleWarnings}/>}
            {errorNoLotNumber && <Popup color={PopupWarningColor} message={'Please enter a lot number'} handlePopup={toggleWarnings}/>}
            {errorRepeatLotNumber && <Popup color={PopupFailureColor} message={'Lot already exists for this job. Please enter a unique lot'} handlePopup={toggleWarnings}/>}
            {success && <Popup color={PopupSuccessColor} message={`Lot #${newLot.number} has been added.`} handlePopup={reset}/>}
        </CreateLotContainer>
    );
}

export default CreateLot;
