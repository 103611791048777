import {useMemo, useState} from "react";
import CrewContainer from "../styles/CrewContainer"
import CrewFilter from "./CrewFilter";
import CrewGrid from "./CrewGrid";
import {darken, lighten} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {createTheme} from "@mui/material/styles";

const ReassignCrew = ({selectedRows, crews, employees, setEmployees, selectedEmployeesReassign, setSelectedEmployeesReassign, empsToRemove, setEmpsToRemove, phase}) => {

    const gridColumns = useMemo(
        () => [
            {
                accessorFn: (row) => row.task.code !== null && row.task.code !== "" ? `${row.task.code} ${row.task.name}` : row.task.name,
                header: 'Task',
            },
            {
                accessorFn: (row) => `${row.lot.job.project_name} - ${row.lot.job.number}`,
                header: 'Tract',
            },
            {
                accessorFn: (row) => `${row.lot.phase} ${row.lot.number}`,
                header: 'Lot',
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
            },
        ],
        [],
    );

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'

    return (
        <CrewContainer>
            <CrewFilter
                crews={crews}
                employees={employees}
                setEmployees={setEmployees}
                setSelectedEmployeesEdit={setSelectedEmployeesReassign}
                selectedEmployeesEdit={selectedEmployeesReassign}
                phase={phase}
            />
            <CrewGrid
                setSelectedEmployeesEdit={setSelectedEmployeesReassign}
                selectedEmployeesEdit={selectedEmployeesReassign}
                empsToRemove={empsToRemove}
                setEmpsToRemove={setEmpsToRemove}
                isExisting={true}
                minHeight={"400px"}
                marginTop={"60px"}
            />
            <div>Tasks that will be reassigned</div>
            <MaterialReactTable
                id="schedule-table"
                columns={gridColumns}
                data={selectedRows}
                rowCount={selectedRows.length}
                enableGrouping
                enableExpanding={false}
                enableExpandAll={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableFilters={false}
                enableHiding={false}
                muiTableHeadCellProps={({column}) => ({
                    sx: {
                        '& .MuiTableSortLabel-icon': {
                            color: column.getIsSorted() ? 'limegreen !important' : undefined
                        },
                        backgroundColor: '#D3D3D3'
                    }
                })}
                muiTableBodyCellProps={({cell, row}) => ({
                    sx: {
                        height: 10,
                        fontStyle: 'italic',
                        border: 'none',
                        backgroundColor:
                            row.depth === 0 ? '#D3D3D3' :
                                row.original.assigned ? 'lightgreen' :
                                    row.original.group_count % 2 === 0 ?
                                        darken(baseBackgroundColor, 0.1) :
                                        lighten(baseBackgroundColor, 0.3)
                        ,
                        borderTop: cell.row.depth === 1 ? 'solid 1pt' : row.depth === 0 ? 'solid 2pt' : 'none',
                        borderLeft: cell.column.id === "mrt-row-actions" ? 'solid 1pt' : 'none',
                        borderRight: cell.column.id === "employee" ? 'solid 1pt' : 'none',
                        paddingBottom: "1px",
                        textDecoration: row.original.remove === 1 ? 'line-through' : 'none'
                    }
                })}
                initialState={{
                    showColumnFilters: false,
                    pagination: {pageSize: 100, pageIndex: 0},
                    density: 'compact',
                    expanded: true,
                }}
            />
        </CrewContainer>
    )
}

export default ReassignCrew;
