import {useState} from 'react';

import Lots from '../../classes/Lots.js';
import NotesIcon from '../../img/NotesIcon.png';
import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Utilities from '../../classes/Utilities'
import LotNotes from '../lots/LotNotes.js';
import Globals from '../utilities/Globals.js';
import Popup, {PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import ConcreteMeasurementsContainer from './styles/ConcreteMeasurementsContainer.js';
import Auth from "../authentication/Auth.js";
import moment from "moment";


// Add more forms here
const ConcreteMeasurements = (props) => {
    const [user, setUser] = useState('');

    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);

    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);
    const [concreteArrayIndex, setConcreteArrayIndex] = useState(-1);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [didLoadData, setDidLoadData] = useState(false);

    const [hasExistingData, setHasExistingData] = useState(false);
    const [displayExistingDataWarning, setDisplayExistingDataWarning] = useState(false);
    const [success, setSuccess] = useState(false);

    const [measurements, setMeasurements] = useState([])

    let totalSF = 0;

    const loadData = async () => {
        setUser(`${await Auth.getUserId()}`);

        setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));
        setMeasurements(await Utilities.loadMeasurements('1'))
        setDidLoadData(true);
    }

    if(!didLoadData){
        loadData();
    }

    const loadLots = async (jobId) => {
        setLots(Lots.sortLots("jobNumber", "ascending", await Lots.loadLots({jobId: jobId}, jobId), await Jobs.loadJobs(null)));
    }

    const selectJob = (e) => {
        loadLots(e.target.value);
        setSelectedLot(null)
    }

    const selectLot = async (e) => {
        // Get selected lot
        const tempSelectedLot = lots.find(lot => lot.id.toString() === e.target.value.toString());

        // Check if user has been save indicating prior data
        if(tempSelectedLot !== undefined && tempSelectedLot.measurements > 0){
            setHasExistingData(!(tempSelectedLot.measurements.concrete.user === ''));
            setDisplayExistingDataWarning(!(tempSelectedLot.measurements.concrete.user === ''));
        }
        else{
            setHasExistingData(false)
            setDisplayExistingDataWarning(false)
        }
        let tempIndex = tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 2);
        if(tempIndex < 0){
            tempSelectedLot.phases.push({
                lot_id: tempSelectedLot.id,
                modified_at: new Date(),
                modified_by: await Auth.getUserId(),
                phase_id: 2,
                release_date: null,
                start_status_id: null,
                starts_status: "",
            });
            tempIndex = tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 2);
        }
        setConcreteArrayIndex(tempIndex);
        setSelectedLot(tempSelectedLot);
    }

    const updateMeasurement = (type, measurement, target) => {
        if(measurement === '' || parseInt(measurement) === 0){
            return;
        }
        let phases = selectedLot.phases
        if(phases[concreteArrayIndex].starts_status === '' || phases[concreteArrayIndex].starts_status === null 
            || phases[concreteArrayIndex].starts_status === 'Need Measurements' || parseInt(phases[concreteArrayIndex].starts_status_id) === 2){
            phases[concreteArrayIndex].release_date = moment(new Date()).format('YYYY-MM-DD H:mm:ss');//(new Date()).toISOString();
            phases[concreteArrayIndex].start_status_id = 1;
            phases[concreteArrayIndex].starts_status = 'Received';
            phases[concreteArrayIndex].modified_by = user;
        }
        setSelectedLot(current => {
            return{
                ...current,
                measurements: {
                    ...current.measurements,
                    concrete: {
                        ...current.measurements.concrete,
                        [type]: Number.parseInt(measurement === '' ? 0 : measurement),
                        user: user,
                        date: moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                    }
                },
            }
        })
    }

    const updateLot = async () => {
        await Lots.updateLot(selectedLot, user);

        // Update lot in list of lots
        const index = lots.findIndex(lot => lot.id.toString() === selectedLot.id.toString());
        const tempLots = [...lots];
        tempLots[index] = selectedLot;
        setLots([...tempLots]);
    }

    const handleSubmitMeasurements = (e) => {
        updateLot();

        setSuccess(true);
        setHasExistingData(true);
    }

    const togglePopup = () => {
        setDisplayExistingDataWarning(false);
        setSuccess(false);
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = () => {
        setDisplayNotes(false);
    }


    if (selectedLot && selectedLot.measurements !== undefined && Object.keys(selectedLot.measurements).length > 0 && Object.keys(selectedLot.measurements.concrete).length > 0) {
        totalSF = Number.parseInt(selectedLot.measurements.concrete.driveway) +
            Number.parseInt(selectedLot.measurements.concrete.walkway) +
            Number.parseInt(selectedLot.measurements.concrete.entry) +
            Number.parseInt(selectedLot.measurements.concrete.approach) +
            Number.parseInt(selectedLot.measurements.concrete.patio) +
            Number.parseInt(selectedLot.measurements.concrete.courtyard);
    }


    // Check which concrete measuremnets to render
    const canCreateConcreteMeasurements = props.isAuthorized('concreteMeasurements', 'WRITE');
    const canEditConcreteMeasurements = props.isAuthorized('concreteMeasurements', 'WRITE'); 
    const inputConcreteMeasurements = (!hasExistingData && canCreateConcreteMeasurements) || (hasExistingData && canEditConcreteMeasurements);

    return (
        <ConcreteMeasurementsContainer>
            <div className='concrete-measurements-form-header'>Concrete Flatwork Measurements</div>
            <div className='concrete-measurements-job-sub-header'>Select Job & Lot</div>
            {/*<div className='concrete-measurements-job-drop-down-box'>*/}
            {/*    <label className="concrete-measurements-job-drop-down-label" htmlFor='concrete-measurements-job-form-select'>Job</label>*/}
            {/*    <Select className="concrete-measurements-job-drop-down-select" name='concrete-measurements-job-form-select'*/}
            {/*            onChange={selectJob}*/}
            {/*            options={jobsForDropDowns.map(job => {*/}
            {/*                return({*/}
            {/*                        value: job.id,*/}
            {/*                        label: job.display*/}
            {/*                    }*/}
            {/*                )*/}
            {/*            })}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className='concrete-measurements-lot-drop-down-box'>*/}
            {/*    <label className="concrete-measurements-lot-drop-down-label" htmlFor='concrete-measurements-lot-form-select'>Lot</label>*/}
            {/*    <Select className="concrete-measurements-lot-drop-down-select" name='concrete-measurements-lot-form-select'*/}
            {/*            onChange={selectLot}*/}
            {/*            options={lots.map(lot => {*/}
            {/*                const display = `${lot.number} - ${lot.phase} - ${lot.address}`;*/}
            {/*                return({*/}
            {/*                        value: lot.id,*/}
            {/*                        label: display*/}
            {/*                    }*/}
            {/*                )*/}
            {/*            })}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className='concrete-measurements-job-drop-down-box'>
                <label className="concrete-measurements-job-drop-down-label" htmlFor='concrete-measurements-job-form-select'>Job</label>
                <select className="concrete-measurements-job-drop-down-select" name='concrete-measurements-job-form-select' onChange={selectJob}>
                    <option className="concrete-measurements-job-drop-down-option" value=''></option>
                    {jobsForDropDowns.map(job => {
                        return (
                            <option key={job.id} className="concrete-measurements-job-drop-down-option" value={job.id}>{job.display}</option>
                        )
                    })}

                </select>
            </div>
            <div className='concrete-measurements-lot-drop-down-box'>
                <label className="concrete-measurements-lot-drop-down-label" htmlFor='concrete-measurements-lot-form-select'>Lot</label>
                <select className="concrete-measurements-lot-drop-down-select" name='concrete-measurements-lot-form-select' onChange={selectLot}>
                    <option className="concrete-measurements-lot-drop-down-option" value=''></option>
                    {lots.map(lot => {
                        const value = lot.id;
                        const display = `${lot.number} - ${lot.phase} - ${lot.address}`;
                        return (
                            <option key={value} className="concrete-measurements-lot-drop-down-option" value={value}>{display}</option>
                        )
                    })}

                </select>
            </div>
            <div className='measurements-sub-header'>Measurements</div>
            {selectedLot &&
            <div className='release-date-box'>
                <div className='measurements-release-date-box'>
                    <div className='measurements-release-date-label'>Release Date:</div>
                    {selectedLot.phases[concreteArrayIndex].release_date && <div className='measurements-release-date-value'>{Globals.formatDateToDisplay(selectedLot.phases[concreteArrayIndex].release_date)}</div>}
                    {!selectedLot.phases[concreteArrayIndex].release_date && <div className='measurements-release-date-value'>Not Released</div>}
                </div>
                <div className='measurements-release-status-box'>
                    <div className='measurements-release-status-label'>Starts Status:</div>
                    {(selectedLot.phases[concreteArrayIndex].starts_status) && <div className='measurements-release-status-value'>{selectedLot.phases[concreteArrayIndex].starts_status}</div>}
                    {(!selectedLot.phases[concreteArrayIndex].starts_status) && <div className='measurements-release-status-value'>N/A</div>}
                </div>
                <div className='measurements-lot-notes-box'>
                    <div className='measurements-lot-notes-label'>Lot Notes:</div>
                    <div className='measurements-lot-notes-value' onClick={(e) => handleDisplayNotes()}>{selectedLot.notes !== ''  ? <img width='17' height='17' src={NotesIcon} alt="Notes Icon"/> : <div className='add-note-button-box'><div className='add-note-button'>Notes</div></div>}</div>
                </div>
            </div>}
            {selectedLot &&
                <div className='measurements-box'>
                 {measurements.map(m => {
                     return(
                     <div key={m.name + 'entry-measurements'} className='entry-measurements individual-measurements'>
                         <div className='measurement-type'>{m.description}</div>
                         {inputConcreteMeasurements && <div>
                             <input id={m.name + '-measurements-total'} className='measurements-input' type='number' onClick={() => document.getElementById(m.name + '-measurements-total').select()}
                             value={selectedLot.measurements.concrete[m.name]} onChange={(e) => updateMeasurement(m.name, e.target.value, e.target)}/>
                         </div>}
                         {!inputConcreteMeasurements && <div className='measurement-static'>{m.name}</div>}
                         <div className='measurement-total-postfix'>sqft</div>
                     </div>)
                 })}
                <div className='total-measurements'>
                    <div className='total-measurements-type'>Total</div>
                    <div className='total-measurements-sign'>=</div>
                    <div className='total-measurements-total-value'>{totalSF}</div>
                    <div className='total-measurements-total-postfix'>sqft</div>
                </div>
                {inputConcreteMeasurements &&
                <div className='submit'>
                    <div tabIndex='0' className='button' onClick={handleSubmitMeasurements}>Submit Measurements</div>
                </div>}
            </div>}
            {displayNotes ? <LotNotes isAuthorized={props.isAuthorized} closeNotes={closeNotes} lot={selectedLot} type='Foundation' width='400'/> : null}
            {displayExistingDataWarning && canEditConcreteMeasurements && <Popup color={PopupWarningColor} message={`The selected lot already contains data. Overwriting data will result in losing current data`} handlePopup={togglePopup}/>}
            {success && <Popup color={PopupSuccessColor} message={`Concrete Measurements have been saved`} handlePopup={togglePopup}/>}
        </ConcreteMeasurementsContainer>
    )
}

export default ConcreteMeasurements;