import {useState} from 'react';

import LotNotes from './LotNotes';
import { Button } from '../utilities/Button.js';
import LotsReleasesContainer from './styles/LotsReleasesContainer';
import Auth from '../authentication/Auth';
import Globals from '../utilities/Globals';

const LotReleases = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [job, setJob] = useState(null);
    const [lot, setLot] = useState(null);

    const [phases, setPhases] = useState(null);
    const [startsStatus, setStartsStatus] = useState(null);

    const [displayNotes, setDisplayNotes] = useState('');

    const [concreteMeasurementTemplate, setConcreteMeasurementTemplate] = useState([]);
    const [paverMeasurementTemplate, setPaverMeasurementTemplate] = useState([]);

    const [hasConcrete, setHasConcrete] = useState(false);
    const [hasPavers, setHasPavers] = useState(false);
    const [isPaversOpt, setIsPaversOpt] = useState(false);
    const [hasPaversLot, setHasPaversLot] = useState(false);
    const [hasGates, setHasGates] = useState(false);

    const [concreteTotalSqFeet, setConcreteTotalSqFeet] = useState(0);
    const [paverTotalSqFeet, setPaverTotalSqFeet] = useState(0);

    const loadData = async () => {
        setDidLoadData(true);

        const tempJob = props.job;
        const tempLot = props.lot;
        console.log(tempLot);

        const tempConcreteMeasurementTemplate = (await Auth.hitEndpointNew("GET", "measurements/field/1")).sort((t1, t2) => parseInt(t1.order_by) < parseInt(t2.order_by) ? -1 : 1);
        const tempPaverMeasurementTemplate = (await Auth.hitEndpointNew("GET", "measurements/field/6")).sort((t1, t2) => parseInt(t1.order_by) < parseInt(t2.order_by) ? -1 : 1);

        const tempHasConcrete = tempJob.attributes.find(att => parseInt(att.attribute_id) === 1) !== undefined;
        const tempHasPavers = tempJob.attributes.find(att => parseInt(att.attribute_id) === 10) !== undefined;
        const tempIsPaversOpt = tempJob.attributes.find(att => parseInt(att.attribute_id) === 11) !== undefined;
        const tempHasPaversLot = tempLot.attributes.find(att => parseInt(att.attribute_id) === 10 || parseInt(att.attribute_id) === 11) !== undefined;
        const tempHasGates = tempJob.attributes.find(att => parseInt(att.attribute_id) === 12) !== undefined;

        setHasConcrete(tempHasConcrete);
        setHasPavers(tempHasPavers);
        setIsPaversOpt(tempIsPaversOpt);
        setHasPaversLot(tempHasPaversLot);
        setHasGates(tempHasGates);
        
        const tempPhases = (await Auth.hitEndpointNew("GET", "phases")).sort((p1, p2) => p1.even_flow_order_by < p2.even_flow_order_by ? -1 : 1);
        // Add temp phases if they don't exist
        for(let i = 0; i < tempPhases.length; i++){
            if(!tempLot.phases.find(p => parseInt(p.phase_id) === tempPhases[i].id)){
                tempLot.phases.push({
                    new: true, 
                    lot_id: tempLot.id,
                    modified_at: new Date(),
                    modified_by: await Auth.getUserId(),
                    phase_id: tempPhases[i].id,
                    release_date: null,
                    start_status_id: null,
                    starts_status: ''
                })
            }
        }

        // Add temp concrete measurements if job has concrete and they don't exist
        let tempTotalConcrete = 0;
        if(tempHasConcrete){
            for(let i = 0; i < tempConcreteMeasurementTemplate.length; i++){
                const idValue = parseInt(tempConcreteMeasurementTemplate[i].id);
                if(!tempLot.measurements.find(m => parseInt(m.measurement_id) === idValue)){
                    tempLot.measurements.push({
                        new: true, 
                        date: new Date(),
                        field_id: 1,
                        lot_id: tempLot.id,
                        measurement_id: idValue,
                        user_id: await Auth.getUserId(),
                        value: 0,
                    })
                }

                tempTotalConcrete += parseInt((tempLot.measurements.find(m => parseInt(m.measurement_id) === idValue)).value);
            }
        }

        // Add temp paver measurements if job has pavers and, on optional pavers, lot has pavers and they don't exist
        let tempTotalPavers = 0;
        if(tempHasPavers){
            if(!tempIsPaversOpt || (tempIsPaversOpt && tempHasPaversLot)){
                for(let i = 0; i < tempPaverMeasurementTemplate.length; i++){
                    const idValue = parseInt(tempPaverMeasurementTemplate[i].id);
                    if(!tempLot.measurements.find(m => parseInt(m.measurement_id) === idValue)){
                        const value = idValue === 13 || idValue === 14 || idValue === 15 ? '' : 0;
                        tempLot.measurements.push({
                            new: true, 
                            date: new Date(),
                            field_id: 6,
                            lot_id: tempLot.id,
                            measurement_id: idValue,
                            user_id: await Auth.getUserId(),
                            value: value,
                        })
                    }

                    if(idValue >= 7 && idValue <= 12){
                        tempTotalPavers += parseInt((tempLot.measurements.find(m => parseInt(m.measurement_id) === idValue)).value);
                    }
                }
            }
        }

        if(tempHasGates){
            tempLot.gate_information = tempLot.gate_information.map(g => {
                return {
                    id: g.id,
                    decorative_iron: g.decorative_iron,
                    handedness: g.handedness,
                    lot_id: g.lot_id,
                    width: g.width,
                    level: g.level,
                    measurement_type: g.measurement_type,
                    measurement_type_id: g.measurement_type_id,
                    deleted_at: g.deleted_at,
                    deleted_by: g.deleted_by
                }
            });
        }

        setJob(tempJob);
        setLot(tempLot);

        setPhases(tempPhases);
        setStartsStatus((await Auth.hitEndpointNew("GET", "starts-status")).sort((s1, s2) => s1.display_order < s2.display_order ? -1 : 1));

        setConcreteMeasurementTemplate([...tempConcreteMeasurementTemplate]);
        setPaverMeasurementTemplate([...tempPaverMeasurementTemplate]);

        setConcreteTotalSqFeet(tempTotalConcrete);
        setPaverTotalSqFeet(tempTotalPavers);
    }

    if(!didLoadData){
        loadData();
    }

    const handleBackAndSave = async () => {
        props.closeLotReleases(lot);
    }

    const closeNotes = () => {
        setDisplayNotes('');
    }

    const columns = ['Phase', 'Release', 'Starts Status', '\u{1f4d3}'];

    if(!job || !lot || !phases || !startsStatus){
        return <div></div>
    }
    
    return (
        <LotsReleasesContainer>
            <div className='lot-release-header'>
                <Button className="lot-release-save" handleKeyPress={((e) => {if(e.key === 'Enter'){handleBackAndSave()}})} handleClick={((e) => handleBackAndSave())} buttonText="Close" height="20" width="120"/>
                <div className='lots-release-title'>{job.project_name} Lot #{lot.number} Release Phases</div>
            </div>
            <div className='lot-release-body'>
                <div className='lot-release-phases-header'>
                    {columns.map((column, index)=> {
                        return <div key={index} className='lot-release-phases-header-data'>{column}</div>
                    })}
                </div>
                <div className='phases-table'>
                    {phases.map((phase, index) => {
                        const lotPhase = lot.phases.find(p => parseInt(p.phase_id) === phase.id);
                        return (
                            <div key={`${phase.id}-${index}`} className={`lot-release-phases-row`}>
                                <div className={`lot-release-phase-row-data`}>{phase.stat_name}</div>
                                <div className={`lot-release-phase-row-data`}>{Globals.formatDateToDisplay(lotPhase.release_date)}</div>
                                <div className={`lot-release-phase-row-data`}>{lotPhase.starts_status}</div>
                                {lot.notes !== '' ?
                                <div className={`lot-release-phase-row-data`} onClick={ () => setDisplayNotes(phase.stat_name)}>{'\u{1f4d3}'}</div> :
                                <div className={`lot-release-phase-row-data`}></div>}
                                
                            </div>
                        )
                    })}
                </div>
                {!hasConcrete && 
                <div className='concrete-box'>
                    <div className='concrete-header'>Concrete Measurements</div>
                    <div className='concrete-body'>
                        <div className='no-concrete-message'>This lot does not have concrete services</div>
                    </div>
                </div>}
                {hasConcrete &&
                <div className='concrete-box'>
                    <div className='concrete-header'>Concrete Measurements</div>
                    <div className='concrete-body'>
                        {concreteMeasurementTemplate.map((template, index) => {
                            const currentMeasurement = lot.measurements.find(m => parseInt(m.measurement_id) === parseInt(template.id));
                            return (
                                <div key={`${template.id}-${index}`} className='measurement-box'>
                                    <div className='measurement-name'>{template.description}</div>
                                    <div className='measurement'>{currentMeasurement.value}</div>
                                    <div className='measurement-suffix'>{`sqft`}</div>
                                </div>
                            )
                        })}
                        <div className='measurement-box'>
                            <div className='measurement-name'>Total</div>
                            <div className='measurement'>{concreteTotalSqFeet}</div>
                            <div className='measurement-suffix'>{`sqft`}</div>
                        </div>
                    </div>
                </div>}
                {(!hasPavers || (hasPavers && isPaversOpt && !hasPaversLot)) && 
                <div className='paver-box'>
                    <div className='paver-header'>Paver Measurements</div>
                    <div className='paver-body'>
                        <div className='no-paver-message'>This lot does not have pavers</div>
                    </div>
                </div>}
                {hasPavers && (!isPaversOpt || (isPaversOpt && hasPaversLot)) &&
                <div className='paver-box'>
                    <div className='paver-header'>Paver Measurements</div>
                    <div className='paver-body'>
                        {paverMeasurementTemplate.map((template, index) => {
                            const currentMeasurement = lot.measurements.find(m => parseInt(m.measurement_id) === parseInt(template.id));
                            if(parseInt(template.id) >= 7 && parseInt(template.id) <= 12){
                                return (
                                    <div key={`${template.id}-${index}`} className='measurement-box'>
                                        <div className='measurement-name'>{template.description}</div>
                                        <div className='measurement'>{currentMeasurement.value}</div>
                                        <div className='measurement-suffix'>{`sqft`}</div>
                                    </div>
                                )
                            }

                            return null;
                        })}
                        <div className='measurement-box'>
                            <div className='measurement-name'>Total</div>
                            <div className='measurement'>{paverTotalSqFeet}</div>
                            <div className='measurement-suffix'>{`sqft`}</div>
                        </div>
                        {paverMeasurementTemplate.map((template, index) => {
                            const currentMeasurement = lot.measurements.find(m => parseInt(m.measurement_id) === parseInt(template.id));
                            if(parseInt(template.id) === 13 || parseInt(template.id) === 14 || parseInt(template.id) === 15){
                                return (
                                    <div key={`${template.id}-${index}`} className='information-box'>
                                        <div className='information-name'>{`${template.description}:`}</div>
                                        <div className='information'>{currentMeasurement.value}</div>
                                    </div>
                                )
                            }
                            return null;
                        })}
                    </div>
                </div>}
                {!hasGates && 
                <div className='gate-information-box'>
                    <div className='gate-information-header'>Gate Measurements</div>
                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center'}}>
                        <div>This lot does not have gate service</div>
                    </div>
                </div>}
                {hasGates &&
                <div className='gate-information-box'>
                    <div className='gate-information-header'>Gate Information</div>
                    <div>
                        <div 
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '101px 98px 98px 98px',
                                height: '30px',
                            }}
                        >
                            <div 
                                style={{
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgb(100, 100, 100)',
                                    border: '1px solid black',
                                }}
                            >Width</div>
                            <div 
                                style={{
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgb(100, 100, 100)',
                                    border: '1px solid black',
                                }}
                            >Hand</div>
                            <div 
                                style={{
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgb(100, 100, 100)',
                                    border: '1px solid black',
                                }}
                            >Deco Iron</div>
                            <div 
                                style={{
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgb(100, 100, 100)',
                                    border: '1px solid black',
                                }}
                            >Level</div>
                        </div>
                        {lot.gate_information.filter(g => g.deleted_at === null).map((g, index) => {
                            const width = g.measurement_type !== null ? `${g.width} ${g.measurement_type.name}` : g.width;
                            const bgColor = index % 2 === 0 ? 'rgb(200, 200, 200)' : 'rgb(230, 230, 230)';
                            const bottomBorder = index + 1 === lot.gate_information.length ? '1px solid black' : 'none';
                            return (
                                <div 
                                    key={g.id}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '101px 98px 98px 98px',
                                        height: '30px',
                                        borderBottom: bottomBorder
                                    }}
                                >
                                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor, border: '1px solid black'}}>
                                        {width}
                                        </div>
                                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor, border: '1px solid black'}}>
                                        {g.handedness}
                                        </div>
                                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor, border: '1px solid black'}}>
                                        {g.decorative_iron ? 'X' : ''}
                                        </div>
                                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor, border: '1px solid black'}}>
                                        {g.level}
                                        </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
                {displayNotes && <LotNotes isAuthorized={props.isAuthorized} closeNotes={closeNotes} lot={lot} type={displayNotes} width='600'/>}
            </div>
        </LotsReleasesContainer>
    );
};

export default LotReleases;
