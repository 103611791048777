import styled from 'styled-components';

const RequestDetailContainer = styled.div`
    display: grid;
    margin: 10px 10px 150px 10px;
    
    .top-boxes{
        display: grid;
        justify-content: space-between;
        width: 1510px;
        grid-template-columns: 1fr 1fr;

        .filter-box{
            display: grid;
            // grid-template-rows: repeat(5, 1fr);
            width: 600px;
            background: rgb(210, 200, 190);
            border-bottom: 3px solid white;
            height: fit-content;
             
            .filter-box-header{
                display: grid;
                grid-row: 1/2;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 30px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .super-date-box{
                display: grid;
                align-items: center;
                // grid-row: 2/3;
                grid-template-columns: 300px, 300px;

                .request-date{grid-column: 2/2; width: 250px;}
                .request-super{grid-column: 3/4; width: 250px;}
            }

            .phase-task-box{
                display: grid;
                align-items: center;
                // grid-row: 2/3;
                grid-template-columns: 300px, 300px;
                .request-phase{grid-column: 2/2; width: 250px;}
                .request-task{grid-column: 3/4; width: 250px;}
            }

            .job-lot-box{
                display: grid;
                align-items: center;
                // grid-row: 2/3;
                grid-template-columns: 300px, 300px;

                .request-job{grid-column: 2/2; grid-row: 1/2; width: 300px;}
                .request-lot{grid-column: 2/6; grid-row: 2/2; width: 90%; }
            }
            
            .crew-employee-box{
                display: grid;
                align-items: center;
                // grid-row: 2/3;
                grid-template-columns: 300px, 300px;

                .request-crew{grid-column: 2/2; grid-row: 1/2; width: 300px;}
                .request-employee{grid-column: 2/6; grid-row: 2/2; width: 90%; }
            }
            
            .request-notes-box{
                display: grid;
                align-items: center;
                // grid-row: 2/3;
                grid-template-columns: 300px, 300px;

                .request-notes{grid-column: 2/2; grid-row: 2/2; width: 585px; background-color: white; margin-top: 3px; margin-bottom: 3px;}
            }
        }
       
`

export default RequestDetailContainer;