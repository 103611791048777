import {useState} from 'react';
import Auth from '../authentication/Auth.js';
import XLSX from 'xlsx';

import Jobs from '../../classes/Jobs.js';
// import Lots from '../../classes/Lots.js';
import { Button } from '../utilities/Button.js';
import MainHeader from '../utilities/MainHeader';
import Popup, {PopupSuccessColor} from '../utilities/Popup';
import LoadLotsContainer from './styles/LoadLotsContainer';

const LoadLots = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [jobs, setJobs] = useState([]);

    const [lots, setLots] = useState([]);
    
    const [disableCreateButton, setDisableCreateButton] = useState(false);

    const [success, setSuccess] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);

        setJobs(await Jobs.loadJobs());
    }

    if(!didLoadData){
        loadData();
    }

    const getLoadedData = async (lotsData) => {
        setDisableCreateButton(false);
        for(let i = 0; i < lotsData.length; i++){
            lotsData[i].JobNumber = lotsData[i].JobNumber.toString();
            lotsData[i].LotNumber = lotsData[i].LotNumber.toString();
        }
        const tempLotsToAdd = lotsData.map(lot => {
            const currentJob = jobs.find(jobToFind => parseInt(jobToFind.number) === parseInt(lot.JobNumber));

            return {
                job_id: currentJob ? currentJob.id.toString() : null,
                number: lot.LotNumber ? lot.LotNumber.toString().trim() : '',
                phase: lot.PhaseID ? lot.PhaseID : '',
                address: lot.Address ? lot.Address : '',
                plan_number: lot.PlanNumber ? lot.PlanNumber : '',
                active: 1,
                notes: '',
                phases: [
                    {phase_id: 1, starts_status: ''},
                    {phase_id: 2, starts_status: ''},
                    {phase_id: 3, starts_status: ''},
                    {phase_id: 4, starts_status: ''},
                    {phase_id: 5, starts_status: ''},
                    {phase_id: 6, starts_status: ''},
                    {phase_id: 7, starts_status: ''},
                    {phase_id: 8, starts_status: ''}
                ],
                errors: {
                    errorNoJobNumber: false,
                    errorJobDoesNotExist: false,
                    errorNoLotNumber: false,
                    errorDuplicateLot: false,
                }
            }
        }).sort((l1, l2) => {
            const j1 = jobs.find(jobToFind => parseInt(jobToFind.id) === parseInt(l1.job_id));
            const j2 = jobs.find(jobToFind => parseInt(jobToFind.id) === parseInt(l2.job_id));
            if(parseInt(j1.number) === parseInt(j2.number)){
                return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
            }
            return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;
        })

        const jobIds = [];
        for(let i = 0; i < tempLotsToAdd.length; i++){
            const currentJobIdExist = jobIds.find(jobIdToFind => jobIdToFind === tempLotsToAdd[i].job_id);
            if(currentJobIdExist === undefined){
                jobIds.push(tempLotsToAdd[i].job_id)
            }
        }
        let lotsOfJobIds = [];
        for(let i = 0; i < jobIds.length; i++){
            const tempLots = await Auth.hitEndpointNew("GET", "lots", `jsonString=${JSON.stringify({job_id: tempLotsToAdd[i].job_id})}`);
            lotsOfJobIds = [...lotsOfJobIds, ...tempLots]
        }

        for(let i = 0; i < tempLotsToAdd.length; i++){
            let errorNoJobNumber = false;
            let errorJobDoesNotExist = false;
            let errorNoLotNumber = false;
            let errorDuplicateLot = false;

            if(tempLotsToAdd[i].job_id === null){errorJobDoesNotExist = true;}
            const currentJob = jobs.find(jobToFind => Number.parseInt(jobToFind.id) === tempLotsToAdd[i].id);
            if(currentJob === undefined){errorNoLotNumber = false}
            if(tempLotsToAdd[i].number === ''){return "ERROR_NO_LOT_NUMBER";}
            const dupLotsFromDB = lotsOfJobIds.filter(lotToFilter => lotToFilter.number === tempLotsToAdd[i].number && lotToFilter.phase === tempLotsToAdd[i].phase);
            const dupLotsInList = tempLotsToAdd.filter(lotToFilter => lotToFilter.job_id === tempLotsToAdd[i].job_id && lotToFilter.number === tempLotsToAdd[i].number && lotToFilter.phase === tempLotsToAdd[i].phase);
            const dupLots = [...dupLotsInList, ...dupLotsFromDB];
            if(dupLots.length > 1){errorDuplicateLot = true;}

            if(errorNoJobNumber || errorJobDoesNotExist || errorNoLotNumber || errorDuplicateLot){
                setDisableCreateButton(true);
            }

            tempLotsToAdd[i].errors.errorNoJobNumber = errorNoJobNumber;
            tempLotsToAdd[i].errors.errorJobDoesNotExist = errorJobDoesNotExist;
            tempLotsToAdd[i].errors.errorNoLotNumber = errorNoLotNumber;
            tempLotsToAdd[i].errors.errorDuplicateLot = errorDuplicateLot;
        }

        setLots([...tempLotsToAdd]);
    }

    const createLots = async () => {
        const tempLots = lots.map(lot => {
            const tempLot = {...lot};
            delete tempLot.errors;
            return tempLot;
        })
        await Auth.hitEndpointNew("POST", "lots", "", tempLots);
        setSuccess(true);
    }


    const handleSuccess = (e) => {
        setLots([]);
        setSuccess(false);
    }

    const columns = ['Job Number', 'Lot Number', 'Phase ID', 'Address', 'Plan ID'];

    return (
        <LoadLotsContainer>
            <MainHeader/>
            <DragNDrop getLoadedData={getLoadedData}/>
            {lots.length > 0 &&
            <div>
                <div className='keys-box'>
                    <div className='main-header'>Review And Submit Lots</div>
                    <div className='sub-header'>Color Key</div>
                    <div className='keys'>
                        <div className="key">
                            <div className='key-yellow'></div>
                            <div className='key-text'><span className='key-label'>Bad Data</span> - check file matches template, and, job and lot numbers are filled out for all lots</div>
                        </div>
                        <div className="key">
                            <div className='key-orange'></div>
                            <div className='key-text'><span className='key-label'>No Job Exists</span> - check job number in file is correct, or create job in Jobs Menu first</div>
                        </div>
                        <div className="key">
                            <div className='key-red'></div>
                            <div className='key-text'><span className='key-label'>Duplicate Lot</span> - check lot number in file is correct, may need to remove lot from file</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='table-header'>Lots To Review</div>
                    <div className='grid-table'>
                        <div className='grid-table-header'>
                            {columns.map((column, index)=> {
                                return <div key={index} className='grid-table-header-data'>{column}</div>
                            })}
                        </div>
                        {lots.map((lot, index) => {
                            const currentJob = jobs.find(jobToFind => jobToFind.id === parseInt(lot.job_id));
                            let color = '';
                            if(lot.errors.errorNoJobNumber){color = 'yellow'}
                            if(lot.errors.errorJobDoesNotExist){color = 'orange'}
                            if(lot.errors.errorNoLotNumber){color = 'yellow'}
                            if(lot.errors.errorDuplicateLot){color = 'red'}
                            return (
                                <div key={index} className={`grid-table-row ${color}`} >
                                    <div className='grid-table-row-data'>{currentJob ? currentJob.number : ''}</div>
                                    <div className='grid-table-row-data'>{lot.number}</div>
                                    <div className='grid-table-row-data'>{lot.phase}</div>
                                    <div className='grid-table-row-data'>{lot.address}</div>
                                    <div className='grid-table-row-data'>{lot.plan_number}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='create-lot-button-box'>
                    <Button className="create-lots" 
                        disableButton={disableCreateButton} 
                        handleKeyPress={((e) => {if(e.key === 'Enter'){createLots()}})} 
                        handleClick={((e) => createLots())} 
                        buttonText={disableCreateButton ? "Fix Data" : "Create Lots"} 
                        width="120"/>
                </div>
            </div>}
            {success && <Popup color={PopupSuccessColor} message={`Lots have been added.`} handlePopup={handleSuccess}/>}
        </LoadLotsContainer>
    );
}

const DragNDrop = (props) => {
    const handleOnDrop = (e) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        const fileType = file.type;
        const validFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        if(validFileType === fileType){
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: "binary"});
                workbook.SheetNames.forEach((sheetName) => {
                    const lotData = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    props.getLoadedData(lotData);
                });
            }
            fileReader.readAsBinaryString(file);
        }
        else{
            alert('Invalid file Type. .xlsx Files Only In Valid Format');
        }

        const dropArea = document.getElementById("drop-area");
        dropArea.classList.remove('hovering');
        dropArea.innerText = "Drag File Here";
    }

    const handleDragOver = (e) => {
        e.preventDefault();

        const dropArea = document.getElementById("drop-area");
        dropArea.classList.add('hovering');
        dropArea.innerText = "Drop File";
    }

    const handleDragLeave = (e) => {
        e.preventDefault();

        const dropArea = document.getElementById("drop-area");
        dropArea.classList.remove('hovering');
        dropArea.innerText = "Drag File Here";
    }

    return (
        <div className='drag-n-drop-box' onDrop={handleOnDrop} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
            <div className='drag-n-drop-header'>Drag N Drop Lots</div>
            <div id="drop-area" className="drop-area">Drag File Here</div>
        </div>
    );
}

export default LoadLots;
