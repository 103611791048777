import { useState, useMemo } from 'react';
import dayjs from 'dayjs';

import Auth from '../../authentication/Auth.js';
import MainHeader from '../../utilities/MainHeader.js';
import ScheduleRequestContainer from '../styles/ScheduleRequestContainer.js';
import Schedules from '../../../classes/Schedules.js';

import {MaterialReactTable} from "material-react-table";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import Button  from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const Request = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [user, setUser] = useState(null);
    const [supers, setSupers] = useState([]);

    const [crews, setCrews] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);

    const [phases, setPhases] = useState([]);
    const [phaseTasks, setPhaseTasks] = useState([]);

    const [schedule, setSchedule] = useState([]);
    const [scheduleRequests, setScheduleRequests] = useState([]);
    const [deleteScheduledRequest, setDeleteScheduledRequest] = useState(null);

    const [openScheduleRequestDialog, setOpenScheduleRequestDialog] = useState(false);
    const [scheduleRequestId, setScheduleRequestId] = useState('');
    const [requestedDate, setRequestedDate] = useState(null);
    const [requestedBy, setRequestedBy] = useState(null);
    const [phase, setPhase] = useState(null);
    const [phaseTask, setPhaseTask] = useState(null);
    const [crew, setCrew] = useState(null);
    const [addedEmployees, setAddedEmployees] = useState([]);
    const [notes, setNotes] = useState('');
    const [job, setJob] = useState(null);
    const [addedLots, setAddedLots] = useState([]);

    const [errorCode, setErrorCode] = useState('');

    const isSuper = user !== null && user.position_id === 2 ? true : false;

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => {
                    return dayjs(new Date(row.schedule_date)).format('MM-DD-YYYY');
                },
                header: 'Scheduled Date',
                enableEditing: false,
                size: 200,
            },
            {
                accessorFn: (row) => {
                    return `${row.crew.crew.number} - ${row.crew.employees[0].employee.first_name} ${row.crew.employees[0].employee.last_name}`;
                },
                header: 'Crew',
                enableEditing: false,
                size: 155,
            },
            {
                accessorKey: 'lot.job.number',
                header: 'Job',
                enableEditing: false,
                size: 155,
            },
            {
                accessorKey: 'lot.number',
                header: 'Lot',
                enableEditing: false,
                size: 155,
            },
            {
                accessorFn: (row) => {
                    return `${row.task.code} - ${row.task.description}`;
                },
                header: 'Phase Task',
                enableEditing: false,
                size: 155,
            },
        ],
        [],
    );

    const loadData = async () => {
        setDidLoadData(true);

        let jsonString = 'jsonString=requested=1 and deleted_at is null';

        setScheduleRequests(await Auth.hitEndpointNew("GET", "schedule/detail/requested", jsonString));

        const currentUser = await Auth.hitEndpointNew("GET", "users-new", `jsonString=id=${await Auth.getUserId()}`);
        currentUser.value = currentUser.id;
        currentUser.label = `${currentUser.firstname} ${currentUser.lastname}`
        setUser(currentUser);

        setSupers(await Schedules.loadSupers());

        if(currentUser.position_id === 2){
            setRequestedBy({...currentUser});
        }

        setPhases(await Auth.hitEndpointNew("GET", "phases"));

        setJobs(await Schedules.loadAvailableJobs());
    }

    if(!didLoadData){
        loadData();
    }

    const handleDateSelect = async (date) => { 
        setSchedule(await Schedules.loadSchedule(date, '', null));
        setRequestedDate(date);
    }

    const handlePhaseSelect = async (e) => {
        const tempPhase = phases.find(p => parseInt(p.id) === parseInt(e.value));
        setPhase(tempPhase);
        setPhaseTasks(await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task.phase_id=${tempPhase.id}`));

        const tempCrews = await Auth.hitEndpointNew("GET", "crews-new", `jsonString=crew.company='${tempPhase.company}'`);
        setCrews(tempCrews.map(c => {
            const leader = c.members.find(m => m.position === "Leader");
            if(leader === undefined){
                c.leader = "No Members"
            }
            else{
                c.leader = `${leader.employee.first_name} ${leader.employee.last_name}`;
            }
            return c;
        }).sort((c1, c2) => parseInt(c1.number) < parseInt(c2.number) ? -1 : 1));
    }

    const handlePhaseTaskSelect = async (e) => {
        const tempPhaseTask = phaseTasks.find(pt => parseInt(pt.id) === parseInt(e.value));
        setPhaseTask(tempPhaseTask);

        let whereStr = 'jsonString=';
        for(let i = 0; i < tempPhaseTask.phases.length; i++){
            whereStr += `${tempPhaseTask.phases[i].company.toLowerCase()}=1`;
            if(i + 1 !== tempPhaseTask.phases.length){
                whereStr += " and ";
            }
        }
        const tempEmployees = await Auth.hitEndpointNew("GET", "employees-new", whereStr);
        setEmployees(tempEmployees.sort((e1, e2) => e1.first_name < e2.first_name ? -1 : 1));
    }

    const handleCrewSelect = (e) => {
        const tempCrew = crews.find(c => parseInt(c.id) === parseInt(e.value));
        setCrew(tempCrew);

        setAddedEmployees(tempCrew.members.map(c => {
            return {
                value: c.employee.id,
                label: `${c.employee.first_name} ${c.employee.last_name}`,
                isLeader: c.position === "Leader"
            }
        }))
    }

    const handleJobSelect = async (e) => {
        const tempJob = jobs.find(j => parseInt(j.id) === parseInt(e.value));
        setJob(tempJob);
        setLots(await Schedules.loadAvailableLots(tempJob.id, phase.id, [phaseTask.id], schedule));
    }

    const handleScheduleRequestFormOK = async () => {
        if(requestedDate === null){
            setErrorCode("ERROR_MISSING_DATE");
            return;
        }
        if(requestedBy === null){
            setErrorCode("ERROR_MISSING_SUPER");
            return;
        }
        if(phase === null){
            setErrorCode("ERROR_MISSING_PHASE");
            return;
        }
        if(phaseTask === null){
            setErrorCode("ERROR_MISSING_PHASE_TASK");
            return;
        }
        if(crew === null){
            setErrorCode("ERROR_MISSING_CREW");
            return;
        }
        if(addedLots.length === 0){
            setErrorCode("ERROR_MISSING_LOTS");
            return;
        }

        for(let i = 0; i < addedLots.length; i++){
            addedEmployees.sort((e1, e2) => e1.isLeader ? -1 : 1)
            const dbObj = {
                lot_id: addedLots[i].id,
                phase_id: phase.id,
                phase_task_id: phaseTask.id,
                schedule_date: dayjs(requestedDate).format('YYYY-MM-DD HH:mm:ss'),
                crew: {
                    crew_id: crew.id,
                    employees: addedEmployees.map(ae => {
                        return {
                            employee_id: ae.value
                        }
                    })
                },
                notes: notes,
                requested_by: requestedBy.id,
                deleted_at: null,
                deleted_by: null,
                requested: true,
                scheduled: false
            };

            if(scheduleRequestId !== ''){
                dbObj.id = scheduleRequestId;
            }

            await Schedules.saveSchedule(dbObj);
        }

        setOpenScheduleRequestDialog(false);
        setScheduleRequestId('');
        setRequestedDate(null);
        setRequestedBy(null);
        setPhase(null);
        setPhaseTask(null);
        setCrew(null);
        setAddedEmployees([]);
        setNotes('');
        setJob(null);
        setAddedLots([]);
        setErrorCode('');
        setPhases(await Auth.hitEndpointNew("GET", "phases"));
        setJobs(await Schedules.loadAvailableJobs());
        setScheduleRequests(await Auth.hitEndpointNew("GET", "schedule/detail/requested", 'jsonString=requested=1 and deleted_at is null'));
    }

    const handleScheduleRequestFormCancel = async () => {
        setOpenScheduleRequestDialog(false);
        setScheduleRequestId('');
        setRequestedDate(null);
        setRequestedBy(null);
        setPhase(null);
        setPhaseTask(null);
        setCrew(null);
        setAddedEmployees([]);
        setNotes('');
        setJob(null);
        setAddedLots([]);
        setErrorCode('');
        setPhases(await Auth.hitEndpointNew("GET", "phases"));
        setJobs(await Schedules.loadAvailableJobs());
    }

    const loadEditScheduleRequest = async (editScheduleRequest) => {
        setOpenScheduleRequestDialog(true);

        setScheduleRequestId(editScheduleRequest.id);

        const tempRequestedDate = dayjs(new Date(editScheduleRequest.schedule_date))
        setSchedule(await Schedules.loadSchedule(tempRequestedDate, '', null));
        setRequestedDate(tempRequestedDate);

        setRequestedBy(editScheduleRequest.requestedBy !== null ? supers.find(s => parseInt(s.id) === parseInt(editScheduleRequest.requested_by)) : {...user});

        // Phase
        const tempPhase = phases.find(p => parseInt(p.id) === parseInt(editScheduleRequest.task.phase_id));
        setPhase(tempPhase);
        setPhases([tempPhase]);
        let tempCrews = await Auth.hitEndpointNew("GET", "crews-new", `jsonString=crew.company='${tempPhase.company}'`);
        tempCrews = tempCrews.map(c => {
            const leader = c.members.find(m => m.position === "Leader");
            if(leader === undefined){
                c.leader = "No Members"
            }
            else{
                c.leader = `${leader.employee.first_name} ${leader.employee.last_name}`;
            }
            return c;
        }).sort((c1, c2) => parseInt(c1.number) < parseInt(c2.number) ? -1 : 1);
        setCrews(tempCrews);

        // Phase Task
        const tempPhaseTasks = await Auth.hitEndpointNew("GET", "phase-tasks-new", `jsonString=phase_task.phase_id=${tempPhase.id}`)
        const tempPhaseTask = tempPhaseTasks.find(pt => parseInt(pt.id) === parseInt(editScheduleRequest.task.id));
        setPhaseTask(tempPhaseTask);
        setPhaseTasks([tempPhaseTask]);

        let whereStr = 'jsonString=';
        for(let i = 0; i < tempPhaseTask.phases.length; i++){
            whereStr += `${tempPhaseTask.phases[i].company.toLowerCase()}=1`;
            if(i + 1 !== tempPhaseTask.phases.length){
                whereStr += " and ";
            }
        }
        const tempEmployees = await Auth.hitEndpointNew("GET", "employees-new", whereStr);
        setEmployees(tempEmployees.sort((e1, e2) => e1.first_name < e2.first_name ? -1 : 1));

        // Crew
        const tempCrew = tempCrews.find(c => parseInt(c.id) === parseInt(editScheduleRequest.crew.crew_id));
        setCrew(tempCrew);

        // Employees
        const tempAddedEmployees = [...editScheduleRequest.crew.employees].map(e => {
            e.value = e.employee.id;
            e.label = `${e.employee.first_name} ${e.employee.last_name}`;
            return e;
        });
        setAddedEmployees(tempAddedEmployees);

        // Notes
        setNotes(editScheduleRequest.notes);

        // Job
        const tempJob = jobs.find(j => parseInt(j.id) === parseInt(editScheduleRequest.lot.job.id));
        setJob(tempJob);
        setJobs([tempJob]);

        // Lots
        const tempLot = {...editScheduleRequest.lot};
        tempLot.value = tempLot.id;
        tempLot.label = `${tempLot.number} - ${tempLot.phase} - ${tempLot.address}`
        setAddedLots([tempLot]);
        setLots([tempLot]);
    } 

    const handleScheduleRequestFormDeleteRequest = async (value) => {
        setDeleteScheduledRequest({...value})
    }

    const handleScheduleRequestFormDelete = async () => {
        const dbObj = {
            id: deleteScheduledRequest.id,
            deleted_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            deleted_by: await Auth.getUserId(),
        };

        await Schedules.saveSchedule(dbObj);

        setDeleteScheduledRequest(null);
    }

    return (
        <ScheduleRequestContainer>
            <MainHeader/>
            <MaterialReactTable
                id="schedule-request-table"
                muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
                columns={columns}
                data={scheduleRequests}
                enableSorting
                enableStickyHeader
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(10, 1fr)",
                            width: "100%",
                            margin: "5px 0",
                        }}>
                            <div style={{
                                gridColumn: "1/10",
                                textAlign: "center",
                                fontSize: "30px",
                                fontWeight: "bold",
                                cursor: "default"
                            }}>
                                Schedule Request
                            </div>
                            <AddBoxIcon 
                                sx={{
                                    gridColumn: "10/11",
                                    justifySelf: "end",
                                    alignSelf: "center"
                                }}
                                onClick={() => setOpenScheduleRequestDialog(true)}/>
                        </div>
                    );
                }}
                enableRowActions={true}
                renderRowActions={({ row, table }) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => loadEditScheduleRequest(row.original)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => handleScheduleRequestFormDeleteRequest(row.original)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                }}
            />
            {openScheduleRequestDialog && 
            <Dialog open={openScheduleRequestDialog}>
                <DialogTitle>Schedule Request Form</DialogTitle>
                <DialogContent sx={{width: "600px"}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={handleDateSelect}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {
                                        width: '100%', 
                                        minWidth: '120px',
                                        backgroundColor: errorCode === "ERROR_MISSING_DATE" ? 'rgb(230, 100, 100)' : 'white',
                                    },
                                    variant: 'standard',
                                },
                            }}
                            value={requestedDate}
                        />
                    </LocalizationProvider>
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    margin: '10px auto',
                                    backgroundColor: errorCode === "ERROR_MISSING_SUPER" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                    color: errorCode === "ERROR_MISSING_SUPER" ? 'black' : 'gray',
                                }
                            }
                        }} 
                        id="super-id"
                        className="super"
                        placeholder="Select A Superintendent"
                        value={requestedBy}
                        onChange={(e) => setRequestedBy(e)}
                        options={isSuper ? [user] : supers}
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    margin: '10px auto',
                                    backgroundColor: errorCode === "ERROR_MISSING_PHASE" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                    color: errorCode === "ERROR_MISSING_PHASE" ? 'black' : 'gray',
                                }
                            }
                        }} 
                        id="phase-id"
                        className="phase"
                        placeholder="Select A Phase"
                        value={phase !== null ? {value: phase.id, label: phase.description} : null}
                        onChange={handlePhaseSelect}
                        options={phases.map(p => ({value: p.id, label: p.description}))}
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    margin: '10px auto',
                                    backgroundColor: errorCode === "ERROR_MISSING_PHASE_TASK" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                    color: errorCode === "ERROR_MISSING_PHASE_TASK" ? 'black' : 'gray',
                                }
                            }
                        }} 
                        id="phase-task-id"
                        className="phase-task"
                        placeholder="Select Phase Task"
                        value={phaseTask !== null ? {value: phaseTask.id, label: `${phaseTask.code} - ${phaseTask.description}`} : null}
                        onChange={handlePhaseTaskSelect}
                        options={phaseTasks.map(pt => ({value: pt.id, label: `${pt.code} - ${pt.description}`}))}
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    margin: '10px auto',
                                    backgroundColor: errorCode === "ERROR_MISSING_CREW" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                    color: errorCode === "ERROR_MISSING_CREW" ? 'black' : 'gray',
                                }
                            }
                        }} 
                        id="crew-id"
                        className="crew"
                        placeholder="Select A Crew"
                        value={crew !== null ? {value: crew.id, label: `${crew.number} - ${crew.leader}`} : null}
                        onChange={handleCrewSelect}
                        options={crews.map(c => ({value: c.id, label: `${c.number} - ${c.leader}`}))}
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    backgroundColor: 'white',
                                    margin: '10px auto',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                }
                            }
                        }} 
                        id="added-employees-id"
                        className="added-employees"
                        placeholder="Add Employee(s)"
                        value={addedEmployees}
                        onChange={(e) => setAddedEmployees(e)}
                        options={employees.map(e => ({value: e.id, label: `${e.first_name} ${e.last_name}`, isLeader: false}))}
                        isMulti
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    backgroundColor: 'white',
                                    margin: '10px auto',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                }
                            }
                        }} 
                        id="job-id"
                        className="job"
                        placeholder="Select Job"
                        value={job !== null ? {value: job.value, label: job.label} : null}
                        onChange={handleJobSelect}
                        options={jobs.map(j => ({value: j.value, label: j.label}))}
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    height: '50px',
                                    margin: '10px auto',
                                    backgroundColor: errorCode === "ERROR_MISSING_LOTS" ? 'rgb(230, 100, 100)' : 'white',
                                }
                            },
                            placeholder: styles => {
                                return {
                                    ...styles,
                                    color: errorCode === "ERROR_MISSING_LOTS" ? 'black' : 'gray',
                                }
                            }
                        }} 
                        id="added-lots-id"
                        className="added-lots"
                        placeholder="Select Lot(s)"
                        value={addedLots}
                        onChange={(e) => setAddedLots(e)}
                        options={lots}
                        isMulti
                        isClearable
                        isSearchable
                    />
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '10px auto',
                            zIndex: '0',
                        }}
                        placeholder="Enter Notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleScheduleRequestFormOK}>OK</Button>
                    <Button onClick={handleScheduleRequestFormCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>}



            {deleteScheduledRequest !== null && 
            <Dialog open={deleteScheduledRequest !== null}>
                <DialogTitle>Delete Schedule Request</DialogTitle>
                <DialogContent sx={{display: 'grid', gridTemplateColumns: '100px 350px', width: "500px", height: "150px"}}>
                    <div style={{gridColumn: '1/2', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>Phase Task:</div>
                    <div style={{gridColumn: '2/3'}}>{deleteScheduledRequest.task.code} - {deleteScheduledRequest.task.description}</div>
                    <div style={{gridColumn: '1/2', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>Job:</div>
                    <div style={{gridColumn: '2/3'}}>{deleteScheduledRequest.lot.job.number} - {deleteScheduledRequest.lot.job.project_name}</div>
                    <div style={{gridColumn: '1/2', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>Lot:</div>
                    <div style={{gridColumn: '2/3'}}>{deleteScheduledRequest.lot.number} - {deleteScheduledRequest.lot.phase} - {deleteScheduledRequest.lot.address}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleScheduleRequestFormDelete}>OK</Button>
                    <Button onClick={() => setDeleteScheduledRequest(null)}>Cancel</Button>
                </DialogActions>
            </Dialog>}
        </ScheduleRequestContainer>
    );
}

export default Request;